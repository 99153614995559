import { NodeIcon } from "../../../components/node/NodeIcon";
import { Chip } from "../../../components/Chip";
import React, { ReactNode } from "react";
import {
  EntitySyncStatusEntityStateStatusEnum,
  NodeType,
} from "../../../../generated/syncroom-api/src";

type Props = {
  nodeType?: NodeType;
  issueUrn: string;
  nodeName?: string;
  connectionName?: string;
  status?: EntitySyncStatusEntityStateStatusEnum;
  children?: ReactNode;
};

export const SyncEntityStatusCard = ({
  nodeType,
  issueUrn,
  nodeName,
  connectionName,
  status,
  children,
}: Props) => {
  return (
    <div className="flex min-h-24 items-center gap-4 rounded-lg border border-zinc-200 p-3">
      <div className="flex-shrink-0">
        <NodeIcon node={{ type: nodeType }} />
      </div>
      <div className="flex w-full flex-col justify-center gap-1">
        <span className="font-bold">{issueUrn}</span>
        {nodeName && (
          <div className="text-deep-purple-0 text-sm">Node: {nodeName}</div>
        )}
        {connectionName && (
          <div className="text-deep-purple-0 text-sm">
            Connection: {connectionName}
          </div>
        )}
      </div>
      {status && <Chip>{status}</Chip>}
      {children}
    </div>
  );
};
