import { MdOutlineContentCopy, MdOutlineOpenInNew } from "react-icons/md";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  canCopy: boolean;
  canOpen: boolean;
  url: string;
};

export const UrlActionButton = ({ children, canCopy, canOpen, url }: Props) => {
  if (!canCopy && !canOpen) {
    throw new Error("At least one of 'canCopy' or 'canOpen' must be true.");
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
  };

  const openUrl = () => {
    window.open(url, "_blank");
  };

  const paddingClass = canCopy && canOpen ? "pr-[72px]" : "pr-10";
  const copyButtonClass = canCopy && !canOpen ? "rounded-r-md" : "";
  const openButtonClass = canOpen ? "rounded-r-md" : "";

  return (
    <div
      className={`flex h-8 items-center rounded-lg px-1.5 ${paddingClass} relative border border-[#E4E2E8] text-sm font-medium capitalize`}
    >
      {children}
      <div className="text-bright-purple absolute right-0 flex h-full items-center justify-center">
        {canCopy && (
          <button
            className={`hover:bg-neutral-lesser_grey flex h-full w-8 items-center justify-center border-l border-[#E4E2E8] bg-white ${copyButtonClass}`}
            onClick={copyToClipboard}
            aria-label={`${children} copy`}
          >
            <MdOutlineContentCopy title="copy" size={20} />
          </button>
        )}
        {canOpen && (
          <button
            className={`hover:bg-neutral-lesser_grey flex h-full w-8 items-center justify-center rounded-r-lg border-l border-[#E4E2E8] bg-white ${openButtonClass}`}
            onClick={openUrl}
            aria-label={`${children} open`}
          >
            <MdOutlineOpenInNew title="open" size={20} />
          </button>
        )}
      </div>
    </div>
  );
};
