/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNodeRequest
 */
export interface UpdateNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateNodeRequest
     */
    customName: string;
    /**
     * List of Tags
     * @type {Array<string>}
     * @memberof UpdateNodeRequest
     */
    tags: Array<string>;
}

/**
 * Check if a given object implements the UpdateNodeRequest interface.
 */
export function instanceOfUpdateNodeRequest(value: object): value is UpdateNodeRequest {
    if (!('customName' in value) || value['customName'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    return true;
}

export function UpdateNodeRequestFromJSON(json: any): UpdateNodeRequest {
    return UpdateNodeRequestFromJSONTyped(json, false);
}

export function UpdateNodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNodeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'customName': json['customName'],
        'tags': json['tags'],
    };
}

  export function UpdateNodeRequestToJSON(json: any): UpdateNodeRequest {
      return UpdateNodeRequestToJSONTyped(json, false);
  }

  export function UpdateNodeRequestToJSONTyped(value?: UpdateNodeRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'customName': value['customName'],
        'tags': value['tags'],
    };
}

