import { ReplicaTitle, TitleType } from "./ReplicaTitle";
import {
  BaseNode,
  StartTestRunIncoming200ResponseInner,
  StartTestRunOutgoing200ResponseInner,
} from "../../../../../generated/syncroom-api/src";
import { useMemo } from "react";
import { ErrorBanner } from "../../../../components/banner/ErrorBanner";
import { ReplicaBody } from "./ReplicaBody";

type Props = {
  node: BaseNode;
  issueUrn?: string;
  type: TitleType;
  result:
    | StartTestRunOutgoing200ResponseInner
    | StartTestRunIncoming200ResponseInner
    | undefined;
};

export const Replica = ({ node, issueUrn, type, result }: Props) => {
  const data = useMemo(
    () => JSON.parse(result?.replica ?? "{}"),
    [result?.replica],
  );

  if (result === undefined) {
    return (
      <ReplicaTitle
        node={node}
        issueUrn={undefined}
        type={TitleType.NO_RESPONSE}
        errorPresent={false}
      />
    );
  }
  const replicaErrorPresent = result.errors !== undefined;

  return (
    <>
      <ReplicaTitle
        node={node}
        issueUrn={issueUrn}
        type={type}
        errorPresent={replicaErrorPresent}
      />
      {result?.errors?.map((error) => (
        <ErrorBanner key={error.message}>{error.message}</ErrorBanner>
      ))}
      <ReplicaBody data={data} />
    </>
  );
};
