/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
    NodeTypeToJSONTyped,
} from './NodeType';
import type { SupportedFeature } from './SupportedFeature';
import {
    SupportedFeatureFromJSON,
    SupportedFeatureFromJSONTyped,
    SupportedFeatureToJSON,
    SupportedFeatureToJSONTyped,
} from './SupportedFeature';
import type { NodeStatus } from './NodeStatus';
import {
    NodeStatusFromJSON,
    NodeStatusFromJSONTyped,
    NodeStatusToJSON,
    NodeStatusToJSONTyped,
} from './NodeStatus';

/**
 * Response object for a node listview item
 * @export
 * @interface NodeListviewItem
 */
export interface NodeListviewItem {
    /**
     * The unique identifier of a node in SyncRoom
     * @type {string}
     * @memberof NodeListviewItem
     */
    id: string;
    /**
     * The given name of a node
     * @type {string}
     * @memberof NodeListviewItem
     */
    customName: string;
    /**
     * 
     * @type {NodeType}
     * @memberof NodeListviewItem
     */
    type: NodeType;
    /**
     * 
     * @type {NodeStatus}
     * @memberof NodeListviewItem
     */
    status: NodeStatus;
    /**
     * The URL of the admin interface on the issue tracker itself
     * @type {string}
     * @memberof NodeListviewItem
     */
    adminUrl: string;
    /**
     * Amount of errors currently on the node
     * @type {number}
     * @memberof NodeListviewItem
     */
    totalErrors: number;
    /**
     * The software version that is running on the node
     * @type {string}
     * @memberof NodeListviewItem
     */
    version: string;
    /**
     * The raw software version that is running on the node
     * @type {string}
     * @memberof NodeListviewItem
     */
    rawVersion: string;
    /**
     * 
     * @type {Array<SupportedFeature>}
     * @memberof NodeListviewItem
     */
    supportedFeatures: Array<SupportedFeature>;
    /**
     * 
     * @type {number}
     * @memberof NodeListviewItem
     */
    managedConnections: number;
    /**
     * 
     * @type {number}
     * @memberof NodeListviewItem
     */
    totalConnections: number;
    /**
     * 
     * @type {string}
     * @memberof NodeListviewItem
     */
    baseUrl: string;
}



/**
 * Check if a given object implements the NodeListviewItem interface.
 */
export function instanceOfNodeListviewItem(value: object): value is NodeListviewItem {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('customName' in value) || value['customName'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('adminUrl' in value) || value['adminUrl'] === undefined) return false;
    if (!('totalErrors' in value) || value['totalErrors'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('rawVersion' in value) || value['rawVersion'] === undefined) return false;
    if (!('supportedFeatures' in value) || value['supportedFeatures'] === undefined) return false;
    if (!('managedConnections' in value) || value['managedConnections'] === undefined) return false;
    if (!('totalConnections' in value) || value['totalConnections'] === undefined) return false;
    if (!('baseUrl' in value) || value['baseUrl'] === undefined) return false;
    return true;
}

export function NodeListviewItemFromJSON(json: any): NodeListviewItem {
    return NodeListviewItemFromJSONTyped(json, false);
}

export function NodeListviewItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeListviewItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customName': json['customName'],
        'type': NodeTypeFromJSON(json['type']),
        'status': NodeStatusFromJSON(json['status']),
        'adminUrl': json['adminUrl'],
        'totalErrors': json['totalErrors'],
        'version': json['version'],
        'rawVersion': json['rawVersion'],
        'supportedFeatures': ((json['supportedFeatures'] as Array<any>).map(SupportedFeatureFromJSON)),
        'managedConnections': json['managedConnections'],
        'totalConnections': json['totalConnections'],
        'baseUrl': json['baseUrl'],
    };
}

  export function NodeListviewItemToJSON(json: any): NodeListviewItem {
      return NodeListviewItemToJSONTyped(json, false);
  }

  export function NodeListviewItemToJSONTyped(value?: NodeListviewItem | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'customName': value['customName'],
        'type': NodeTypeToJSON(value['type']),
        'status': NodeStatusToJSON(value['status']),
        'adminUrl': value['adminUrl'],
        'totalErrors': value['totalErrors'],
        'version': value['version'],
        'rawVersion': value['rawVersion'],
        'supportedFeatures': ((value['supportedFeatures'] as Array<any>).map(SupportedFeatureToJSON)),
        'managedConnections': value['managedConnections'],
        'totalConnections': value['totalConnections'],
        'baseUrl': value['baseUrl'],
    };
}

