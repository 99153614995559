import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { CreateNetworkModal } from "./CreateNetworkModal";
import { useNetworksApi } from "../../shared/useNetworksApi";
import { PageHeader } from "../../components/layout/PageHeader";
import { Card } from "../../components/layout/Card";
import { Button, ButtonSize } from "../../components/buttons/Button";
import { FaPlus } from "react-icons/fa";
import { NetworkCard } from "./NetworkCard";
import { Loader } from "../../components/Loader";
import { ResponseErrorMessage } from "../../components/ResponseErrorMessage";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { EmptyStatePage } from "../../pages/EmptyStatePage";
import { PageContent } from "../../components/layout/PageContent";

export const NetworkListviewPage = () => {
  const api = useNetworksApi();
  const queryClient = useQueryClient();
  const [isCreateNetworkModalOpen, setIsCreateNetworkModalOpen] =
    useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const queryKey = ["networks"];

  const networkOverviewQuery = useQuery({
    queryKey,
    queryFn: () => api.getNetworks(),
  });

  if (networkOverviewQuery.isPending) return <Loader />;
  if (networkOverviewQuery.isError)
    return (
      <ResponseErrorMessage error={networkOverviewQuery.error}>
        Error fetching network overview
      </ResponseErrorMessage>
    );

  const createNetworkModal = (
    <CreateNetworkModal
      onClose={() => setIsCreateNetworkModalOpen(false)}
      onSave={async (networkId: string) => {
        await auth.signinSilent();
        await queryClient.invalidateQueries({ queryKey });
        setIsCreateNetworkModalOpen(false);
        navigate(networkId);
      }}
    />
  );

  if (networkOverviewQuery.data.length <= 0) {
    return (
      <>
        {isCreateNetworkModalOpen && createNetworkModal}
        <EmptyStatePage
          hasButton={true}
          title="No networks found."
          text='To get started, click the "Add Network" button to create your first network.'
          buttonText="Add network"
          onClick={() => setIsCreateNetworkModalOpen(true)}
        />
      </>
    );
  }

  return (
    <>
      {isCreateNetworkModalOpen && createNetworkModal}
      <PageContent>
        <PageHeader>
          <h1 className="heading-1">Networks</h1>
        </PageHeader>
        <Card
          header="My networks"
          buttons={
            <Button
              onClick={() => setIsCreateNetworkModalOpen(true)}
              size={ButtonSize.MEDIUM}
              iconLeft={<FaPlus />}
            >
              Add network
            </Button>
          }
        >
          <div className="grid w-fit grid-cols-3 gap-8">
            {networkOverviewQuery.data.map((network) => {
              return <NetworkCard network={network} key={network.id} />;
            })}
          </div>
        </Card>
      </PageContent>
    </>
  );
};
