import { Modal } from "../../../components/Modal";
import { Form, Formik } from "formik";
import { FormSelectInput } from "../../../components/form/FormSelectInput";
import { useNodeApi } from "../../../shared/useNodeApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SelectOption } from "../../../components/select/SelectWrapper";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import { Button, ButtonType } from "../../../components/buttons/Button";
import * as yup from "yup";

type Props = {
  nodeId: string;
  entity: string;
  entityType: string;
  onClose: () => void;
};

export type FormValues = {
  connectionId: string;
};

const validationSchema = yup.object({
  connectionId: yup.string().required("Connection is a required field"),
});

export const SyncEntityStatusActionExalateModal = ({
  nodeId,
  entity,
  entityType,
  onClose,
}: Props) => {
  const nodeApi = useNodeApi();
  const connectionsQuery = useQuery({
    queryKey: ["nodes", nodeId, "connections"],
    queryFn: () =>
      nodeApi.getNodeConnections({
        nodeId,
      }),
  });
  const exalateMutation = useMutation({
    mutationFn: (connectionId: string) =>
      nodeApi.syncStatusExalateEntity({
        nodeId: nodeId,
        entity: entity,
        syncStatusExalateEntityRequest: {
          entityType,
          connectionId,
        },
      }),
    onSuccess: async () => {
      onClose();
    },
  });

  const connectionOptions: SelectOption[] =
    connectionsQuery.data?.map((connection) => {
      return {
        value: connection.id,
        label: connection.name,
      };
    }) ?? [];

  return (
    <Formik<FormValues>
      initialValues={{
        connectionId: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => exalateMutation.mutate(values.connectionId)}
    >
      <Form noValidate>
        <Modal
          isLoading={connectionsQuery.isLoading || exalateMutation.isPending}
          toggleModal={() => onClose()}
          title={`Exalate ${entityType} ${entity}`}
          buttons={
            <>
              <Button type={ButtonType.SECONDARY} onClick={() => onClose()}>
                Cancel
              </Button>
              <Button buttonType="submit" disabled={exalateMutation.isPending}>
                Exalate
              </Button>
            </>
          }
        >
          <div className="flex flex-col gap-y-4">
            {connectionsQuery.error && (
              <ResponseErrorMessage error={connectionsQuery.error}>
                Error fetching connections
              </ResponseErrorMessage>
            )}
            {exalateMutation.error && (
              <ResponseErrorMessage error={exalateMutation.error}>
                Error in Exalate entity
              </ResponseErrorMessage>
            )}
            <FormSelectInput
              label="Connection"
              name="connectionId"
              required={true}
              options={connectionOptions}
            />
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
