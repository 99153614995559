/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConnectedNode,
  Connection,
  ConnectionDetail,
  ConnectionVersion,
  CreateConnectionVersion201Response,
  EntitySyncStatusEntity,
  ErrorRest,
  GetConnectionSyncQueueStatistics200Response,
  GetEntitySyncStatusDetails200Response,
  GetErrorsFromConnection200Response,
  GetErrorsFromNode200Response,
  GetIssuesForTestRun200ResponseInner,
  GetNodeSyncQueueEntityDetails200Response,
  Node,
  NodeListviewItem,
  Scripts,
  StartTestRunIncoming200ResponseInner,
  StartTestRunIncomingRequestInner,
  StartTestRunOutgoing200ResponseInner,
  SuggestionsResponse,
  SyncQueueDetails,
  SyncStatusConnectEntityRequest,
  SyncStatusExalateEntityRequest,
  TestRunIssue,
  Trigger,
  TriggerRequestBody,
  UpdateConnectionScripts200Response,
  UpdateNodeRequest,
  ValidatedTrigger,
} from '../models/index';
import {
    ConnectedNodeFromJSON,
    ConnectedNodeToJSON,
    ConnectionFromJSON,
    ConnectionToJSON,
    ConnectionDetailFromJSON,
    ConnectionDetailToJSON,
    ConnectionVersionFromJSON,
    ConnectionVersionToJSON,
    CreateConnectionVersion201ResponseFromJSON,
    CreateConnectionVersion201ResponseToJSON,
    EntitySyncStatusEntityFromJSON,
    EntitySyncStatusEntityToJSON,
    ErrorRestFromJSON,
    ErrorRestToJSON,
    GetConnectionSyncQueueStatistics200ResponseFromJSON,
    GetConnectionSyncQueueStatistics200ResponseToJSON,
    GetEntitySyncStatusDetails200ResponseFromJSON,
    GetEntitySyncStatusDetails200ResponseToJSON,
    GetErrorsFromConnection200ResponseFromJSON,
    GetErrorsFromConnection200ResponseToJSON,
    GetErrorsFromNode200ResponseFromJSON,
    GetErrorsFromNode200ResponseToJSON,
    GetIssuesForTestRun200ResponseInnerFromJSON,
    GetIssuesForTestRun200ResponseInnerToJSON,
    GetNodeSyncQueueEntityDetails200ResponseFromJSON,
    GetNodeSyncQueueEntityDetails200ResponseToJSON,
    NodeFromJSON,
    NodeToJSON,
    NodeListviewItemFromJSON,
    NodeListviewItemToJSON,
    ScriptsFromJSON,
    ScriptsToJSON,
    StartTestRunIncoming200ResponseInnerFromJSON,
    StartTestRunIncoming200ResponseInnerToJSON,
    StartTestRunIncomingRequestInnerFromJSON,
    StartTestRunIncomingRequestInnerToJSON,
    StartTestRunOutgoing200ResponseInnerFromJSON,
    StartTestRunOutgoing200ResponseInnerToJSON,
    SuggestionsResponseFromJSON,
    SuggestionsResponseToJSON,
    SyncQueueDetailsFromJSON,
    SyncQueueDetailsToJSON,
    SyncStatusConnectEntityRequestFromJSON,
    SyncStatusConnectEntityRequestToJSON,
    SyncStatusExalateEntityRequestFromJSON,
    SyncStatusExalateEntityRequestToJSON,
    TestRunIssueFromJSON,
    TestRunIssueToJSON,
    TriggerFromJSON,
    TriggerToJSON,
    TriggerRequestBodyFromJSON,
    TriggerRequestBodyToJSON,
    UpdateConnectionScripts200ResponseFromJSON,
    UpdateConnectionScripts200ResponseToJSON,
    UpdateNodeRequestFromJSON,
    UpdateNodeRequestToJSON,
    ValidatedTriggerFromJSON,
    ValidatedTriggerToJSON,
} from '../models/index';

export interface ActivateVersionRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
}

export interface CreateConnectionVersionRequest {
    nodeId: string;
    connectionId: string;
    body: string;
}

export interface CreateTriggerRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerRequestBody: TriggerRequestBody;
}

export interface DeleteNodeRequest {
    nodeId: string;
    force: boolean;
}

export interface DeleteTriggerRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerId: string;
}

export interface DownloadIssuesUnderTriggerRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerId: string;
}

export interface GetAllConnectedNodesRequest {
    networkId?: string;
    nodeId?: string;
}

export interface GetConnectedNodesRequest {
    nodeId: string;
}

export interface GetConnectionDetailRequest {
    nodeId: string;
    connectionId: string;
}

export interface GetConnectionScriptsRequest {
    nodeId: string;
    connectionId: string;
    versionId?: string;
}

export interface GetConnectionSyncQueueDetailsRequest {
    nodeId: string;
    connectionId: string;
}

export interface GetConnectionSyncQueueStatisticsRequest {
    nodeId: string;
    connectionId: string;
}

export interface GetConnectionTriggersRequest {
    nodeId: string;
    connectionId: string;
    versionId?: string;
}

export interface GetConnectionVersionsRequest {
    nodeId: string;
    connectionId: string;
}

export interface GetEntitySyncStatusRequest {
    nodeId: string;
    entityType: string;
    entity: string;
}

export interface GetEntitySyncStatusDetailsRequest {
    nodeId: string;
    twinTraceId: number;
}

export interface GetErrorsFromConnectionRequest {
    nodeId: string;
    connectionId: string;
    offset?: number;
    limit?: number;
}

export interface GetErrorsFromNodeRequest {
    nodeId: string;
    offset?: number;
    limit?: number;
}

export interface GetIdeSuggestionsRequest {
    nodeId: string;
}

export interface GetIssuesForTestRunRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    search: string;
    entityType: string;
}

export interface GetIssuesUnderTriggerRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerId: string;
}

export interface GetNodeRequest {
    nodeId: string;
}

export interface GetNodeConnectionsRequest {
    nodeId: string;
}

export interface GetNodeEntityTypesRequest {
    nodeId: string;
}

export interface GetNodeSyncQueueDetailsRequest {
    nodeId: string;
}

export interface GetNodeSyncQueueEntityDetailsRequest {
    nodeId: string;
    entityId: number;
    direction: GetNodeSyncQueueEntityDetailsDirectionEnum;
    isAttachment: boolean;
}

export interface GetNodeSyncQueueStatisticsRequest {
    nodeId: string;
}

export interface RemoveBothSidesOfAConnectionRequest {
    nodeId: string;
    connectionId: string;
}

export interface StartTestRunIncomingRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    startTestRunIncomingRequestInner: Array<StartTestRunIncomingRequestInner>;
}

export interface StartTestRunOutgoingRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    testRunIssue: Array<TestRunIssue>;
}

export interface SyncStatusConnectEntityOperationRequest {
    nodeId: string;
    entity: string;
    syncStatusConnectEntityRequest: SyncStatusConnectEntityRequest;
}

export interface SyncStatusExalateEntityOperationRequest {
    nodeId: string;
    entity: string;
    syncStatusExalateEntityRequest: SyncStatusExalateEntityRequest;
}

export interface SyncStatusUnexalateEntityRequest {
    nodeId: string;
    entity: string;
    syncStatusExalateEntityRequest: SyncStatusExalateEntityRequest;
}

export interface ToggleTriggerStatusRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerId: string;
    body: boolean;
}

export interface TriggerBulkExalateRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerId: string;
}

export interface TriggerBulkUnExalateRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerId: string;
}

export interface UpdateCentrallyManagedRequest {
    nodeId: string;
    connectionId: string;
    body: boolean;
}

export interface UpdateConnectionScriptsRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    scripts: Scripts;
}

export interface UpdateNodeOperationRequest {
    nodeId: string;
    updateNodeRequest: UpdateNodeRequest;
}

export interface UpdateTriggerRequest {
    nodeId: string;
    connectionId: string;
    versionId: string;
    triggerId: string;
    triggerRequestBody: TriggerRequestBody;
}

/**
 * 
 */
export class NodesApi extends runtime.BaseAPI {

    /**
     * Activate a specific version for a connection on a node
     */
    async activateVersionRaw(requestParameters: ActivateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling activateVersion().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling activateVersion().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling activateVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate a specific version for a connection on a node
     */
    async activateVersion(requestParameters: ActivateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateVersionRaw(requestParameters, initOverrides);
    }

    /**
     * Create a version for a connection of a node
     */
    async createConnectionVersionRaw(requestParameters: CreateConnectionVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateConnectionVersion201Response>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling createConnectionVersion().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling createConnectionVersion().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling createConnectionVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateConnectionVersion201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a version for a connection of a node
     */
    async createConnectionVersion(requestParameters: CreateConnectionVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateConnectionVersion201Response> {
        const response = await this.createConnectionVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a trigger for a connection version from a node
     */
    async createTriggerRaw(requestParameters: CreateTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidatedTrigger>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling createTrigger().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling createTrigger().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling createTrigger().'
            );
        }

        if (requestParameters['triggerRequestBody'] == null) {
            throw new runtime.RequiredError(
                'triggerRequestBody',
                'Required parameter "triggerRequestBody" was null or undefined when calling createTrigger().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TriggerRequestBodyToJSON(requestParameters['triggerRequestBody']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidatedTriggerFromJSON(jsonValue));
    }

    /**
     * Create a trigger for a connection version from a node
     */
    async createTrigger(requestParameters: CreateTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidatedTrigger> {
        const response = await this.createTriggerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a node
     */
    async deleteNodeRaw(requestParameters: DeleteNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling deleteNode().'
            );
        }

        if (requestParameters['force'] == null) {
            throw new runtime.RequiredError(
                'force',
                'Required parameter "force" was null or undefined when calling deleteNode().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['force'] != null) {
            queryParameters['force'] = requestParameters['force'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a node
     */
    async deleteNode(requestParameters: DeleteNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNodeRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a specific trigger from a connection version from a node
     */
    async deleteTriggerRaw(requestParameters: DeleteTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling deleteTrigger().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling deleteTrigger().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling deleteTrigger().'
            );
        }

        if (requestParameters['triggerId'] == null) {
            throw new runtime.RequiredError(
                'triggerId',
                'Required parameter "triggerId" was null or undefined when calling deleteTrigger().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers/{trigger_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))).replace(`{${"trigger_id"}}`, encodeURIComponent(String(requestParameters['triggerId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific trigger from a connection version from a node
     */
    async deleteTrigger(requestParameters: DeleteTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTriggerRaw(requestParameters, initOverrides);
    }

    /**
     * Download all issues that match the trigger in a CSV list format
     */
    async downloadIssuesUnderTriggerRaw(requestParameters: DownloadIssuesUnderTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling downloadIssuesUnderTrigger().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling downloadIssuesUnderTrigger().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling downloadIssuesUnderTrigger().'
            );
        }

        if (requestParameters['triggerId'] == null) {
            throw new runtime.RequiredError(
                'triggerId',
                'Required parameter "triggerId" was null or undefined when calling downloadIssuesUnderTrigger().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers/{trigger_id}/issues/download`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))).replace(`{${"trigger_id"}}`, encodeURIComponent(String(requestParameters['triggerId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download all issues that match the trigger in a CSV list format
     */
    async downloadIssuesUnderTrigger(requestParameters: DownloadIssuesUnderTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadIssuesUnderTriggerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all connected nodes (including connections) in the context of a network (if network_id is provided) , or in the context of a node (if node_id is provided)
     */
    async getAllConnectedNodesRaw(requestParameters: GetAllConnectedNodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConnectedNode>>> {
        const queryParameters: any = {};

        if (requestParameters['networkId'] != null) {
            queryParameters['network_id'] = requestParameters['networkId'];
        }

        if (requestParameters['nodeId'] != null) {
            queryParameters['node_id'] = requestParameters['nodeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/allConnectedNodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConnectedNodeFromJSON));
    }

    /**
     * Retrieve all connected nodes (including connections) in the context of a network (if network_id is provided) , or in the context of a node (if node_id is provided)
     */
    async getAllConnectedNodes(requestParameters: GetAllConnectedNodesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConnectedNode>> {
        const response = await this.getAllConnectedNodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all connected nodes of a node that are known by SyncRoom
     */
    async getConnectedNodesRaw(requestParameters: GetConnectedNodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Node>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getConnectedNodes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connectedNodes`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeFromJSON));
    }

    /**
     * Retrieve all connected nodes of a node that are known by SyncRoom
     */
    async getConnectedNodes(requestParameters: GetConnectedNodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Node>> {
        const response = await this.getConnectedNodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a specific connection from a node
     */
    async getConnectionDetailRaw(requestParameters: GetConnectionDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectionDetail>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getConnectionDetail().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getConnectionDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionDetailFromJSON(jsonValue));
    }

    /**
     * Retrieve a specific connection from a node
     */
    async getConnectionDetail(requestParameters: GetConnectionDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectionDetail> {
        const response = await this.getConnectionDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve both scripts from a connection version from a node
     */
    async getConnectionScriptsRaw(requestParameters: GetConnectionScriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Scripts>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getConnectionScripts().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getConnectionScripts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['versionId'] != null) {
            queryParameters['version_id'] = requestParameters['versionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/scripts`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScriptsFromJSON(jsonValue));
    }

    /**
     * Retrieve both scripts from a connection version from a node
     */
    async getConnectionScripts(requestParameters: GetConnectionScriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scripts> {
        const response = await this.getConnectionScriptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve details of the outgoing and incoming sync queue of this connection
     */
    async getConnectionSyncQueueDetailsRaw(requestParameters: GetConnectionSyncQueueDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyncQueueDetails>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getConnectionSyncQueueDetails().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getConnectionSyncQueueDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/syncQueue/details`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncQueueDetailsFromJSON(jsonValue));
    }

    /**
     * Retrieve details of the outgoing and incoming sync queue of this connection
     */
    async getConnectionSyncQueueDetails(requestParameters: GetConnectionSyncQueueDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyncQueueDetails> {
        const response = await this.getConnectionSyncQueueDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve statistics of the outgoing and incoming sync queue of this connection
     */
    async getConnectionSyncQueueStatisticsRaw(requestParameters: GetConnectionSyncQueueStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetConnectionSyncQueueStatistics200Response>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getConnectionSyncQueueStatistics().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getConnectionSyncQueueStatistics().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/syncQueue`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConnectionSyncQueueStatistics200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve statistics of the outgoing and incoming sync queue of this connection
     */
    async getConnectionSyncQueueStatistics(requestParameters: GetConnectionSyncQueueStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetConnectionSyncQueueStatistics200Response> {
        const response = await this.getConnectionSyncQueueStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve triggers from a connection version from a node
     */
    async getConnectionTriggersRaw(requestParameters: GetConnectionTriggersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Trigger>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getConnectionTriggers().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getConnectionTriggers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['versionId'] != null) {
            queryParameters['version_id'] = requestParameters['versionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/triggers`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TriggerFromJSON));
    }

    /**
     * Retrieve triggers from a connection version from a node
     */
    async getConnectionTriggers(requestParameters: GetConnectionTriggersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Trigger>> {
        const response = await this.getConnectionTriggersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all versions from a connection from a node
     */
    async getConnectionVersionsRaw(requestParameters: GetConnectionVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConnectionVersion>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getConnectionVersions().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getConnectionVersions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConnectionVersionFromJSON));
    }

    /**
     * Retrieve all versions from a connection from a node
     */
    async getConnectionVersions(requestParameters: GetConnectionVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConnectionVersion>> {
        const response = await this.getConnectionVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the sync status for a specific entity
     */
    async getEntitySyncStatusRaw(requestParameters: GetEntitySyncStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntitySyncStatusEntity>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getEntitySyncStatus().'
            );
        }

        if (requestParameters['entityType'] == null) {
            throw new runtime.RequiredError(
                'entityType',
                'Required parameter "entityType" was null or undefined when calling getEntitySyncStatus().'
            );
        }

        if (requestParameters['entity'] == null) {
            throw new runtime.RequiredError(
                'entity',
                'Required parameter "entity" was null or undefined when calling getEntitySyncStatus().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['entityType'] != null) {
            queryParameters['entityType'] = requestParameters['entityType'];
        }

        if (requestParameters['entity'] != null) {
            queryParameters['entity'] = requestParameters['entity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/entitySyncStatus`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntitySyncStatusEntityFromJSON(jsonValue));
    }

    /**
     * Get the sync status for a specific entity
     */
    async getEntitySyncStatus(requestParameters: GetEntitySyncStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntitySyncStatusEntity> {
        const response = await this.getEntitySyncStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the sync status details for a specific entity
     */
    async getEntitySyncStatusDetailsRaw(requestParameters: GetEntitySyncStatusDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEntitySyncStatusDetails200Response>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getEntitySyncStatusDetails().'
            );
        }

        if (requestParameters['twinTraceId'] == null) {
            throw new runtime.RequiredError(
                'twinTraceId',
                'Required parameter "twinTraceId" was null or undefined when calling getEntitySyncStatusDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/entitySyncStatus/{twinTraceId}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"twinTraceId"}}`, encodeURIComponent(String(requestParameters['twinTraceId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEntitySyncStatusDetails200ResponseFromJSON(jsonValue));
    }

    /**
     * Get the sync status details for a specific entity
     */
    async getEntitySyncStatusDetails(requestParameters: GetEntitySyncStatusDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEntitySyncStatusDetails200Response> {
        const response = await this.getEntitySyncStatusDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all errors from a connection
     */
    async getErrorsFromConnectionRaw(requestParameters: GetErrorsFromConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetErrorsFromConnection200Response>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getErrorsFromConnection().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getErrorsFromConnection().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/errors`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetErrorsFromConnection200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve all errors from a connection
     */
    async getErrorsFromConnection(requestParameters: GetErrorsFromConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetErrorsFromConnection200Response> {
        const response = await this.getErrorsFromConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all errors from a node
     */
    async getErrorsFromNodeRaw(requestParameters: GetErrorsFromNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetErrorsFromNode200Response>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getErrorsFromNode().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/errors`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetErrorsFromNode200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve all errors from a node
     */
    async getErrorsFromNode(requestParameters: GetErrorsFromNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetErrorsFromNode200Response> {
        const response = await this.getErrorsFromNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the suggestions for the script editor
     */
    async getIdeSuggestionsRaw(requestParameters: GetIdeSuggestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuggestionsResponse>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getIdeSuggestions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/ide/suggestions`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuggestionsResponseFromJSON(jsonValue));
    }

    /**
     * Get the suggestions for the script editor
     */
    async getIdeSuggestions(requestParameters: GetIdeSuggestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuggestionsResponse> {
        const response = await this.getIdeSuggestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find issues using the provided query and search term.
     */
    async getIssuesForTestRunRaw(requestParameters: GetIssuesForTestRunRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetIssuesForTestRun200ResponseInner>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getIssuesForTestRun().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getIssuesForTestRun().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling getIssuesForTestRun().'
            );
        }

        if (requestParameters['search'] == null) {
            throw new runtime.RequiredError(
                'search',
                'Required parameter "search" was null or undefined when calling getIssuesForTestRun().'
            );
        }

        if (requestParameters['entityType'] == null) {
            throw new runtime.RequiredError(
                'entityType',
                'Required parameter "entityType" was null or undefined when calling getIssuesForTestRun().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['entityType'] != null) {
            queryParameters['entityType'] = requestParameters['entityType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/testrun/issues`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetIssuesForTestRun200ResponseInnerFromJSON));
    }

    /**
     * Find issues using the provided query and search term.
     */
    async getIssuesForTestRun(requestParameters: GetIssuesForTestRunRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetIssuesForTestRun200ResponseInner>> {
        const response = await this.getIssuesForTestRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the total count of all issues that match the trigger
     */
    async getIssuesUnderTriggerRaw(requestParameters: GetIssuesUnderTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getIssuesUnderTrigger().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling getIssuesUnderTrigger().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling getIssuesUnderTrigger().'
            );
        }

        if (requestParameters['triggerId'] == null) {
            throw new runtime.RequiredError(
                'triggerId',
                'Required parameter "triggerId" was null or undefined when calling getIssuesUnderTrigger().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers/{trigger_id}/issues`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))).replace(`{${"trigger_id"}}`, encodeURIComponent(String(requestParameters['triggerId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the total count of all issues that match the trigger
     */
    async getIssuesUnderTrigger(requestParameters: GetIssuesUnderTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getIssuesUnderTriggerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a node
     */
    async getNodeRaw(requestParameters: GetNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Node>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getNode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NodeFromJSON(jsonValue));
    }

    /**
     * Retrieve a node
     */
    async getNode(requestParameters: GetNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Node> {
        const response = await this.getNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve connections from a node
     */
    async getNodeConnectionsRaw(requestParameters: GetNodeConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Connection>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getNodeConnections().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConnectionFromJSON));
    }

    /**
     * Retrieve connections from a node
     */
    async getNodeConnections(requestParameters: GetNodeConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Connection>> {
        const response = await this.getNodeConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the entity types the node supports
     */
    async getNodeEntityTypesRaw(requestParameters: GetNodeEntityTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getNodeEntityTypes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/entity_types`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Retrieve the entity types the node supports
     */
    async getNodeEntityTypes(requestParameters: GetNodeEntityTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getNodeEntityTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve details of the outgoing and incoming sync queue of this node
     */
    async getNodeSyncQueueDetailsRaw(requestParameters: GetNodeSyncQueueDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyncQueueDetails>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getNodeSyncQueueDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/syncQueue/details`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncQueueDetailsFromJSON(jsonValue));
    }

    /**
     * Retrieve details of the outgoing and incoming sync queue of this node
     */
    async getNodeSyncQueueDetails(requestParameters: GetNodeSyncQueueDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyncQueueDetails> {
        const response = await this.getNodeSyncQueueDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve details of a specific entity on a sync queue
     */
    async getNodeSyncQueueEntityDetailsRaw(requestParameters: GetNodeSyncQueueEntityDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNodeSyncQueueEntityDetails200Response>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getNodeSyncQueueEntityDetails().'
            );
        }

        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling getNodeSyncQueueEntityDetails().'
            );
        }

        if (requestParameters['direction'] == null) {
            throw new runtime.RequiredError(
                'direction',
                'Required parameter "direction" was null or undefined when calling getNodeSyncQueueEntityDetails().'
            );
        }

        if (requestParameters['isAttachment'] == null) {
            throw new runtime.RequiredError(
                'isAttachment',
                'Required parameter "isAttachment" was null or undefined when calling getNodeSyncQueueEntityDetails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['direction'] != null) {
            queryParameters['direction'] = requestParameters['direction'];
        }

        if (requestParameters['isAttachment'] != null) {
            queryParameters['isAttachment'] = requestParameters['isAttachment'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/syncQueue/details/{entity_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"entity_id"}}`, encodeURIComponent(String(requestParameters['entityId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNodeSyncQueueEntityDetails200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve details of a specific entity on a sync queue
     */
    async getNodeSyncQueueEntityDetails(requestParameters: GetNodeSyncQueueEntityDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNodeSyncQueueEntityDetails200Response> {
        const response = await this.getNodeSyncQueueEntityDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve statistics of the outgoing and incoming sync queue of this node
     */
    async getNodeSyncQueueStatisticsRaw(requestParameters: GetNodeSyncQueueStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling getNodeSyncQueueStatistics().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/syncQueue`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Retrieve statistics of the outgoing and incoming sync queue of this node
     */
    async getNodeSyncQueueStatistics(requestParameters: GetNodeSyncQueueStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getNodeSyncQueueStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all nodes
     */
    async getNodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NodeListviewItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeListviewItemFromJSON));
    }

    /**
     * Retrieve all nodes
     */
    async getNodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NodeListviewItem>> {
        const response = await this.getNodesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Remove the connection and the opposite connection from SyncRoom and unmanage it
     */
    async removeBothSidesOfAConnectionRaw(requestParameters: RemoveBothSidesOfAConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling removeBothSidesOfAConnection().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling removeBothSidesOfAConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the connection and the opposite connection from SyncRoom and unmanage it
     */
    async removeBothSidesOfAConnection(requestParameters: RemoveBothSidesOfAConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeBothSidesOfAConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * Start a test run on the target node for the incoming script
     */
    async startTestRunIncomingRaw(requestParameters: StartTestRunIncomingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StartTestRunIncoming200ResponseInner>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling startTestRunIncoming().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling startTestRunIncoming().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling startTestRunIncoming().'
            );
        }

        if (requestParameters['startTestRunIncomingRequestInner'] == null) {
            throw new runtime.RequiredError(
                'startTestRunIncomingRequestInner',
                'Required parameter "startTestRunIncomingRequestInner" was null or undefined when calling startTestRunIncoming().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/testrun/incoming`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['startTestRunIncomingRequestInner']!.map(StartTestRunIncomingRequestInnerToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StartTestRunIncoming200ResponseInnerFromJSON));
    }

    /**
     * Start a test run on the target node for the incoming script
     */
    async startTestRunIncoming(requestParameters: StartTestRunIncomingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StartTestRunIncoming200ResponseInner>> {
        const response = await this.startTestRunIncomingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start a test run on the source node for the outgoing script
     */
    async startTestRunOutgoingRaw(requestParameters: StartTestRunOutgoingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StartTestRunOutgoing200ResponseInner>>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling startTestRunOutgoing().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling startTestRunOutgoing().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling startTestRunOutgoing().'
            );
        }

        if (requestParameters['testRunIssue'] == null) {
            throw new runtime.RequiredError(
                'testRunIssue',
                'Required parameter "testRunIssue" was null or undefined when calling startTestRunOutgoing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/testrun/outgoing`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['testRunIssue']!.map(TestRunIssueToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StartTestRunOutgoing200ResponseInnerFromJSON));
    }

    /**
     * Start a test run on the source node for the outgoing script
     */
    async startTestRunOutgoing(requestParameters: StartTestRunOutgoingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StartTestRunOutgoing200ResponseInner>> {
        const response = await this.startTestRunOutgoingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connect a single entity
     */
    async syncStatusConnectEntityRaw(requestParameters: SyncStatusConnectEntityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling syncStatusConnectEntity().'
            );
        }

        if (requestParameters['entity'] == null) {
            throw new runtime.RequiredError(
                'entity',
                'Required parameter "entity" was null or undefined when calling syncStatusConnectEntity().'
            );
        }

        if (requestParameters['syncStatusConnectEntityRequest'] == null) {
            throw new runtime.RequiredError(
                'syncStatusConnectEntityRequest',
                'Required parameter "syncStatusConnectEntityRequest" was null or undefined when calling syncStatusConnectEntity().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/entitySyncStatus/entity/{entity}/connect`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"entity"}}`, encodeURIComponent(String(requestParameters['entity']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncStatusConnectEntityRequestToJSON(requestParameters['syncStatusConnectEntityRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connect a single entity
     */
    async syncStatusConnectEntity(requestParameters: SyncStatusConnectEntityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syncStatusConnectEntityRaw(requestParameters, initOverrides);
    }

    /**
     * exalate a single entity
     */
    async syncStatusExalateEntityRaw(requestParameters: SyncStatusExalateEntityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling syncStatusExalateEntity().'
            );
        }

        if (requestParameters['entity'] == null) {
            throw new runtime.RequiredError(
                'entity',
                'Required parameter "entity" was null or undefined when calling syncStatusExalateEntity().'
            );
        }

        if (requestParameters['syncStatusExalateEntityRequest'] == null) {
            throw new runtime.RequiredError(
                'syncStatusExalateEntityRequest',
                'Required parameter "syncStatusExalateEntityRequest" was null or undefined when calling syncStatusExalateEntity().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/entitySyncStatus/entity/{entity}/exalate`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"entity"}}`, encodeURIComponent(String(requestParameters['entity']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncStatusExalateEntityRequestToJSON(requestParameters['syncStatusExalateEntityRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * exalate a single entity
     */
    async syncStatusExalateEntity(requestParameters: SyncStatusExalateEntityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syncStatusExalateEntityRaw(requestParameters, initOverrides);
    }

    /**
     * Unexalate a single entity
     */
    async syncStatusUnexalateEntityRaw(requestParameters: SyncStatusUnexalateEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling syncStatusUnexalateEntity().'
            );
        }

        if (requestParameters['entity'] == null) {
            throw new runtime.RequiredError(
                'entity',
                'Required parameter "entity" was null or undefined when calling syncStatusUnexalateEntity().'
            );
        }

        if (requestParameters['syncStatusExalateEntityRequest'] == null) {
            throw new runtime.RequiredError(
                'syncStatusExalateEntityRequest',
                'Required parameter "syncStatusExalateEntityRequest" was null or undefined when calling syncStatusUnexalateEntity().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/entitySyncStatus/entity/{entity}/unexalate`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"entity"}}`, encodeURIComponent(String(requestParameters['entity']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncStatusExalateEntityRequestToJSON(requestParameters['syncStatusExalateEntityRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unexalate a single entity
     */
    async syncStatusUnexalateEntity(requestParameters: SyncStatusUnexalateEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syncStatusUnexalateEntityRaw(requestParameters, initOverrides);
    }

    /**
     * Toggle the status of a specific trigger from a connection version from a node
     */
    async toggleTriggerStatusRaw(requestParameters: ToggleTriggerStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling toggleTriggerStatus().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling toggleTriggerStatus().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling toggleTriggerStatus().'
            );
        }

        if (requestParameters['triggerId'] == null) {
            throw new runtime.RequiredError(
                'triggerId',
                'Required parameter "triggerId" was null or undefined when calling toggleTriggerStatus().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling toggleTriggerStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers/{trigger_id}/toggleStatus`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))).replace(`{${"trigger_id"}}`, encodeURIComponent(String(requestParameters['triggerId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggle the status of a specific trigger from a connection version from a node
     */
    async toggleTriggerStatus(requestParameters: ToggleTriggerStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleTriggerStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Bulk exalate all issues that match the trigger
     */
    async triggerBulkExalateRaw(requestParameters: TriggerBulkExalateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling triggerBulkExalate().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling triggerBulkExalate().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling triggerBulkExalate().'
            );
        }

        if (requestParameters['triggerId'] == null) {
            throw new runtime.RequiredError(
                'triggerId',
                'Required parameter "triggerId" was null or undefined when calling triggerBulkExalate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers/{trigger_id}/bulkexalate`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))).replace(`{${"trigger_id"}}`, encodeURIComponent(String(requestParameters['triggerId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk exalate all issues that match the trigger
     */
    async triggerBulkExalate(requestParameters: TriggerBulkExalateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerBulkExalateRaw(requestParameters, initOverrides);
    }

    /**
     * Bulk un-exalate all issues that match the trigger
     */
    async triggerBulkUnExalateRaw(requestParameters: TriggerBulkUnExalateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling triggerBulkUnExalate().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling triggerBulkUnExalate().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling triggerBulkUnExalate().'
            );
        }

        if (requestParameters['triggerId'] == null) {
            throw new runtime.RequiredError(
                'triggerId',
                'Required parameter "triggerId" was null or undefined when calling triggerBulkUnExalate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers/{trigger_id}/bulkunexalate`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))).replace(`{${"trigger_id"}}`, encodeURIComponent(String(requestParameters['triggerId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk un-exalate all issues that match the trigger
     */
    async triggerBulkUnExalate(requestParameters: TriggerBulkUnExalateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerBulkUnExalateRaw(requestParameters, initOverrides);
    }

    /**
     * Update the centrally managed value of a connection
     */
    async updateCentrallyManagedRaw(requestParameters: UpdateCentrallyManagedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling updateCentrallyManaged().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling updateCentrallyManaged().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling updateCentrallyManaged().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the centrally managed value of a connection
     */
    async updateCentrallyManaged(requestParameters: UpdateCentrallyManagedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCentrallyManagedRaw(requestParameters, initOverrides);
    }

    /**
     * Update both scripts from a connection version from a node
     */
    async updateConnectionScriptsRaw(requestParameters: UpdateConnectionScriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateConnectionScripts200Response>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling updateConnectionScripts().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling updateConnectionScripts().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling updateConnectionScripts().'
            );
        }

        if (requestParameters['scripts'] == null) {
            throw new runtime.RequiredError(
                'scripts',
                'Required parameter "scripts" was null or undefined when calling updateConnectionScripts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/scripts`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ScriptsToJSON(requestParameters['scripts']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateConnectionScripts200ResponseFromJSON(jsonValue));
    }

    /**
     * Update both scripts from a connection version from a node
     */
    async updateConnectionScripts(requestParameters: UpdateConnectionScriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateConnectionScripts200Response> {
        const response = await this.updateConnectionScriptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a node
     */
    async updateNodeRaw(requestParameters: UpdateNodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling updateNode().'
            );
        }

        if (requestParameters['updateNodeRequest'] == null) {
            throw new runtime.RequiredError(
                'updateNodeRequest',
                'Required parameter "updateNodeRequest" was null or undefined when calling updateNode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNodeRequestToJSON(requestParameters['updateNodeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a node
     */
    async updateNode(requestParameters: UpdateNodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNodeRaw(requestParameters, initOverrides);
    }

    /**
     * Update a specific trigger from a connection version from a node
     */
    async updateTriggerRaw(requestParameters: UpdateTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidatedTrigger>> {
        if (requestParameters['nodeId'] == null) {
            throw new runtime.RequiredError(
                'nodeId',
                'Required parameter "nodeId" was null or undefined when calling updateTrigger().'
            );
        }

        if (requestParameters['connectionId'] == null) {
            throw new runtime.RequiredError(
                'connectionId',
                'Required parameter "connectionId" was null or undefined when calling updateTrigger().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling updateTrigger().'
            );
        }

        if (requestParameters['triggerId'] == null) {
            throw new runtime.RequiredError(
                'triggerId',
                'Required parameter "triggerId" was null or undefined when calling updateTrigger().'
            );
        }

        if (requestParameters['triggerRequestBody'] == null) {
            throw new runtime.RequiredError(
                'triggerRequestBody',
                'Required parameter "triggerRequestBody" was null or undefined when calling updateTrigger().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/nodes/{node_id}/connections/{connection_id}/versions/{version_id}/triggers/{trigger_id}`.replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters['nodeId']))).replace(`{${"connection_id"}}`, encodeURIComponent(String(requestParameters['connectionId']))).replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters['versionId']))).replace(`{${"trigger_id"}}`, encodeURIComponent(String(requestParameters['triggerId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TriggerRequestBodyToJSON(requestParameters['triggerRequestBody']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidatedTriggerFromJSON(jsonValue));
    }

    /**
     * Update a specific trigger from a connection version from a node
     */
    async updateTrigger(requestParameters: UpdateTriggerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidatedTrigger> {
        const response = await this.updateTriggerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetNodeSyncQueueEntityDetailsDirectionEnum = {
    OUTGOING: 'OUTGOING',
    INCOMING: 'INCOMING'
} as const;
export type GetNodeSyncQueueEntityDetailsDirectionEnum = typeof GetNodeSyncQueueEntityDetailsDirectionEnum[keyof typeof GetNodeSyncQueueEntityDetailsDirectionEnum];
