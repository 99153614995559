/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorListInner
 */
export interface ErrorListInner {
    /**
     * 
     * @type {string}
     * @memberof ErrorListInner
     */
    message: string;
}

/**
 * Check if a given object implements the ErrorListInner interface.
 */
export function instanceOfErrorListInner(value: object): value is ErrorListInner {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ErrorListInnerFromJSON(json: any): ErrorListInner {
    return ErrorListInnerFromJSONTyped(json, false);
}

export function ErrorListInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorListInner {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

  export function ErrorListInnerToJSON(json: any): ErrorListInner {
      return ErrorListInnerToJSONTyped(json, false);
  }

  export function ErrorListInnerToJSONTyped(value?: ErrorListInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
    };
}

