/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A representation of an entity used by the entity sync status endpoints
 * @export
 * @interface EntitySyncStatus
 */
export interface EntitySyncStatus {
    /**
     * 
     * @type {string}
     * @memberof EntitySyncStatus
     */
    issueUrn: string;
    /**
     * The URL where you can find the entity on the task management system.
     * @type {string}
     * @memberof EntitySyncStatus
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof EntitySyncStatus
     */
    summary: string;
}

/**
 * Check if a given object implements the EntitySyncStatus interface.
 */
export function instanceOfEntitySyncStatus(value: object): value is EntitySyncStatus {
    if (!('issueUrn' in value) || value['issueUrn'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    return true;
}

export function EntitySyncStatusFromJSON(json: any): EntitySyncStatus {
    return EntitySyncStatusFromJSONTyped(json, false);
}

export function EntitySyncStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntitySyncStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'issueUrn': json['issueUrn'],
        'url': json['url'],
        'summary': json['summary'],
    };
}

  export function EntitySyncStatusToJSON(json: any): EntitySyncStatus {
      return EntitySyncStatusToJSONTyped(json, false);
  }

  export function EntitySyncStatusToJSONTyped(value?: EntitySyncStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'issueUrn': value['issueUrn'],
        'url': value['url'],
        'summary': value['summary'],
    };
}

