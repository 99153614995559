import { TestRunIssue } from "../../../../../generated/syncroom-api/src";
import { Tab } from "../../../../components/Tab";

type Props = {
  issues: TestRunIssue[];
  selectedIssue: TestRunIssue;
  setSelectedIssue: (issue: TestRunIssue) => void;
};

export const TicketSelection = ({
  issues,
  selectedIssue,
  setSelectedIssue,
}: Props) => {
  return (
    <div className="border-neutral-grey flex w-full overflow-x-scroll border-b">
      {issues.map((issue) => (
        <Tab
          key={issue.issueUrn}
          active={issue.issueUrn === selectedIssue.issueUrn}
          onClick={() => {
            setSelectedIssue(issue);
          }}
        >
          {issue.issueUrn}
        </Tab>
      ))}
    </div>
  );
};
