import { useNodeApi } from "../../../shared/useNodeApi";
import { Modal } from "../../../components/Modal";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ResponseError } from "../../../../generated/syncroom-api/src";
import { WarningBanner } from "../../../components/banner/WarningBanner";
import { defaultOnError } from "../../../util/errors";
import { Node } from "../../../../generated/syncroom-api/src/";
import { toast } from "react-toastify";

type Props = {
  nodeInfo: Node;
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
};

export const DeleteNodeModal = ({
  nodeInfo,
  setIsModalOpen,
  onClose,
}: Props) => {
  const [deleteTriedAndFailed, setDeleteTriedAndFailed] = useState(false);
  const api = useNodeApi();
  const queryClient = useQueryClient();

  const deleteNodeMutation = useMutation({
    mutationFn: () =>
      api.deleteNode({
        nodeId: nodeInfo.id,
        force: deleteTriedAndFailed,
      }),
    onError: async (error) => {
      if (!deleteTriedAndFailed && error.name === "ResponseError") {
        const responseError = error as ResponseError;
        if (responseError.response.status === 503) {
          setDeleteTriedAndFailed(true);
          return;
        }
      }
      defaultOnError(error);
    },
    onSuccess: async () => {
      // Remove everything, because we don't know which nodes are affected by this delete
      // Also use remove instead of invalidate, to avoid 404 errors, because invalidate triggers a refresh
      queryClient.removeQueries();
      toast.success(
        `Node ${nodeInfo.customName} has successfully been deleted.`,
      );
      onClose();
    },
  });

  return (
    <Modal
      size="large"
      toggleModal={setIsModalOpen}
      title="Remove node from SyncRoom"
      buttons={
        <>
          <Button onClick={() => onClose()} type={ButtonType.SECONDARY}>
            Cancel
          </Button>
          <Button
            onClick={() => deleteNodeMutation.mutate()}
            type={ButtonType.PRIMARY}
          >
            Confirm{deleteTriedAndFailed && " force removal"}
          </Button>
        </>
      }
      isLoading={deleteNodeMutation.isPending}
    >
      {deleteTriedAndFailed && (
        <WarningBanner>
          Removal of this node from SyncRoom has failed because it or any of its
          connected nodes is either archived or unreachable. You can force
          removal by pressing the 'Confirm' button again.
        </WarningBanner>
      )}
      Are you sure you want to remove this node from SyncRoom? This will
      unmanage all of it's managed connections, remove all saved versions and
      also remove any floating nodes.
    </Modal>
  );
};
