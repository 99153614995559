import { ReactComponent as JiraLogo } from "../../images/mark-gradient-blue-jira-software.svg";
import { ReactComponent as GitHubLogo } from "../../images/github-mark-white.svg";
import { ReactComponent as AzureDevOpsLogo } from "../../images/Azure-DevOps.svg";
import { ReactComponent as ServiceNowLogo } from "../../images/servicenow_logo_icon.svg";
import { ReactComponent as SalesForceLogo } from "../../images/salesforce.svg";
import { ReactComponent as ZendeskLogo } from "../../images/zendesk-icon-svgrepo-com.svg";
import { ReactComponent as NodeElement } from "../../images/node-element.svg";
import { ReactComponent as NodeElementSelected } from "../../images/node-element-selected.svg";
import { ReactComponent as NodeElementSelectedError } from "../../images/node-element-selected-error.svg";
import { NodeStatus, NodeType } from "../../../generated/syncroom-api/src";
import { FaCircleExclamation } from "react-icons/fa6";
import { IoCloudOffline } from "react-icons/io5";
import { FaRegQuestionCircle } from "react-icons/fa";
import React from "react";

type Props = {
  node: NodeTypeAndStatus;
  selected?: boolean;
};

type NodeTypeAndStatus = {
  type?: NodeType;
  status?: NodeStatus;
};

export const NodeIcon = ({ node, selected = false }: Props) => {
  const hasError = node.status !== NodeStatus.OK;

  let nodeElement;
  if (selected && hasError) {
    nodeElement = <NodeElementSelectedError width="64" height="64" />;
  } else if (selected) {
    nodeElement = <NodeElementSelected width="64" height="64" />;
  } else {
    nodeElement = <NodeElement width="64" height="64" />;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      aria-label={`${node.type} icon`}
    >
      {nodeElement}
      {getNodeTypeIcon(node.type)}
      {getNodeStatusIcon(node.status)}
    </svg>
  );
};

export const getNodeTypeIcon = (type?: NodeType) => {
  const defaultSizeAndOffset = { width: 32, height: 32, x: 16, y: 16 };

  switch (type) {
    case "JIRA_CLOUD":
      return <JiraLogo {...defaultSizeAndOffset} />;
    case "GITHUB":
      return <GitHubLogo {...defaultSizeAndOffset} />;
    case "AZURE_DEVOPS":
      return <AzureDevOpsLogo {...defaultSizeAndOffset} />;
    case "SALESFORCE":
      return <SalesForceLogo {...defaultSizeAndOffset} />;
    case "SERVICE_NOW":
      return <ServiceNowLogo {...defaultSizeAndOffset} />;
    case "ZENDESK":
      return <ZendeskLogo {...defaultSizeAndOffset} />;
    default:
      return <FaRegQuestionCircle color="#ABAFAD" x="16" y="16" size="32" />;
  }
};

const getNodeStatusIcon = (status?: NodeStatus) => {
  const icon = getStatusIcon(status);
  if (!icon) {
    return;
  }

  return (
    <svg width="20" height="20" x="44">
      {icon}
    </svg>
  );
};

const getStatusIcon = (status?: NodeStatus) => {
  switch (status) {
    case NodeStatus.ERROR:
      return <FaCircleExclamation size="100%" color="#CF0C0C" />;
    case NodeStatus.UNAVAILABLE:
      return <IoCloudOffline size="100%" color="#CF0C0C" />;
    default:
      return;
  }
};
