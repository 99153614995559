import { FaCircleCheck, FaCircleQuestion } from "react-icons/fa6";
import { NodeStatus } from "../../../generated/syncroom-api/src";
import { Chip, ChipColor } from "../Chip";
import { ErrorChip } from "../ErrorChip";
import { IoCloudOffline } from "react-icons/io5";

type Props = {
  status?: NodeStatus;
  hideOkStatus?: boolean;
  totalErrors?: number;
};

export const StatusIndicator = ({
  status,
  hideOkStatus = true,
  totalErrors = undefined,
}: Props) => {
  switch (status) {
    case NodeStatus.OK:
      if (hideOkStatus) {
        return null;
      } else {
        return (
          <Chip
            color={ChipColor.BLUE}
            leadingIcon={<FaCircleCheck title="Ok" />}
          />
        );
      }
    case NodeStatus.ERROR:
      return <ErrorChip totalErrors={totalErrors} />;
    case NodeStatus.UNAVAILABLE:
      return (
        <Chip
          color={ChipColor.RED}
          leadingIcon={<IoCloudOffline title="Unavailable" />}
        ></Chip>
      );
    default:
      return (
        <Chip
          color={ChipColor.GRAY}
          leadingIcon={<FaCircleQuestion title="Unknown" />}
        />
      );
  }
};
