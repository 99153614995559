import { ReactElement } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  icon: ReactElement;
  text: string;
  to?: string;
  onClick?: () => void;
};

export const SidebarItem = ({ icon, text, to = "", onClick }: Props) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        "flex h-11 w-full items-center justify-start gap-2 self-stretch rounded-lg px-3.5 py-1.5 " +
        (isActive
          ? "bg-violet-500 fill-white text-white"
          : "fill-bright-purple")
      }
      onClick={onClick}
    >
      <div className="flex h-5 w-5 items-center justify-center">{icon}</div>
      <div className="flex items-center justify-center gap-2.5">
        <div className="font-semibold leading-relaxed">{text}</div>
      </div>
    </NavLink>
  );
};
