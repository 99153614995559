import { useNodeApi } from "../../../../shared/useNodeApi";
import { useQuery } from "@tanstack/react-query";
import { SyncQueueDetailsModal } from "../../../../components/syncqueue/SyncQueueDetailsModal";

type Props = {
  nodeId: string;
  onClose: () => void;
  connection: {
    id: string;
    name: string;
    sourceNodeName: string;
    targetNodeName: string;
  };
};

export const ConnectionSyncQueueDetails = ({
  nodeId,
  onClose,
  connection,
}: Props) => {
  const api = useNodeApi();

  const syncQueueDetailsQuery = useQuery({
    queryKey: [
      "nodes",
      nodeId,
      "connections",
      connection.id,
      "syncqueue",
      "details",
    ],
    queryFn: () =>
      api.getConnectionSyncQueueDetails({
        nodeId,
        connectionId: connection.id,
      }),
  });

  return (
    <SyncQueueDetailsModal
      onClose={onClose}
      syncQueueDetails={syncQueueDetailsQuery.data}
      subHeader={`Connection: ${connection.name}`}
      sourceNodeName={connection.sourceNodeName}
      targetNodeName={connection.targetNodeName}
      isLoading={syncQueueDetailsQuery.isPending}
      error={syncQueueDetailsQuery.error}
    />
  );
};
