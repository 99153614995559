import { NodeError } from "../../../generated/syncroom-api/src";
import { Modal } from "../Modal";
import { NodeErrorItem } from "../errorModal/NodeErrorItem";
import { useState } from "react";
import { DataListItem } from "../DataListItem";
import { timestampToLocaleString } from "../../util/dateFormat";
import { Button, ButtonSize, ButtonType } from "../buttons/Button";

type Props = {
  nodeError: NodeError;
};

export const SyncQueueError = ({ nodeError }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {isModalOpen ? (
        <Modal title="Sync Queue Error" toggleModal={setIsModalOpen}>
          <NodeErrorItem nodeError={nodeError}></NodeErrorItem>
        </Modal>
      ) : (
        <div className="divide-neutral-light_grey divide-y">
          {nodeError.rootCause && (
            <DataListItem dt="Root cause">{nodeError.rootCause}</DataListItem>
          )}
          <DataListItem dt="Error type">{nodeError.type}</DataListItem>
          <DataListItem dt="Creation time">
            {timestampToLocaleString(nodeError.creationTime)}
          </DataListItem>
          <DataListItem dt="Stacktrace">
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap pb-1">
              {nodeError.stacktrace}
            </p>
            <Button
              type={ButtonType.TEXT_ONLY}
              size={ButtonSize.NONE}
              onClick={() => setIsModalOpen(true)}
            >
              <span className="underline">View error</span>
            </Button>
          </DataListItem>
        </div>
      )}
    </>
  );
};
