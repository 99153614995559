import { Field } from "formik";
import { InputHTMLAttributes, ReactElement } from "react";
import { FormLabel } from "./FormLabel";
import { FormFieldError } from "./FormFieldError";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  sublabel?: string;
  iconLeft?: ReactElement;
  name: string;
};

export const FormTextInput = ({
  label,
  sublabel,
  name,
  iconLeft,
  ...rest
}: Props) => {
  return (
    <div>
      <label>
        <FormLabel>{label}</FormLabel>
        {sublabel && (
          <div className="text-deep-purple-0 pb-2 text-sm font-medium leading-normal">
            {sublabel}
          </div>
        )}
        <div className="relative">
          {iconLeft && (
            <div className="text-bright-purple absolute left-4 top-3.5 size-4">
              {iconLeft}
            </div>
          )}
          <Field
            {...rest}
            className={`${iconLeft && "pl-12"} bg-neutral-lesser_white focus:border-primary focus:ring-bright-purple-2 w-full rounded-lg border border-slate-200 font-normal placeholder:text-gray-400 focus:outline-none focus:ring disabled:text-gray-400`}
            type="text"
            name={name}
          />
        </div>
      </label>
      <FormFieldError name={name} />
    </div>
  );
};
