import { useNodeApi } from "../../../shared/useNodeApi";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../../components/Loader";
import { FaChevronRight } from "react-icons/fa";
import { SyncQueueIcon } from "../../../icons/SyncQueueIcon";
import { Node, NodeStatus } from "../../../../generated/syncroom-api/src";
import { NodeSyncQueueDetails } from "./NodeSyncQueueDetails";
import { useState } from "react";

type Props = {
  node: Node;
};

export const NodeSyncQueue = ({ node }: Props) => {
  const [isSyncQueueDetailModalOpen, setIsSyncQueueDetailModalOpen] =
    useState<boolean>();
  const api = useNodeApi();

  const statisticsQuery = useQuery({
    queryKey: ["nodes", node.id, "syncqueue"],
    queryFn: () =>
      api.getNodeSyncQueueStatistics({
        nodeId: node.id,
      }),
    enabled: node.status !== NodeStatus.UNAVAILABLE,
  });

  if (statisticsQuery.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <>
      {statisticsQuery.data !== undefined && isSyncQueueDetailModalOpen && (
        <NodeSyncQueueDetails
          node={node}
          onClose={() => setIsSyncQueueDetailModalOpen(false)}
        />
      )}
      <div className="flex h-14 gap-2.5 rounded-xl border border-[#e4e2e8] bg-white">
        <div className="flex items-center gap-2.5 p-1.5">
          <div className="bg-neutral-lesser_grey flex items-center gap-1 rounded-md px-2.5 py-3">
            <SyncQueueIcon />
            <p className="text-sm font-bold">{statisticsQuery.data ?? "N/A"}</p>
          </div>
          <p className="text-sm font-medium">in queue</p>
        </div>
        {statisticsQuery.data !== undefined && (
          <div className="flex w-10 flex-col items-center">
            <button
              className="flex grow items-center justify-center rounded-r-lg border-l border-[#e4e2e8] bg-white px-2.5"
              aria-label="View Node SyncQueue details"
              onClick={() => setIsSyncQueueDetailModalOpen(true)}
            >
              <FaChevronRight className="text-bright-purple size-4" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};
