import { PageHeader } from "../components/layout/PageHeader";
import { useUserApi } from "../shared/useUserApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Loader } from "../components/Loader";
import { ResponseErrorMessage } from "../components/ResponseErrorMessage";
import { EmptyStatePage } from "../pages/EmptyStatePage";
import { UserRoleChip } from "./UserRoleChip";
import { Chip, ChipColor } from "../components/Chip";
import { Link } from "react-router-dom";
import { User, UserRoleEnum } from "../../generated/syncroom-api/src";
import { Button, ButtonSize, ButtonType } from "../components/buttons/Button";
import { useState } from "react";
import { FaPen, FaPlus, FaUserSlash } from "react-icons/fa";
import { Roles } from "../shared/Roles";
import { PageContent } from "../components/layout/PageContent";
import {
  ActionMenuSize,
  ActionsMenu,
  ButtonMenuItem,
} from "../components/ActionsMenu";
import { CreateEditUserModal } from "./CreateEditUserModal";
import { DeactivateUserModal } from "./DeactivateUserModal";
import { useAuth } from "react-oidc-context";
import { Card } from "../components/layout/Card";

export const UserListViewPage = () => {
  const [isCreateEditUserModalOpen, setIsCreateEditUserModalOpen] =
    useState(false);
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const api = useUserApi();
  const queryClient = useQueryClient();
  const queryKey = ["users"];
  const auth = useAuth();

  const loggedInUserEmail = auth.user?.profile?.email;

  const getAllUsersRequests = useQuery({
    queryKey,
    queryFn: () => api.getUsers(),
  });

  if (getAllUsersRequests.isPending) return <Loader />;
  if (getAllUsersRequests.isError)
    return (
      <ResponseErrorMessage error={getAllUsersRequests.error}>
        Error fetching users in SyncRoom
      </ResponseErrorMessage>
    );

  const users = getAllUsersRequests.data;

  if (users.length <= 0)
    return (
      <EmptyStatePage title="No users found" text="Users should be added" />
    );

  const toggleCreateEditUserModal = (user: User) => {
    setSelectedUser(user);
    setIsCreateEditUserModalOpen(!isCreateEditUserModalOpen);
  };

  const toggleDeactivateUserModal = (user: User) => {
    if (user) {
      setSelectedUser(user);
      setIsDeactivateUserModalOpen(!isDeactivateUserModalOpen);
    }
  };

  const buttons = (user: User): ButtonMenuItem[] => {
    const isDeactivated = user.isDeactivated;
    const isSystemAdmin = user.role === Roles.SYSTEM_ADMIN;
    const actionButtons: ButtonMenuItem[] = [];

    if (!isSystemAdmin) {
      actionButtons.push({
        buttonText: "Edit user",
        onClick: () => toggleCreateEditUserModal(user),
        iconRight: <FaPen />,
        guarded: true,
        guardedRole: Roles.SYSTEM_ADMIN,
      });
    }
    if (!isDeactivated) {
      actionButtons.push({
        buttonText: "Deactivate user",
        onClick: () => toggleDeactivateUserModal(user),
        iconRight: <FaUserSlash />,
        guarded: true,
        guardedRole: Roles.SYSTEM_ADMIN,
      });
    }
    return actionButtons;
  };

  return (
    <>
      {isCreateEditUserModalOpen && (
        <CreateEditUserModal
          user={selectedUser}
          onClose={() => {
            setIsCreateEditUserModalOpen(false);
            setSelectedUser(undefined);
          }}
          onSave={async () => {
            setSelectedUser(undefined);
            await queryClient.invalidateQueries({ queryKey });
            setIsCreateEditUserModalOpen(false);
          }}
        />
      )}

      {isDeactivateUserModalOpen && (
        <DeactivateUserModal
          user={selectedUser!}
          onClose={() => setIsDeactivateUserModalOpen(false)}
          onSave={async () => {
            await queryClient.invalidateQueries({ queryKey });
            setIsDeactivateUserModalOpen(false);
            setSelectedUser(undefined);
          }}
        ></DeactivateUserModal>
      )}

      <PageContent>
        <PageHeader>
          <h1 className="heading-1">Users overview</h1>
        </PageHeader>
        <Card
          header="All users"
          buttons={
            <Button
              onClick={() => setIsCreateEditUserModalOpen(true)}
              size={ButtonSize.MEDIUM}
              iconLeft={<FaPlus />}
            >
              Add user
            </Button>
          }
        >
          <table>
            <thead>
              <tr>
                <th className="w-80">Email</th>
                <th className="w-40">Role</th>
                <th className="w-full">Allowed networks</th>
                <th className="w-20"></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => {
                const isDeactivated = user.isDeactivated;
                const isSystemAdmin = user.role === Roles.SYSTEM_ADMIN;
                const shouldShowActionMenu =
                  (!isDeactivated && !isSystemAdmin) ||
                  (!isDeactivated &&
                    loggedInUserEmail !== user.email &&
                    isSystemAdmin);

                return (
                  <tr key={user.email}>
                    <td>
                      {user.email}
                      {isDeactivated && <span> (inactive)</span>}
                    </td>
                    <td>
                      <UserRoleChip role={user.role} />
                    </td>
                    <td>
                      {user.role === UserRoleEnum.SYSTEM_ADMIN ? (
                        <p>All networks are allowed for a System Admin</p>
                      ) : (
                        <div className="my-auto flex flex-wrap gap-2">
                          {user.assignedNetworks.map((network) => {
                            return (
                              <Link
                                to={`/networks/${network.networkId}`}
                                key={user.email + network.networkId}
                              >
                                <Chip color={ChipColor.GRAY}>
                                  {network.networkName}
                                </Chip>
                              </Link>
                            );
                          })}
                        </div>
                      )}
                    </td>
                    <td>
                      {shouldShowActionMenu && (
                        <ActionsMenu
                          buttons={buttons(user)}
                          type={ButtonType.SECONDARY}
                          size={ActionMenuSize.LARGE}
                          btnSize={ButtonSize.MEDIUM}
                          btnLabel={`Show Actions ${user.email}`}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      </PageContent>
    </>
  );
};
