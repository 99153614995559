import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { NodeErrorModal } from "./subcomponents/NodeErrorModal";
import { NetworkGraphic } from "../../network/detailPage/NetworkGraphic";
import {
  NodeStatus,
  SupportedFeature,
} from "../../../generated/syncroom-api/src";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useNodeApi } from "../../shared/useNodeApi";
import { Card } from "../../components/layout/Card";
import { PageHeader } from "../../components/layout/PageHeader";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../components/buttons/Button";
import { useNodeInfoQuery } from "../useNodeInfoQuery";
import { Loader } from "../../components/Loader";
import { NodeTypeIndicator } from "../../components/node/NodeTypeIndicator";
import { ResponseErrorMessage } from "../../components/ResponseErrorMessage";
import { EditNodeDetailsModal } from "./subcomponents/EditNodeDetailsModal";
import { DeleteNodeModal } from "./subcomponents/DeleteNodeModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Roles } from "../../shared/Roles";
import { UrlActionButton } from "../../components/buttons/UrlActionButton";
import { Chip, ChipColor } from "../../components/Chip";
import {
  ActionMenuSize,
  ActionsMenu,
  ButtonMenuItem,
} from "../../components/ActionsMenu";
import { FaPen, FaTrash } from "react-icons/fa";
import { NodeListAccordion } from "../../components/node/accordion/NodeListAccordion";
import { NodeSyncQueue } from "./subcomponents/NodeSyncQueue";
import { PageContent } from "../../components/layout/PageContent";
import { NodeUnavailableHeader } from "./subcomponents/NodeUnavailableHeader";
import { ReactComponent as SyncStatusIcon } from "../../images/queue-first-in-last-out.svg";

type Props = {
  nodeId: string;
};

export const NodeDetail = ({ nodeId }: Props) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteNodeModalOpen, setIsDeleteNodeModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const toggleErrorModal = () => {
    setIsErrorModalOpen(!isErrorModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleDeleteNodeModal = () => {
    setIsDeleteNodeModalOpen(!isDeleteNodeModalOpen);
  };

  const goBack = () => {
    const slicedPath = location.pathname.split("/").slice(0, -2).join("/"); // removes /nodes/{id} from path
    navigate(slicedPath.length === 0 ? "/nodes" : slicedPath);
  };

  const nodesApi = useNodeApi();

  const nodeInfoQuery = useNodeInfoQuery(nodeId);

  const connectedNodesQuery = useQuery({
    queryKey: ["nodes", nodeId],
    queryFn: () =>
      nodesApi.getConnectedNodes({
        nodeId,
      }),
  });

  const getConnectedNodesWithConnectionsQuery = useQuery({
    queryKey: ["nodes", nodeId, "allConnectedNodes"],
    queryFn: () => nodesApi.getAllConnectedNodes({ nodeId }),
  });

  if (
    nodeInfoQuery.isPending ||
    connectedNodesQuery.isPending ||
    getConnectedNodesWithConnectionsQuery.isPending
  )
    return <Loader />;
  if (nodeInfoQuery.isError)
    return (
      <ResponseErrorMessage error={nodeInfoQuery.error}>
        Error fetching node info
      </ResponseErrorMessage>
    );
  if (connectedNodesQuery.isError)
    return (
      <ResponseErrorMessage error={connectedNodesQuery.error}>
        Error fetching connected nodes
      </ResponseErrorMessage>
    );
  if (getConnectedNodesWithConnectionsQuery.isError)
    return (
      <ResponseErrorMessage error={getConnectedNodesWithConnectionsQuery.error}>
        Error fetching all connected nodes
      </ResponseErrorMessage>
    );

  const nodeInfo = nodeInfoQuery.data;
  const nodes = connectedNodesQuery.data;
  const allConnectedNodes = getConnectedNodesWithConnectionsQuery.data;

  const onSelectNode = (event: any) => {
    const nodeID = event.nodes[0];

    if (params.networkId !== undefined) {
      navigate(`/networks/${params.networkId}/nodes/${nodeID}`);
    } else {
      navigate(`/nodes/${nodeID}`);
    }
  };

  // According to https://forum.newrelic.com/s/hubtopic/aAX8W0000008d1BWAQ/link-to-logs-from-outside-new-relic
  const jumpToLogging = () => {
    const baseUrlPart = new URL(nodeInfo.baseUrl).hostname.split(".")[0];
    const launcher = JSON.stringify({
      isEntitled: true,
      query: `hostname:"*${baseUrlPart}*"`,
      eventTypes: ["Log_Logging"],
    });

    window.open(
      `https://one.newrelic.com/launcher/logger.log-launcher?launcher=${window.btoa(launcher)}`,
      "_blank",
    );
  };

  const buttons: ButtonMenuItem[] = [
    {
      buttonText: "Edit node settings",
      onClick: () => toggleEditModal(),
      disabled: false,
      iconRight: <FaPen />,
      label: "open edit",
    },
    {
      buttonText: "View Entity Sync Status",
      onClick: () => navigate("entity-sync-status"),
      disabled: false,
      iconRight: <SyncStatusIcon />,
      showButton: nodeInfoQuery.data.supportedFeatures.includes(
        SupportedFeature.ENTITY_SYNC_STATUS,
      ),
    },
    {
      buttonText: "Jump to logging",
      onClick: () => jumpToLogging(),
      disabled: false,
      iconRight: <FaArrowUpRightFromSquare />,
      guarded: true,
      guardedRole: Roles.SYSTEM_ADMIN,
      redirectToAccessDenied: false,
      label: "jump to logging",
    },
    {
      buttonText: "Remove node from SyncRoom",
      onClick: () => toggleDeleteNodeModal(),
      disabled: false,
      iconRight: <FaTrash />,
      guarded: true,
      guardedRole: Roles.SYSTEM_ADMIN,
      redirectToAccessDenied: false,
      label: "delete node",
    },
  ];

  return (
    <>
      {isEditModalOpen && (
        <EditNodeDetailsModal
          nodeInfo={nodeInfo}
          toggleModal={toggleEditModal}
        />
      )}
      {isErrorModalOpen && (
        <NodeErrorModal
          nodeId={nodeId}
          setIsModalOpen={setIsErrorModalOpen}
          onClose={toggleErrorModal}
        />
      )}
      {isDeleteNodeModalOpen && (
        <DeleteNodeModal
          nodeInfo={nodeInfo}
          setIsModalOpen={setIsDeleteNodeModalOpen}
          onClose={goBack}
        />
      )}
      {nodeInfo.status === NodeStatus.UNAVAILABLE && <NodeUnavailableHeader />}
      <PageContent>
        <PageHeader grid>
          <div className="flex justify-end gap-4">
            <Button
              onClick={() => window.open(nodeInfo.adminUrl)}
              iconRight={<FaArrowUpRightFromSquare />}
              size={ButtonSize.MEDIUM}
              type={ButtonType.SECONDARY}
            >
              Open admin
            </Button>
            {nodeInfo.status === NodeStatus.ERROR && (
              <div className="relative">
                <div className="bg-danger absolute -right-2 -top-2 flex size-6 items-center justify-center rounded-full text-white">
                  {nodeInfo.totalErrors}
                </div>
                <Button
                  type={ButtonType.SECONDARY}
                  size={ButtonSize.MEDIUM}
                  onClick={toggleErrorModal}
                  disabled={nodeInfo.status !== NodeStatus.ERROR}
                >
                  View errors
                </Button>
              </div>
            )}
            <ActionsMenu
              buttons={buttons}
              type={ButtonType.SECONDARY}
              size={ActionMenuSize.LARGE}
              btnSize={ButtonSize.MEDIUM}
            />
          </div>
          <div className="col-span-2 flex items-end gap-5">
            <div className="flex scale-125 items-center justify-center">
              <NodeTypeIndicator nodeType={nodeInfo.type} />
            </div>
            <h1 className="heading-1">{nodeInfo.customName}</h1>
          </div>
          <div className="col-span-2 flex h-16 items-end justify-between">
            <div className="flex flex-shrink-0 gap-3">
              <Chip
                color={ChipColor.SLATE}
              >{`Version: ${nodeInfo.rawVersion}`}</Chip>
              <UrlActionButton
                canCopy={true}
                canOpen={false}
                url={nodeInfo.baseUrl}
              >
                Node URL
              </UrlActionButton>
              <UrlActionButton
                canCopy={true}
                canOpen={true}
                url={nodeInfo.issueTrackerUrl}
              >
                {nodeInfo.type.toLowerCase().replace("_", " ")} URL
              </UrlActionButton>
              {nodeInfo.tags.length !== 0 &&
                nodeInfo.tags.map((tag) => (
                  <Chip key={tag} color={ChipColor.SLATE}>
                    {tag}
                  </Chip>
                ))}
            </div>
            {nodeInfo.supportedFeatures.includes(
              SupportedFeature.SYNC_QUEUE,
            ) && <NodeSyncQueue node={nodeInfo} />}
          </div>
        </PageHeader>
        <div className="grid grow grid-cols-2 gap-7">
          <Card padding={false} header="Network visual">
            <NetworkGraphic
              nodes={nodes}
              selectedNodeId={nodeId}
              onSelectNode={onSelectNode}
            />
          </Card>
          <Card header={`Connected nodes to ${nodeInfo.customName}`}>
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-end gap-5 rounded-xl bg-gray-100 px-5 py-3">
                <p className="grow text-sm font-medium leading-normal">
                  {allConnectedNodes.length} connected node(s)
                </p>
              </div>
              {allConnectedNodes.map((connectedNode) => {
                return (
                  <div
                    key={connectedNode.customName}
                    className="border-b-2 border-dashed border-zinc-200 py-6"
                  >
                    <NodeListAccordion
                      originalNode={nodeInfo}
                      connectedNode={connectedNode}
                    />
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
      </PageContent>
    </>
  );
};
