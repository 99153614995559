/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeError } from './NodeError';
import {
    NodeErrorFromJSON,
    NodeErrorFromJSONTyped,
    NodeErrorToJSON,
    NodeErrorToJSONTyped,
} from './NodeError';

/**
 * 
 * @export
 * @interface GetErrorsFromNode200Response
 */
export interface GetErrorsFromNode200Response {
    /**
     * 
     * @type {number}
     * @memberof GetErrorsFromNode200Response
     */
    total: number;
    /**
     * 
     * @type {Array<NodeError>}
     * @memberof GetErrorsFromNode200Response
     */
    results: Array<NodeError>;
}

/**
 * Check if a given object implements the GetErrorsFromNode200Response interface.
 */
export function instanceOfGetErrorsFromNode200Response(value: object): value is GetErrorsFromNode200Response {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function GetErrorsFromNode200ResponseFromJSON(json: any): GetErrorsFromNode200Response {
    return GetErrorsFromNode200ResponseFromJSONTyped(json, false);
}

export function GetErrorsFromNode200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetErrorsFromNode200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'results': ((json['results'] as Array<any>).map(NodeErrorFromJSON)),
    };
}

  export function GetErrorsFromNode200ResponseToJSON(json: any): GetErrorsFromNode200Response {
      return GetErrorsFromNode200ResponseToJSONTyped(json, false);
  }

  export function GetErrorsFromNode200ResponseToJSONTyped(value?: GetErrorsFromNode200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'total': value['total'],
        'results': ((value['results'] as Array<any>).map(NodeErrorToJSON)),
    };
}

