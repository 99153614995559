/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdeSuggestionItem } from './IdeSuggestionItem';
import {
    IdeSuggestionItemFromJSON,
    IdeSuggestionItemFromJSONTyped,
    IdeSuggestionItemToJSON,
    IdeSuggestionItemToJSONTyped,
} from './IdeSuggestionItem';

/**
 * The ide suggestion
 * @export
 * @interface IdeSuggestion
 */
export interface IdeSuggestion {
    /**
     * Indicates if the suggestion is quoted
     * @type {boolean}
     * @memberof IdeSuggestion
     */
    quoted: boolean;
    /**
     * List of variables associated with the suggestion
     * @type {Array<string>}
     * @memberof IdeSuggestion
     */
    variables: Array<string>;
    /**
     * Accessor for custom fields, if any
     * @type {string}
     * @memberof IdeSuggestion
     */
    accessor?: string;
    /**
     * 
     * @type {Array<IdeSuggestionItem>}
     * @memberof IdeSuggestion
     */
    items: Array<IdeSuggestionItem>;
}

/**
 * Check if a given object implements the IdeSuggestion interface.
 */
export function instanceOfIdeSuggestion(value: object): value is IdeSuggestion {
    if (!('quoted' in value) || value['quoted'] === undefined) return false;
    if (!('variables' in value) || value['variables'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function IdeSuggestionFromJSON(json: any): IdeSuggestion {
    return IdeSuggestionFromJSONTyped(json, false);
}

export function IdeSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdeSuggestion {
    if (json == null) {
        return json;
    }
    return {
        
        'quoted': json['quoted'],
        'variables': json['variables'],
        'accessor': json['accessor'] == null ? undefined : json['accessor'],
        'items': ((json['items'] as Array<any>).map(IdeSuggestionItemFromJSON)),
    };
}

  export function IdeSuggestionToJSON(json: any): IdeSuggestion {
      return IdeSuggestionToJSONTyped(json, false);
  }

  export function IdeSuggestionToJSONTyped(value?: IdeSuggestion | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'quoted': value['quoted'],
        'variables': value['variables'],
        'accessor': value['accessor'],
        'items': ((value['items'] as Array<any>).map(IdeSuggestionItemToJSON)),
    };
}

