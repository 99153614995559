/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Supported features on a node
 * @export
 */
export const SupportedFeature = {
    DRAFT_MODE: 'DRAFT_MODE',
    VALIDATE_SCRIPT: 'VALIDATE_SCRIPT',
    VALIDATE_TRIGGER: 'VALIDATE_TRIGGER',
    SYNC_QUEUE: 'SYNC_QUEUE',
    ENTITY_SYNC_STATUS: 'ENTITY_SYNC_STATUS'
} as const;
export type SupportedFeature = typeof SupportedFeature[keyof typeof SupportedFeature];


export function instanceOfSupportedFeature(value: any): boolean {
    for (const key in SupportedFeature) {
        if (Object.prototype.hasOwnProperty.call(SupportedFeature, key)) {
            if (SupportedFeature[key as keyof typeof SupportedFeature] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SupportedFeatureFromJSON(json: any): SupportedFeature {
    return SupportedFeatureFromJSONTyped(json, false);
}

export function SupportedFeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedFeature {
    return json as SupportedFeature;
}

export function SupportedFeatureToJSON(value?: SupportedFeature | null): any {
    return value as any;
}

export function SupportedFeatureToJSONTyped(value: any, ignoreDiscriminator: boolean): SupportedFeature {
    return value as SupportedFeature;
}

