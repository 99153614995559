import { FullScreenModal } from "../FullScreenModal";
import {
  GetNodeSyncQueueEntityDetailsDirectionEnum,
  SyncQueueDetails,
} from "../../../generated/syncroom-api/src";
import { SyncQueueDetailsCard } from "./SyncQueueDetailsCard";
import { ResponseErrorMessage } from "../ResponseErrorMessage";
import { Loader } from "../Loader";
import { useState } from "react";
import { SyncQueueEntityDetail } from "./SyncQueueEntityDetail";

type Props = {
  onClose: () => void;
  syncQueueDetails?: SyncQueueDetails;
  subHeader: string;
  sourceNodeName: string;
  targetNodeName: string;
  isLoading: boolean;
  error: Error | null;
};

export type SelectedEntity = {
  id: number;
  isAttachment: boolean;
  direction: GetNodeSyncQueueEntityDetailsDirectionEnum;
  nodeId: string;
};

export const SyncQueueDetailsModal = ({
  onClose,
  syncQueueDetails,
  subHeader,
  sourceNodeName,
  targetNodeName,
  isLoading,
  error,
}: Props) => {
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>();

  return (
    <FullScreenModal title="Sync Queue" subHeader={subHeader} onClose={onClose}>
      {error && (
        <div className="m-8">
          <ResponseErrorMessage error={error}>
            Error fetching Sync Queue details
          </ResponseErrorMessage>
        </div>
      )}
      {isLoading && <Loader />}
      {syncQueueDetails && !selectedEntity && (
        <div className="bg-neutral-lesser_grey grid h-full grid-cols-2 gap-5 px-10 py-8">
          <SyncQueueDetailsCard
            direction={GetNodeSyncQueueEntityDetailsDirectionEnum.OUTGOING}
            nodeName={sourceNodeName}
            syncQueueItems={syncQueueDetails.outgoing}
            selectEntity={(entity) => {
              setSelectedEntity(entity);
            }}
          />
          <SyncQueueDetailsCard
            direction={GetNodeSyncQueueEntityDetailsDirectionEnum.INCOMING}
            nodeName={targetNodeName}
            syncQueueItems={syncQueueDetails.incoming}
            selectEntity={(entity) => {
              setSelectedEntity(entity);
            }}
          />
        </div>
      )}
      {selectedEntity && (
        <SyncQueueEntityDetail
          goBack={() => setSelectedEntity(undefined)}
          selectedEntity={selectedEntity}
        />
      )}
    </FullScreenModal>
  );
};
