import { useNodeApi } from "../../../shared/useNodeApi";
import { useQuery } from "@tanstack/react-query";
import { Node } from "../../../../generated/syncroom-api/src";
import { SyncQueueDetailsModal } from "../../../components/syncqueue/SyncQueueDetailsModal";

type Props = {
  node: Node;
  onClose: () => void;
};

export const NodeSyncQueueDetails = ({ node, onClose }: Props) => {
  const api = useNodeApi();

  const syncQueueDetailsQuery = useQuery({
    queryKey: ["nodes", node.id, "syncqueue", "details"],
    queryFn: () =>
      api.getNodeSyncQueueDetails({
        nodeId: node.id,
      }),
  });

  return (
    <SyncQueueDetailsModal
      onClose={onClose}
      syncQueueDetails={syncQueueDetailsQuery.data}
      subHeader={`Node: ${node.customName}`}
      sourceNodeName={node.customName}
      targetNodeName={node.customName}
      isLoading={syncQueueDetailsQuery.isPending}
      error={syncQueueDetailsQuery.error}
    />
  );
};
