/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Trigger response object
 * @export
 * @interface Trigger
 */
export interface Trigger {
    /**
     * The unique identifier of a trigger in SyncRoom
     * @type {string}
     * @memberof Trigger
     */
    id?: string;
    /**
     * The query for the trigger, for example a JQL filter on a project key in jira cloud
     * @type {string}
     * @memberof Trigger
     */
    query?: string;
    /**
     * The type of entity that is affected by the trigger
     * @type {string}
     * @memberof Trigger
     */
    entityType: string;
    /**
     * 
     * @type {string}
     * @memberof Trigger
     */
    status: TriggerStatusEnum;
    /**
     * Some additional information about the trigger
     * @type {string}
     * @memberof Trigger
     */
    notes?: string;
}


/**
 * @export
 */
export const TriggerStatusEnum = {
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED'
} as const;
export type TriggerStatusEnum = typeof TriggerStatusEnum[keyof typeof TriggerStatusEnum];


/**
 * Check if a given object implements the Trigger interface.
 */
export function instanceOfTrigger(value: object): value is Trigger {
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function TriggerFromJSON(json: any): Trigger {
    return TriggerFromJSONTyped(json, false);
}

export function TriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trigger {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'query': json['query'] == null ? undefined : json['query'],
        'entityType': json['entityType'],
        'status': json['status'],
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

  export function TriggerToJSON(json: any): Trigger {
      return TriggerToJSONTyped(json, false);
  }

  export function TriggerToJSONTyped(value?: Trigger | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'query': value['query'],
        'entityType': value['entityType'],
        'status': value['status'],
        'notes': value['notes'],
    };
}

