import { Button, ButtonSize, ButtonType } from "./buttons/Button";
import { FaEllipsisVertical } from "react-icons/fa6";
import React, { ReactNode, useRef, useState } from "react";
import { useOutsideClick } from "../shared/useOutsideClick";
import { FaChevronRight } from "react-icons/fa";
import { GuardedComponent } from "./GuardedComponent";
import { Roles } from "../shared/Roles";
import { Toggle } from "./buttons/Toggle";

export type ButtonMenuItem = {
  buttonText: string;
  onClick?: () => void;
  disabled?: boolean;
  showButton?: boolean;
  iconRight?: ReactNode;
  guarded?: boolean;
  guardedRole?: Roles;
  redirectToAccessDenied?: boolean;
  label?: string;
  toggle?: boolean;
  toggleState?: boolean;
};

export enum ActionMenuSize {
  DEFAULT,
  LARGE,
}

type Props = {
  buttons: ButtonMenuItem[];
  type: ButtonType;
  size: ActionMenuSize;
  btnSize: ButtonSize;
  btnLabel?: string;
};

export const ActionsMenu = ({
  buttons,
  type,
  size,
  btnSize,
  btnLabel = "Show Actions",
}: Props) => {
  const [showMenu, setShowMenu] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setShowMenu(false));

  const handleButtonClick = (btn: ButtonMenuItem) => {
    if (btn.onClick) btn.onClick();
    setShowMenu(false);
  };

  return (
    <div className="relative">
      <Button
        iconLeft={<FaEllipsisVertical />}
        type={type}
        size={btnSize}
        onClick={() => setShowMenu(!showMenu)}
        label={btnLabel}
      />
      {showMenu && (
        <div
          ref={ref}
          role="menu"
          className={`border-neutral_lesser_grey absolute right-0 z-20 mt-2 ${getActionMenuSize(size)} rounded-lg border bg-white shadow`}
        >
          {buttons
            .filter((btn) => btn.showButton !== false)
            .map((btn) => {
              const button = (
                <button
                  key={btn.buttonText}
                  type="button"
                  disabled={btn.disabled}
                  className="border-neutral_lesser_grey flex w-full cursor-pointer items-center justify-between border-b bg-white p-4 text-left first:rounded-t-lg last:rounded-b-lg last:border-b-0 hover:bg-gray-50 disabled:cursor-default disabled:text-zinc-400 disabled:hover:bg-white"
                  onClick={() => handleButtonClick(btn)}
                  aria-label={btn.label}
                >
                  <span>{btn.buttonText}</span>
                  <span className="text-bright-purple">
                    {btn.iconRight}
                    {btn.toggle && !btn.iconRight && (
                      <Toggle setState={() => {}} state={btn.toggleState} />
                    )}
                    {!btn.toggle && !btn.iconRight && (
                      <FaChevronRight className="text-gray-400" />
                    )}
                  </span>
                </button>
              );

              return btn.guarded ? (
                <GuardedComponent
                  role={btn.guardedRole!}
                  key={btn.buttonText}
                  redirectToAccessDenied={btn.redirectToAccessDenied}
                >
                  {button}
                </GuardedComponent>
              ) : (
                button
              );
            })}
        </div>
      )}
    </div>
  );
};

const getActionMenuSize = (size: ActionMenuSize) => {
  switch (size) {
    case ActionMenuSize.DEFAULT:
      return "w-64";
    case ActionMenuSize.LARGE:
      return "w-80";
  }
};
