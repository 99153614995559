/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetIssuesForTestRun200ResponseInner
 */
export interface GetIssuesForTestRun200ResponseInner {
    /**
     * The id of this entity on the ticket management system
     * @type {string}
     * @memberof GetIssuesForTestRun200ResponseInner
     */
    id: string;
    /**
     * The URN of an issue
     * @type {string}
     * @memberof GetIssuesForTestRun200ResponseInner
     */
    issueUrn: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssuesForTestRun200ResponseInner
     */
    entityType: string;
    /**
     * The issue type (User story, Bug, Epic) This depends on the ticket management system
     * @type {string}
     * @memberof GetIssuesForTestRun200ResponseInner
     */
    type: string;
    /**
     * The summary/title of the issue
     * @type {string}
     * @memberof GetIssuesForTestRun200ResponseInner
     */
    summary: string;
}

/**
 * Check if a given object implements the GetIssuesForTestRun200ResponseInner interface.
 */
export function instanceOfGetIssuesForTestRun200ResponseInner(value: object): value is GetIssuesForTestRun200ResponseInner {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('issueUrn' in value) || value['issueUrn'] === undefined) return false;
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    return true;
}

export function GetIssuesForTestRun200ResponseInnerFromJSON(json: any): GetIssuesForTestRun200ResponseInner {
    return GetIssuesForTestRun200ResponseInnerFromJSONTyped(json, false);
}

export function GetIssuesForTestRun200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIssuesForTestRun200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'issueUrn': json['issueUrn'],
        'entityType': json['entityType'],
        'type': json['type'],
        'summary': json['summary'],
    };
}

  export function GetIssuesForTestRun200ResponseInnerToJSON(json: any): GetIssuesForTestRun200ResponseInner {
      return GetIssuesForTestRun200ResponseInnerToJSONTyped(json, false);
  }

  export function GetIssuesForTestRun200ResponseInnerToJSONTyped(value?: GetIssuesForTestRun200ResponseInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'issueUrn': value['issueUrn'],
        'entityType': value['entityType'],
        'type': value['type'],
        'summary': value['summary'],
    };
}

