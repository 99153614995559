/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * NodeError response object
 * @export
 * @interface NodeError
 */
export interface NodeError {
    /**
     * The identifier of a specific error on a node
     * @type {number}
     * @memberof NodeError
     */
    id: number;
    /**
     * The stacktrace of the error
     * @type {string}
     * @memberof NodeError
     */
    stacktrace: string;
    /**
     * Time in milliseconds in UTC timezone
     * @type {number}
     * @memberof NodeError
     */
    creationTime: number;
    /**
     * 
     * @type {string}
     * @memberof NodeError
     */
    type: NodeErrorTypeEnum;
    /**
     * The root cause of the error
     * @type {string}
     * @memberof NodeError
     */
    rootCause?: string;
    /**
     * Details about the root cause
     * @type {string}
     * @memberof NodeError
     */
    rootCauseDetails?: string;
    /**
     * The local issue URN to identify which issue causes the error
     * @type {string}
     * @memberof NodeError
     */
    localIssueUrn?: string;
    /**
     * The remote issue URN to identify which issue causes the error
     * @type {string}
     * @memberof NodeError
     */
    remoteIssueUrn?: string;
}


/**
 * @export
 */
export const NodeErrorTypeEnum = {
    NODE: 'NODE',
    RELATION: 'RELATION',
    TRIGGER: 'TRIGGER',
    ISSUE: 'ISSUE',
    WARNING: 'WARNING'
} as const;
export type NodeErrorTypeEnum = typeof NodeErrorTypeEnum[keyof typeof NodeErrorTypeEnum];


/**
 * Check if a given object implements the NodeError interface.
 */
export function instanceOfNodeError(value: object): value is NodeError {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('stacktrace' in value) || value['stacktrace'] === undefined) return false;
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function NodeErrorFromJSON(json: any): NodeError {
    return NodeErrorFromJSONTyped(json, false);
}

export function NodeErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeError {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stacktrace': json['stacktrace'],
        'creationTime': json['creationTime'],
        'type': json['type'],
        'rootCause': json['rootCause'] == null ? undefined : json['rootCause'],
        'rootCauseDetails': json['rootCauseDetails'] == null ? undefined : json['rootCauseDetails'],
        'localIssueUrn': json['localIssueUrn'] == null ? undefined : json['localIssueUrn'],
        'remoteIssueUrn': json['remoteIssueUrn'] == null ? undefined : json['remoteIssueUrn'],
    };
}

  export function NodeErrorToJSON(json: any): NodeError {
      return NodeErrorToJSONTyped(json, false);
  }

  export function NodeErrorToJSONTyped(value?: NodeError | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'stacktrace': value['stacktrace'],
        'creationTime': value['creationTime'],
        'type': value['type'],
        'rootCause': value['rootCause'],
        'rootCauseDetails': value['rootCauseDetails'],
        'localIssueUrn': value['localIssueUrn'],
        'remoteIssueUrn': value['remoteIssueUrn'],
    };
}

