import { JSONTree } from "react-json-tree";

type Props = {
  data: any;
};

export const ReplicaBody = ({ data }: Props) => {
  return (
    <JSONTree
      data={data}
      theme={theme}
      invertTheme={false}
      hideRoot={true}
      shouldExpandNodeInitially={(keyPath) => {
        return !keyPath.some(
          (item) => typeof item === "string" && collapsedNodes.includes(item),
        );
      }}
      getItemString={() => {
        //This will hide the type labels displayed by objects and arrays
        return null;
      }}
      valueRenderer={(valueAsString, value) => {
        if (typeof value === "string") {
          return value;
        } else {
          // valueAsString has type unknown and return type must be string
          return valueAsString as string;
        }
      }}
    />
  );
};

const collapsedNodes = ["comments", "attachments", "workLogs", "voters"];

const theme = {
  scheme: "exalate",
  base00: "#ffffff",
  base01: "#f5f4f1",
  base02: "#f8f8f2",
  base03: "#cacaca",
  base04: "#75715e",
  base05: "#49483e",
  base06: "#383830",
  base07: "#272822",
  base08: "#f92672",
  base09: "#1b9c64",
  base0A: "#f4bf75",
  base0B: "#383689",
  base0C: "#a1efe4",
  base0D: "#8381b5",
  base0E: "#ae81ff",
  base0F: "#cc6633",
};
