import { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};

export const AlternativeCard = ({ title, children }: Props) => {
  return (
    <div className="mb-4 w-full rounded-lg border">
      <div className="bg-neutral-lesser_grey flex items-center p-4">
        <p className="font-semibold">{title}</p>
      </div>
      {children}
    </div>
  );
};
