import React, { Suspense } from "react";
import { LoadingPage } from "../../../components/LoadingPage";
import { useNodeInfoQuery } from "../../../nodes/useNodeInfoQuery";
import { SuggestionsResponse } from "../../../../generated/syncroom-api/src";

const ScriptEditorCore = React.lazy(() => import("./ScriptEditorCoreModule"));

type Props = {
  nodeId: string;
  script: string;
  onChange: (script: string) => void;
  ideSuggestions?: SuggestionsResponse;
  error?: string;
};

export const ScriptEditor = ({
  nodeId,
  script,
  onChange,
  ideSuggestions,
  error,
}: Props) => {
  const nodeInfo = useNodeInfoQuery(nodeId);
  const isLoading = nodeInfo.isLoading || !ideSuggestions;

  const suggestions = {
    ...(ideSuggestions ?? { suggestions: [] }),
    isLoading: isLoading,
  };

  return (
    <div className="flex flex-grow flex-col justify-stretch">
      {!isLoading && (
        <Suspense fallback={<LoadingPage />}>
          <ScriptEditorCore
            theme="exalateTheme"
            value={script}
            onChange={onChange}
            language={`groovy_${nodeId}`}
            nodeType={nodeInfo.data?.type}
            ideSuggestions={suggestions}
            error={error}
            height="100%"
            options={{
              minimap: { enabled: false },
            }}
          />
        </Suspense>
      )}
    </div>
  );
};
