/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Script response object
 * @export
 * @interface Scripts
 */
export interface Scripts {
    /**
     * The content of a script
     * @type {string}
     * @memberof Scripts
     */
    incoming: string;
    /**
     * The content of a script
     * @type {string}
     * @memberof Scripts
     */
    outgoing: string;
}

/**
 * Check if a given object implements the Scripts interface.
 */
export function instanceOfScripts(value: object): value is Scripts {
    if (!('incoming' in value) || value['incoming'] === undefined) return false;
    if (!('outgoing' in value) || value['outgoing'] === undefined) return false;
    return true;
}

export function ScriptsFromJSON(json: any): Scripts {
    return ScriptsFromJSONTyped(json, false);
}

export function ScriptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Scripts {
    if (json == null) {
        return json;
    }
    return {
        
        'incoming': json['incoming'],
        'outgoing': json['outgoing'],
    };
}

  export function ScriptsToJSON(json: any): Scripts {
      return ScriptsToJSONTyped(json, false);
  }

  export function ScriptsToJSONTyped(value?: Scripts | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'incoming': value['incoming'],
        'outgoing': value['outgoing'],
    };
}

