import { ConnectionVersion } from "../../../../generated/syncroom-api/src";
import { FaInfoCircle } from "react-icons/fa";

type Props = {
  version?: ConnectionVersion;
};

export const VersionInfoBanner = ({ version }: Props) => {
  if (version == null) {
    return;
  }

  const getBackgroundColor = () => {
    switch (version.status) {
      case "ACTIVE":
        return "bg-primary";
      case "DRAFT":
        return "bg-green";
      case "ARCHIVED":
        return "bg-deep-purple-1";
      default:
        return "";
    }
  };

  return (
    <div
      className={`font-header flex items-center gap-3 px-5 py-4 text-white ${getBackgroundColor()}`}
    >
      <FaInfoCircle />
      {version.status === "ACTIVE" && (
        <p>
          You’re looking at an active version of this connection. To edit,
          create a new version or select an existing draft.
        </p>
      )}
      {version.status === "DRAFT" && (
        <p>
          You’re in draft mode now. You can edit and save and publish this
          version.
        </p>
      )}
      {version.status === "ARCHIVED" && (
        <p>
          You’re looking at an archived version of this connection. To edit,
          restore the version to a draft.
        </p>
      )}
    </div>
  );
};
