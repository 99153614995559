import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  active: boolean;
  onClick: () => void;
};

export const Tab = ({ children, active, onClick }: Props) => {
  return (
    <button
      className={`hover:bg-neutral-lesser_grey flex h-16 min-w-32 items-center justify-center p-2.5 ${active ? "border-b-bright-purple bg-neutral-lesser_grey border-b-4 pb-1.5" : ""}`}
      onClick={onClick}
    >
      <p className="text-primary font-bold">{children}</p>
    </button>
  );
};
