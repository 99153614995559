import React, { useState } from "react";
import { FaPencil, FaTrashCan } from "react-icons/fa6";
import { EditNetworkDetailsModal } from "./EditNetworkDetailsModal";
import { Network } from "../../../generated/syncroom-api/src";
import {
  ActionMenuSize,
  ActionsMenu,
  ButtonMenuItem,
} from "../../components/ActionsMenu";
import { ButtonSize, ButtonType } from "../../components/buttons/Button";
import { RemoveNetworkModal } from "./RemoveNetworkModal";
import { Roles } from "../../shared/Roles";

type Props = {
  networkInfo: Network;
};

export const NetworkActions = ({ networkInfo }: Props) => {
  const [isEditNetworkModalOpen, setIsEditNetworkModalOpen] = useState(false);
  const [isRemoveNetworkModalOpen, setIsRemoveNetworkModalOpen] =
    useState(false);

  const actions: ButtonMenuItem[] = [
    {
      buttonText: "Edit network settings",
      iconRight: <FaPencil />,
      disabled: false,
      onClick: () => setIsEditNetworkModalOpen(true),
    },
    {
      buttonText: "Remove network",
      iconRight: <FaTrashCan />,
      disabled: false,
      onClick: () => setIsRemoveNetworkModalOpen(true),
      guarded: true,
      guardedRole: Roles.SYSTEM_ADMIN,
      redirectToAccessDenied: false,
    },
  ];

  return (
    <>
      {isEditNetworkModalOpen && (
        <EditNetworkDetailsModal
          networkInfo={networkInfo}
          toggleModal={() => setIsEditNetworkModalOpen(false)}
        />
      )}
      {isRemoveNetworkModalOpen && (
        <RemoveNetworkModal
          networkId={networkInfo.id}
          networkName={networkInfo.name}
          toggleModal={() => setIsRemoveNetworkModalOpen(false)}
        />
      )}
      <ActionsMenu
        buttons={actions}
        size={ActionMenuSize.DEFAULT}
        type={ButtonType.SECONDARY}
        btnSize={ButtonSize.MEDIUM}
      />
    </>
  );
};
