import { useMemo } from "react";
import {
  SelectOption,
  SelectSize,
  SelectWrapper,
} from "../../../components/select/SelectWrapper";
import { ErrorChip } from "../../../components/ErrorChip";
import {
  ConnectionVersion,
  ConnectionVersionStatusEnum,
} from "../../../../generated/syncroom-api/src";

type Props = {
  versions: ConnectionVersion[];
  selectedVersionId?: string;
  setSelectedVersionId: (value: string) => void;
  totalErrors: number;
};

export const VersionsSelect = ({
  versions,
  selectedVersionId,
  setSelectedVersionId,
  totalErrors,
}: Props) => {
  const versionOptions: SelectOption[] = useMemo(() => {
    return versions.map((version) => ({
      value: version.id,
      label: (
        <span
          className={`flex items-center gap-2 ${version.status === ConnectionVersionStatusEnum.ARCHIVED ? "text-gray-400" : ""}`}
        >
          <span className="font-bold">Version {version.name}</span>
          {version.status === ConnectionVersionStatusEnum.ACTIVE && (
            <>
              {" "}
              (Active)
              {totalErrors > 0 && (
                <ErrorChip totalErrors={totalErrors} showOnlyTotal={true} />
              )}
            </>
          )}
          {version.status === ConnectionVersionStatusEnum.ARCHIVED && (
            <> (Archived)</>
          )}
          {version.status === ConnectionVersionStatusEnum.DRAFT && (
            <> (Draft)</>
          )}
        </span>
      ),
    }));
  }, [versions, totalErrors]);

  return (
    versions &&
    versions.length > 0 && (
      <SelectWrapper
        name="version"
        size={SelectSize.MEDIUM}
        options={versionOptions}
        value={versionOptions.find(
          (version) => version.value === selectedVersionId,
        )}
        onChange={(selectedVersion) =>
          setSelectedVersionId(selectedVersion!.value)
        }
        isSearchable={false}
      />
    )
  );
};
