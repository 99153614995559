import { FullScreenModal } from "../../../components/FullScreenModal";
import { useNodeApi } from "../../../shared/useNodeApi";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../../components/Loader";
import { ReplicaBody } from "../../../connections/detailPage/subcomponents/test-run/ReplicaBody";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import {
  EntitySyncStatus,
  EntitySyncStatusEntityState,
  GetNodeSyncQueueEntityDetailsDirectionEnum,
  Node,
} from "../../../../generated/syncroom-api/src";
import { EntityNodeBar } from "./EntityNodeBar";
import { AlternativeCard } from "../../../components/AlternativeCard";

type Props = {
  onClose: () => void;
  sourceNode: Node;
  localEntitySyncStatus: EntitySyncStatus;
  selectedEntitySyncStatusEntityState: EntitySyncStatusEntityState;
};

export const EntityCompareModal = ({
  onClose,
  sourceNode,
  localEntitySyncStatus,
  selectedEntitySyncStatusEntityState,
}: Props) => {
  const api = useNodeApi();

  const entityDetailsQuery = useQuery({
    queryKey: [
      "nodes",
      sourceNode.id,
      "entitySyncStatus",
      selectedEntitySyncStatusEntityState.twinTraceId,
    ],
    queryFn: () =>
      api.getEntitySyncStatusDetails({
        nodeId: sourceNode.id,
        twinTraceId: selectedEntitySyncStatusEntityState.twinTraceId,
      }),
  });

  return (
    <FullScreenModal
      title="Entity Sync Status details"
      subHeader={`${localEntitySyncStatus.issueUrn} to ${selectedEntitySyncStatusEntityState.remoteEntitySyncStatus?.issueUrn ?? "unknown entity"}`}
      onClose={onClose}
    >
      {entityDetailsQuery.isLoading && <Loader />}
      {entityDetailsQuery.isError && (
        <ResponseErrorMessage error={entityDetailsQuery.error}>
          Error fetching entity details
        </ResponseErrorMessage>
      )}
      {entityDetailsQuery.data && (
        <div className="mx-10 my-8 flex flex-col gap-5">
          <div className="flex gap-16">
            <EntityNodeBar
              nodeType={sourceNode.type}
              nodeName={sourceNode.customName}
              entityUrn={localEntitySyncStatus.issueUrn}
              direction={GetNodeSyncQueueEntityDetailsDirectionEnum.OUTGOING}
            />
            <EntityNodeBar
              nodeType={selectedEntitySyncStatusEntityState.nodeType}
              nodeName={selectedEntitySyncStatusEntityState.nodeName}
              entityUrn={
                selectedEntitySyncStatusEntityState.remoteEntitySyncStatus
                  ?.issueUrn
              }
              direction={GetNodeSyncQueueEntityDetailsDirectionEnum.INCOMING}
            />
          </div>
          <div className="flex gap-16">
            <AlternativeCard title="Local replica">
              <div className="mx-6">
                <ReplicaBody
                  data={JSON.parse(entityDetailsQuery.data.localReplica)}
                />
              </div>
            </AlternativeCard>
            <AlternativeCard title="Remote replica">
              {entityDetailsQuery.data.remoteReplica ? (
                <div className="mx-6">
                  <ReplicaBody
                    data={JSON.parse(entityDetailsQuery.data.remoteReplica)}
                  />
                </div>
              ) : (
                <p className="p-6">Remote replica is not (yet) available.</p>
              )}
            </AlternativeCard>
          </div>
        </div>
      )}
    </FullScreenModal>
  );
};
