/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNode } from './BaseNode';
import {
    BaseNodeFromJSON,
    BaseNodeFromJSONTyped,
    BaseNodeToJSON,
    BaseNodeToJSONTyped,
} from './BaseNode';

/**
 * Connection detail response object
 * @export
 * @interface ConnectionDetail
 */
export interface ConnectionDetail {
    /**
     * The unique identifier of a connection in SyncRoom
     * @type {string}
     * @memberof ConnectionDetail
     */
    id: string;
    /**
     * 
     * @type {BaseNode}
     * @memberof ConnectionDetail
     */
    sourceNode: BaseNode;
    /**
     * 
     * @type {BaseNode}
     * @memberof ConnectionDetail
     */
    targetNode: BaseNode;
    /**
     * The unique identifier of a connection version in SyncRoom
     * @type {string}
     * @memberof ConnectionDetail
     */
    activeVersionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDetail
     */
    centrallyManaged: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDetail
     */
    oppositeConnectionId: string;
    /**
     * Amount of errors currently on the connection
     * @type {number}
     * @memberof ConnectionDetail
     */
    totalErrors: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDetail
     */
    errorsOnOppositeConnection: boolean;
    /**
     * The name of the connection as known on the nodes
     * @type {string}
     * @memberof ConnectionDetail
     */
    name: string;
}

/**
 * Check if a given object implements the ConnectionDetail interface.
 */
export function instanceOfConnectionDetail(value: object): value is ConnectionDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('sourceNode' in value) || value['sourceNode'] === undefined) return false;
    if (!('targetNode' in value) || value['targetNode'] === undefined) return false;
    if (!('centrallyManaged' in value) || value['centrallyManaged'] === undefined) return false;
    if (!('oppositeConnectionId' in value) || value['oppositeConnectionId'] === undefined) return false;
    if (!('totalErrors' in value) || value['totalErrors'] === undefined) return false;
    if (!('errorsOnOppositeConnection' in value) || value['errorsOnOppositeConnection'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ConnectionDetailFromJSON(json: any): ConnectionDetail {
    return ConnectionDetailFromJSONTyped(json, false);
}

export function ConnectionDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceNode': BaseNodeFromJSON(json['sourceNode']),
        'targetNode': BaseNodeFromJSON(json['targetNode']),
        'activeVersionId': json['activeVersionId'] == null ? undefined : json['activeVersionId'],
        'centrallyManaged': json['centrallyManaged'],
        'oppositeConnectionId': json['oppositeConnectionId'],
        'totalErrors': json['totalErrors'],
        'errorsOnOppositeConnection': json['errorsOnOppositeConnection'],
        'name': json['name'],
    };
}

  export function ConnectionDetailToJSON(json: any): ConnectionDetail {
      return ConnectionDetailToJSONTyped(json, false);
  }

  export function ConnectionDetailToJSONTyped(value?: ConnectionDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'sourceNode': BaseNodeToJSON(value['sourceNode']),
        'targetNode': BaseNodeToJSON(value['targetNode']),
        'activeVersionId': value['activeVersionId'],
        'centrallyManaged': value['centrallyManaged'],
        'oppositeConnectionId': value['oppositeConnectionId'],
        'totalErrors': value['totalErrors'],
        'errorsOnOppositeConnection': value['errorsOnOppositeConnection'],
        'name': value['name'],
    };
}

