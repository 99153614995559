import { useMutation } from "@tanstack/react-query";
import { useNodeApi } from "../../shared/useNodeApi";
import { Loader } from "../../components/Loader";
import React, { useState } from "react";
import { useNodeInfoQuery } from "../useNodeInfoQuery";
import {
  EntitySearchForm,
  EntitySearchFormValues,
} from "./subcomponents/EntitySearchForm";
import { EntitySearchResults } from "./subcomponents/EntitySearchResults";
import { ResponseErrorMessage } from "../../components/ResponseErrorMessage";

type Props = {
  nodeId: string;
};

export const EntitySyncStatus = ({ nodeId }: Props) => {
  const nodesApi = useNodeApi();
  const nodeInfo = useNodeInfoQuery(nodeId);
  const [selectedEntityType, setSelectedEntityType] = useState<string>();

  const entityStateSearch = useMutation({
    mutationFn: (values: EntitySearchFormValues) =>
      nodesApi.getEntitySyncStatus({
        nodeId,
        entity: values.search,
        entityType: values.entityType,
      }),
  });

  if (nodeInfo.isLoading) {
    return <Loader />;
  }
  if (nodeInfo.isError) {
    return (
      <ResponseErrorMessage error={nodeInfo.error}>
        Error fetching node
      </ResponseErrorMessage>
    );
  }

  return (
    <>
      <EntitySearchForm
        nodeId={nodeId}
        onSearch={(data) => {
          setSelectedEntityType(data.entityType);
          entityStateSearch.mutate(data);
        }}
      />
      {entityStateSearch.isPending && (
        <div className="p-6">
          <Loader />
        </div>
      )}
      {entityStateSearch.isError && (
        <ResponseErrorMessage error={entityStateSearch.error}>
          Error searching for entity
        </ResponseErrorMessage>
      )}
      {entityStateSearch.data && nodeInfo.data && (
        <EntitySearchResults
          nodeInfo={nodeInfo.data}
          syncStatus={entityStateSearch.data}
          entityType={selectedEntityType ?? "entity"}
        />
      )}
    </>
  );
};
