/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdeSuggestion } from './IdeSuggestion';
import {
    IdeSuggestionFromJSON,
    IdeSuggestionFromJSONTyped,
    IdeSuggestionToJSON,
    IdeSuggestionToJSONTyped,
} from './IdeSuggestion';

/**
 * The code suggestions for the script editor
 * @export
 * @interface SuggestionsResponse
 */
export interface SuggestionsResponse {
    /**
     * 
     * @type {Array<IdeSuggestion>}
     * @memberof SuggestionsResponse
     */
    suggestions: Array<IdeSuggestion>;
}

/**
 * Check if a given object implements the SuggestionsResponse interface.
 */
export function instanceOfSuggestionsResponse(value: object): value is SuggestionsResponse {
    if (!('suggestions' in value) || value['suggestions'] === undefined) return false;
    return true;
}

export function SuggestionsResponseFromJSON(json: any): SuggestionsResponse {
    return SuggestionsResponseFromJSONTyped(json, false);
}

export function SuggestionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuggestionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'suggestions': ((json['suggestions'] as Array<any>).map(IdeSuggestionFromJSON)),
    };
}

  export function SuggestionsResponseToJSON(json: any): SuggestionsResponse {
      return SuggestionsResponseToJSONTyped(json, false);
  }

  export function SuggestionsResponseToJSONTyped(value?: SuggestionsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'suggestions': ((value['suggestions'] as Array<any>).map(IdeSuggestionToJSON)),
    };
}

