/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Connection response object
 * @export
 * @interface ConnectedNodeConnection
 */
export interface ConnectedNodeConnection {
    /**
     * The unique identifier of a connection in SyncRoom
     * @type {string}
     * @memberof ConnectedNodeConnection
     */
    connectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectedNodeConnection
     */
    connectionName: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectedNodeConnection
     */
    targetNodeCustomName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectedNodeConnection
     */
    inError?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectedNodeConnection
     */
    networkName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectedNodeConnection
     */
    managedBySyncRoom?: boolean;
}

/**
 * Check if a given object implements the ConnectedNodeConnection interface.
 */
export function instanceOfConnectedNodeConnection(value: object): value is ConnectedNodeConnection {
    if (!('connectionName' in value) || value['connectionName'] === undefined) return false;
    if (!('targetNodeCustomName' in value) || value['targetNodeCustomName'] === undefined) return false;
    return true;
}

export function ConnectedNodeConnectionFromJSON(json: any): ConnectedNodeConnection {
    return ConnectedNodeConnectionFromJSONTyped(json, false);
}

export function ConnectedNodeConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectedNodeConnection {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionId': json['connectionId'] == null ? undefined : json['connectionId'],
        'connectionName': json['connectionName'],
        'targetNodeCustomName': json['targetNodeCustomName'],
        'inError': json['inError'] == null ? undefined : json['inError'],
        'networkName': json['networkName'] == null ? undefined : json['networkName'],
        'managedBySyncRoom': json['managedBySyncRoom'] == null ? undefined : json['managedBySyncRoom'],
    };
}

  export function ConnectedNodeConnectionToJSON(json: any): ConnectedNodeConnection {
      return ConnectedNodeConnectionToJSONTyped(json, false);
  }

  export function ConnectedNodeConnectionToJSONTyped(value?: ConnectedNodeConnection | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'connectionId': value['connectionId'],
        'connectionName': value['connectionName'],
        'targetNodeCustomName': value['targetNodeCustomName'],
        'inError': value['inError'],
        'networkName': value['networkName'],
        'managedBySyncRoom': value['managedBySyncRoom'],
    };
}

