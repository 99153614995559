import { Button, ButtonSize, ButtonType } from "../buttons/Button";
import { FaArrowLeft } from "react-icons/fa";
import { DataListItem } from "../DataListItem";
import { SelectedEntity } from "./SyncQueueDetailsModal";
import { useNodeApi } from "../../shared/useNodeApi";
import { useQuery } from "@tanstack/react-query";
import { ResponseErrorMessage } from "../ResponseErrorMessage";
import { Loader } from "../Loader";
import { ReplicaBody } from "../../connections/detailPage/subcomponents/test-run/ReplicaBody";
import { SyncQueueError } from "./SyncQueueError";
import { AlternativeCard } from "../AlternativeCard";
import { GetNodeSyncQueueEntityDetailsDirectionEnum } from "../../../generated/syncroom-api/src";

type Props = {
  goBack: () => void;
  selectedEntity: SelectedEntity;
};

export const SyncQueueEntityDetail = ({ goBack, selectedEntity }: Props) => {
  const api = useNodeApi();
  const entityDetailsQuery = useQuery({
    queryKey: [
      "nodes",
      selectedEntity.nodeId,
      "syncQueue",
      "details",
      selectedEntity,
    ],
    queryFn: () =>
      api.getNodeSyncQueueEntityDetails({
        nodeId: selectedEntity.nodeId,
        entityId: selectedEntity.id,
        direction: selectedEntity.direction,
        isAttachment: selectedEntity.isAttachment,
      }),
  });

  if (entityDetailsQuery.isError)
    return (
      <div className="px-10 pt-5">
        <ResponseErrorMessage error={entityDetailsQuery.error}>
          Error fetching entity details
        </ResponseErrorMessage>
      </div>
    );
  if (entityDetailsQuery.isPending) return <Loader />;

  const entity = entityDetailsQuery.data;

  return (
    <>
      <div className="border-neutral-grey flex justify-between border-b px-10">
        <div className="flex h-16 items-center gap-5">
          <Button
            type={ButtonType.SECONDARY}
            size={ButtonSize.MEDIUM}
            iconLeft={<FaArrowLeft />}
            onClick={() => goBack()}
            label="Back to Sync Queue"
          />
          <p className="text-deep-purple text-base font-bold leading-relaxed">
            Back to Sync Queue /{" "}
            {selectedEntity.isAttachment ? "Attachment for entity" : "Entity"}:{" "}
            {(selectedEntity.direction ===
            GetNodeSyncQueueEntityDetailsDirectionEnum.OUTGOING
              ? entity.localIssueUrn
              : entity.remoteIssueUrn) ?? "Unknown"}
          </p>
        </div>
      </div>
      <div className="gap-6 px-10 py-8">
        <div className="grid grid-cols-2 gap-6">
          <AlternativeCard title="Sync details">
            <div className="divide-neutral-light_grey divide-y">
              <DataListItem dt="ID">{entity.id}</DataListItem>
              <DataListItem dt="Connection">
                {entity.connectionName ?? "Unknown"}
              </DataListItem>
              <DataListItem dt="Event number">
                {entity.eventNumber}
              </DataListItem>
              <DataListItem dt="Local entity URN">
                {entity.localIssueUrn ?? "Unknown"}
              </DataListItem>
              <DataListItem dt="Remote entity URN">
                {entity.remoteIssueUrn ?? "Unknown"}
              </DataListItem>
              {selectedEntity.isAttachment && (
                <>
                  {entity.fileName && (
                    <DataListItem dt={"File name"}>
                      {entity.fileName}
                    </DataListItem>
                  )}
                  {entity.fileSize && (
                    <DataListItem dt={"File size"}>
                      {entity.fileSize}KB
                    </DataListItem>
                  )}
                </>
              )}
            </div>
          </AlternativeCard>
          <AlternativeCard title="Error">
            {entity.error ? (
              <SyncQueueError nodeError={entity.error} />
            ) : (
              <p className="p-4">No error for this entity.</p>
            )}
          </AlternativeCard>
        </div>
        <AlternativeCard title={`${selectedEntity.direction} payload`}>
          <div className="mx-6">
            <ReplicaBody data={JSON.parse(entity.replica)} />
          </div>
        </AlternativeCard>
      </div>
    </>
  );
};
