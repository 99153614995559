import { Field, Form, Formik } from "formik";
import { FaSearch } from "react-icons/fa";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import { Loader } from "../../../components/Loader";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import * as yup from "yup";
import { FormFieldError } from "../../../components/form/FormFieldError";

type Props = {
  nodeId: string;
  onSearch: (data: EntitySearchFormValues) => void;
};

export type EntitySearchFormValues = {
  entityType: string;
  search: string;
};

const validationScheme = yup.object({
  entityType: yup.string().required(),
  search: yup.string().trim().required(),
});

export const EntitySearchForm = ({ nodeId, onSearch }: Props) => {
  const nodesApi = useNodeApi();
  const entityTypesRequest = useQuery({
    queryKey: [nodeId, "entityTypes"],
    queryFn: () =>
      nodesApi.getNodeEntityTypes({
        nodeId,
      }),
  });

  if (entityTypesRequest.isLoading) {
    return <Loader />;
  }
  if (entityTypesRequest.isError) {
    return (
      <ResponseErrorMessage error={entityTypesRequest.error}>
        Error fetching Entity Types
      </ResponseErrorMessage>
    );
  }

  return (
    <Formik<EntitySearchFormValues>
      validationSchema={validationScheme}
      initialValues={{
        entityType: "issue",
        search: "",
      }}
      onSubmit={(values) => {
        onSearch(values);
      }}
    >
      <Form noValidate className="p-6">
        <div className="focus-within:border-primary focus-within:ring-bright-purple-2 flex h-11 border-collapse items-stretch rounded-lg focus-within:outline-none focus-within:ring">
          <Field
            as="select"
            name="entityType"
            className="bg-neutral-lesser_white block w-36 flex-shrink-0 rounded-l-lg border border-r-0 border-slate-200 font-medium placeholder:text-gray-400 focus:border-slate-200 focus:outline-none focus:ring-0"
          >
            {entityTypesRequest.data?.map((entityType) => (
              <option key={entityType}>{entityType}</option>
            ))}
          </Field>
          <Field
            as="input"
            type="search"
            name="search"
            className="bg-neutral-lesser_white block w-full border border-slate-200 font-normal placeholder:text-gray-400 focus:border-slate-200 focus:outline-none focus:ring-0"
            placeholder="Search for entity (exact match)"
            required
          />
          <button
            type="submit"
            className="bg-bright-purple focus:ring-bright-purple-2 hover:bg-primary flex-shrink-0 rounded-r-lg p-2.5 text-sm font-medium text-white focus:outline-none focus:ring-0"
            aria-label="Search"
          >
            <FaSearch />
          </button>
        </div>
        <FormFieldError name="search" />
      </Form>
    </Formik>
  );
};
