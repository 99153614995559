/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The request body for the user modifiable part of a trigger
 * @export
 * @interface TriggerRequestBody
 */
export interface TriggerRequestBody {
    /**
     * The query for the trigger, for example a JQL filter on a project key in jira cloud
     * @type {string}
     * @memberof TriggerRequestBody
     */
    query?: string;
    /**
     * The type of entity that is affected by the trigger
     * @type {string}
     * @memberof TriggerRequestBody
     */
    entityType: string;
    /**
     * 
     * @type {string}
     * @memberof TriggerRequestBody
     */
    status: TriggerRequestBodyStatusEnum;
    /**
     * Some additional information about the trigger
     * @type {string}
     * @memberof TriggerRequestBody
     */
    notes?: string;
}


/**
 * @export
 */
export const TriggerRequestBodyStatusEnum = {
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED'
} as const;
export type TriggerRequestBodyStatusEnum = typeof TriggerRequestBodyStatusEnum[keyof typeof TriggerRequestBodyStatusEnum];


/**
 * Check if a given object implements the TriggerRequestBody interface.
 */
export function instanceOfTriggerRequestBody(value: object): value is TriggerRequestBody {
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function TriggerRequestBodyFromJSON(json: any): TriggerRequestBody {
    return TriggerRequestBodyFromJSONTyped(json, false);
}

export function TriggerRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TriggerRequestBody {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'] == null ? undefined : json['query'],
        'entityType': json['entityType'],
        'status': json['status'],
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

  export function TriggerRequestBodyToJSON(json: any): TriggerRequestBody {
      return TriggerRequestBodyToJSONTyped(json, false);
  }

  export function TriggerRequestBodyToJSONTyped(value?: TriggerRequestBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'query': value['query'],
        'entityType': value['entityType'],
        'status': value['status'],
        'notes': value['notes'],
    };
}

