import { ReactElement } from "react";

type Props = {
  icon: ReactElement;
  count: number;
  queueType: "entities" | "files";
};

export const SyncQueueCounter = ({ icon, count, queueType }: Props) => {
  return (
    <div className="bg-deep-purple-3 flex h-16 w-20 flex-col items-center justify-center rounded-md pt-0.5">
      <div className="flex items-center gap-1">
        <div className="text-bright-purple flex size-4 items-center justify-center">
          {icon}
        </div>
        <div className="w-4 text-center text-sm font-bold leading-normal">
          {count}
        </div>
      </div>
      <div className="text-sm font-medium">{queueType}</div>
    </div>
  );
};
