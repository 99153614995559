/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
    NodeTypeToJSONTyped,
} from './NodeType';

/**
 * 
 * @export
 * @interface SyncQueueDetailItemNode
 */
export interface SyncQueueDetailItemNode {
    /**
     * 
     * @type {string}
     * @memberof SyncQueueDetailItemNode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SyncQueueDetailItemNode
     */
    name: string;
    /**
     * 
     * @type {NodeType}
     * @memberof SyncQueueDetailItemNode
     */
    type: NodeType;
}



/**
 * Check if a given object implements the SyncQueueDetailItemNode interface.
 */
export function instanceOfSyncQueueDetailItemNode(value: object): value is SyncQueueDetailItemNode {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function SyncQueueDetailItemNodeFromJSON(json: any): SyncQueueDetailItemNode {
    return SyncQueueDetailItemNodeFromJSONTyped(json, false);
}

export function SyncQueueDetailItemNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncQueueDetailItemNode {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': NodeTypeFromJSON(json['type']),
    };
}

  export function SyncQueueDetailItemNodeToJSON(json: any): SyncQueueDetailItemNode {
      return SyncQueueDetailItemNodeToJSONTyped(json, false);
  }

  export function SyncQueueDetailItemNodeToJSONTyped(value?: SyncQueueDetailItemNode | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'type': NodeTypeToJSON(value['type']),
    };
}

