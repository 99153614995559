export const SyncQueueIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="queue-first-in-last-out">
      <path
        id="Vector"
        d="M4.5 4.5H13.5V6H4.5V4.5ZM4.5 7.875H13.5V9.375H4.5V7.875ZM4.5 11.25H13.5V12.75H4.5V11.25ZM6.75 14.25H11.25L9 16.5L6.75 14.25ZM6.75 1.5H11.25L9 3.75L6.75 1.5Z"
        fill="#8F64F9"
      />
    </g>
  </svg>
);
