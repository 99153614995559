import { useQuery } from "@tanstack/react-query";
import { FaPlus } from "react-icons/fa";
import React, { useState } from "react";
import { AddNodeModal } from "./addNodeModal/AddNodeModal";
import { useNetworksApi } from "../../shared/useNetworksApi";
import { PageHeader } from "../../components/layout/PageHeader";
import { Card } from "../../components/layout/Card";
import { Button, ButtonSize } from "../../components/buttons/Button";
import { NetworkGraphic } from "./NetworkGraphic";
import { useNetworkInfoQuery } from "../useNetworkInfoQuery";
import { Loader } from "../../components/Loader";
import { ResponseErrorMessage } from "../../components/ResponseErrorMessage";
import { useNavigate } from "react-router-dom";
import { NodeListAccordion } from "../../components/node/accordion/NodeListAccordion";
import { NetworkActions } from "./NetworkActions";
import { ResponseError } from "../../../generated/syncroom-api/src";
import { EmptyStatePage } from "../../pages/EmptyStatePage";
import { useNodeApi } from "../../shared/useNodeApi";
import { PageContent } from "../../components/layout/PageContent";

type Props = {
  networkId: string;
};

export const NetworkDetail = ({ networkId }: Props) => {
  const navigate = useNavigate();
  const [isAddNodeModalOpen, setIsAddNodeModalOpen] = useState(false);

  const networkApi = useNetworksApi();
  const nodeApi = useNodeApi();

  const nodesInNetwork = useQuery({
    queryKey: ["networks", networkId, "nodes"],
    queryFn: () =>
      networkApi.getNodesInNetwork({
        networkId,
      }),
  });
  const nodesWithConnections = useQuery({
    queryKey: ["networks", networkId, "allConnectedNodes"],
    queryFn: () => nodeApi.getAllConnectedNodes({ networkId }),
  });
  const networkInfoQuery = useNetworkInfoQuery(networkId);

  const emptyStatePage404 = (
    <EmptyStatePage
      hasButton={true}
      title="This network does not exist."
      text="It looks like the network you're trying to access has been removed or does not exist. Please check the network overview and try again."
      buttonText="Back to network overview"
      onClick={() => navigate(`/networks`)}
    />
  );

  if (
    nodesInNetwork.isPending ||
    networkInfoQuery.isPending ||
    nodesWithConnections.isPending
  )
    return <Loader />;

  if (nodesInNetwork.isError) {
    const responseError = nodesInNetwork.error as ResponseError;
    if (responseError.response.status === 404) {
      return emptyStatePage404;
    }

    return (
      <ResponseErrorMessage error={responseError}>
        Error fetching nodes
      </ResponseErrorMessage>
    );
  }

  if (networkInfoQuery.isError) {
    const responseError = networkInfoQuery.error as ResponseError;
    if (responseError.response.status === 404) {
      return emptyStatePage404;
    }

    return (
      <ResponseErrorMessage error={responseError}>
        Error fetching network info
      </ResponseErrorMessage>
    );
  }

  if (nodesWithConnections.isError) {
    const responseError = nodesWithConnections.error as ResponseError;
    return (
      <ResponseErrorMessage error={responseError}>
        Error fetching nodes
      </ResponseErrorMessage>
    );
  }

  const networkInfo = networkInfoQuery.data;

  const onSelectNode = (event: any) => {
    const nodeID = event.nodes[0];
    navigate(`nodes/${nodeID}`);
  };

  return (
    <>
      {isAddNodeModalOpen && (
        <AddNodeModal
          setIsModalOpen={setIsAddNodeModalOpen}
          networkId={networkId}
          onClose={async () => {
            setIsAddNodeModalOpen(false);
          }}
        />
      )}
      <PageContent>
        <PageHeader grid={true}>
          <div className="justify-self-end">
            <NetworkActions networkInfo={networkInfo} />
          </div>
          <div className="col-span-2">
            <h1 className="heading-1 my-5">{networkInfo.name}</h1>
            <p>{networkInfo.purpose}</p>
          </div>
        </PageHeader>
        <div className="grid grow grid-cols-2 gap-7">
          <Card padding={false} header="Network visual">
            <NetworkGraphic
              nodes={nodesInNetwork.data}
              selectedNodeId={null}
              filteredTags={[]}
              onSelectNode={onSelectNode}
            />
          </Card>
          <Card
            header={`Nodes in ${networkInfo.name}`}
            buttons={
              <Button
                onClick={() => setIsAddNodeModalOpen(true)}
                iconLeft={<FaPlus />}
                size={ButtonSize.MEDIUM}
              >
                Add Node
              </Button>
            }
          >
            <div className="flex flex-col">
              <p className="font-medium leading-relaxed text-zinc-400">
                {networkInfo.totalNodes > 0 && (
                  <>{networkInfo.totalNodes} nodes in this network</>
                )}
                {networkInfo.totalNodes === 0 && (
                  <>Your network is empty. Add a node to start.</>
                )}
              </p>
              {nodesWithConnections.data.map((node) => {
                return (
                  <div
                    key={node.baseUrl}
                    className="border-b-2 border-dashed border-zinc-200 py-6"
                  >
                    <NodeListAccordion
                      connectedNode={node}
                      fromNetwork={true}
                    />
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
      </PageContent>
    </>
  );
};
