import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useNodeApi } from "../../shared/useNodeApi";
import { Card } from "../../components/layout/Card";
import { PageHeader } from "../../components/layout/PageHeader";
import { NodeIconWithName } from "../../components/table/NodeIconWithName";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../components/buttons/Button";
import { StatusIndicator } from "../../components/node/StatusIndicator";
import { Loader } from "../../components/Loader";
import { ResponseErrorMessage } from "../../components/ResponseErrorMessage";
import { PageContent } from "../../components/layout/PageContent";

export const NodeListviewPage = () => {
  const navigate = useNavigate();
  const api = useNodeApi();
  const nodeListQuery = useQuery({
    queryKey: ["nodes"],
    queryFn: () => api.getNodes(),
  });

  if (nodeListQuery.isPending) return <Loader />;
  if (nodeListQuery.isError)
    return (
      <ResponseErrorMessage error={nodeListQuery.error}>
        Error fetching nodes
      </ResponseErrorMessage>
    );

  return (
    <PageContent>
      <PageHeader>
        <h1 className="heading-1">Nodes Overview</h1>
      </PageHeader>
      <Card header="Nodes">
        <table>
          <thead>
            <tr>
              <th scope="col" className="w-80">
                Name
              </th>
              <th scope="col" className="w-56">
                URL
              </th>
              <th scope="col" className="w-36">
                Version
              </th>
              <th scope="col" className="w-44">
                Connections
              </th>
              <th scope="col" className="w-32">
                Status
              </th>
              <th scope="col" className="w-40" />
            </tr>
          </thead>
          <tbody>
            {nodeListQuery.data.map((nodeOverviewItem) => {
              return (
                <tr
                  key={nodeOverviewItem.id}
                  onClick={() => navigate(nodeOverviewItem.id)}
                  className="hover:bg-neutral-lesser_grey cursor-pointer"
                >
                  <NodeIconWithName
                    type={nodeOverviewItem.type}
                    name={nodeOverviewItem.customName}
                  />
                  <td className="truncate">{nodeOverviewItem.baseUrl}</td>
                  <td>Version {nodeOverviewItem.version}</td>
                  <td>
                    {nodeOverviewItem.managedConnections} out of{" "}
                    {nodeOverviewItem.totalConnections} managed
                  </td>
                  <td>
                    <StatusIndicator
                      status={nodeOverviewItem.status}
                      totalErrors={nodeOverviewItem.totalErrors}
                    />
                  </td>
                  <td className="text-clip">
                    <Button
                      onClick={() => {}}
                      type={ButtonType.TERTIARY}
                      size={ButtonSize.MEDIUM}
                    >
                      View details
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </PageContent>
  );
};
