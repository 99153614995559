import { Modal } from "../../../components/Modal";
import React, { useState } from "react";
import { Button, ButtonType } from "../../../components/buttons/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ResponseError } from "../../../../generated/syncroom-api/src";
import { useNodeApi } from "../../../shared/useNodeApi";
import { handleResponseError } from "../../../util/errors";
import { ErrorBanner } from "../../../components/banner/ErrorBanner";

type Props = {
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
  nodeId: string;
  connectionId: string;
  centrallyManaged: boolean;
  sourceNodeName: string;
  targetNodeName: string;
};

export const ToggleCentrallyManagedModal = ({
  setIsModalOpen,
  onClose,
  nodeId,
  connectionId,
  centrallyManaged,
  sourceNodeName,
  targetNodeName,
}: Props) => {
  const [error, setError] = useState<string>();
  const api = useNodeApi();
  const queryClient = useQueryClient();

  const toggleCentrallyManagedMutate = useMutation<
    void,
    ResponseError,
    boolean
  >({
    mutationFn: (body: boolean) =>
      api.updateCentrallyManaged({
        nodeId,
        connectionId,
        body,
      }),
    onSuccess: async () => {
      onClose();
      // Use remove instead of invalidate, to avoid 404 errors, because invalidate triggers a refresh
      queryClient.removeQueries({
        queryKey: ["nodes", nodeId, "connections", connectionId],
      });
      await queryClient.invalidateQueries({
        queryKey: ["nodes", nodeId, "connections", connectionId],
        exact: true,
      });
    },
    onError: (error) => handleResponseError(error, (msg) => setError(msg)),
  });

  const actionText = centrallyManaged
    ? "Disable management in SyncRoom"
    : "Manage connection";

  return (
    <Modal
      toggleModal={setIsModalOpen}
      title={actionText}
      buttons={
        <div className="flex gap-3">
          <Button onClick={() => onClose()} type={ButtonType.SECONDARY}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              toggleCentrallyManagedMutate.mutate(!centrallyManaged)
            }
          >
            {actionText}
          </Button>
        </div>
      }
      isLoading={toggleCentrallyManagedMutate.isPending}
    >
      {error && <ErrorBanner>{error}</ErrorBanner>}
      {centrallyManaged ? (
        <p>
          The connection '{sourceNodeName} to {targetNodeName}' is currently
          managed in SyncRoom. You’re about to disable this function and move
          management to the external admin environment. Are you sure?
        </p>
      ) : (
        <p>
          Are you sure you want to manage the connection '{sourceNodeName} to{" "}
          {targetNodeName}'? This will disable local management of the
          connections on their ticket management systems.
        </p>
      )}
    </Modal>
  );
};
