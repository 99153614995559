import { NodeIcon } from "../../../components/node/NodeIcon";
import { Chip, ChipColor } from "../../../components/Chip";
import {
  GetNodeSyncQueueEntityDetailsDirectionEnum,
  NodeType,
} from "../../../../generated/syncroom-api/src";

type Props = {
  nodeType: NodeType | undefined;
  nodeName: string | undefined;
  entityUrn: string | undefined;
  direction: GetNodeSyncQueueEntityDetailsDirectionEnum;
};

export const EntityNodeBar = ({
  nodeType,
  nodeName,
  entityUrn,
  direction,
}: Props) => {
  const getDirectionText = () => {
    if (direction === GetNodeSyncQueueEntityDetailsDirectionEnum.OUTGOING)
      return "Replica sent from";
    else if (direction === GetNodeSyncQueueEntityDetailsDirectionEnum.INCOMING)
      return "Data applied on";
  };

  return (
    <div className="border-deep-purple-3 flex w-full items-center gap-4 rounded-lg border bg-white py-2 pl-2 pr-5">
      <div>
        <NodeIcon node={{ type: nodeType }} />
      </div>
      <p className="w-full font-bold">
        {`${getDirectionText()} ${entityUrn ?? "unknown entity"} on ${nodeName ?? "unknown node"} side`}
      </p>
      {entityUrn && <Chip color={ChipColor.BLUE}>{entityUrn}</Chip>}
    </div>
  );
};
