import { BsDownload, BsUpload } from "react-icons/bs";
import { NodeIcon } from "../node/NodeIcon";
import { Chip } from "../Chip";
import { Button, ButtonSize, ButtonType } from "../buttons/Button";
import { FaChevronRight } from "react-icons/fa";
import { Card } from "../layout/Card";
import {
  GetNodeSyncQueueEntityDetailsDirectionEnum,
  SyncQueueDetailItem,
} from "../../../generated/syncroom-api/src";
import { SelectedEntity } from "./SyncQueueDetailsModal";

type Props = {
  direction: GetNodeSyncQueueEntityDetailsDirectionEnum;
  nodeName: string;
  syncQueueItems: SyncQueueDetailItem[];
  selectEntity: (entity: SelectedEntity) => void;
};

export const SyncQueueDetailsCard = ({
  direction,
  nodeName,
  syncQueueItems,
  selectEntity,
}: Props) => {
  return (
    <Card
      header={`${direction[0] + direction.slice(1).toLowerCase()} sync queue of ${nodeName}`}
      buttons={
        <div className="bg-neutral-lesser_grey flex items-center gap-2 rounded-md px-2.5 py-3">
          {direction === GetNodeSyncQueueEntityDetailsDirectionEnum.OUTGOING ? (
            <BsUpload className="fill-bright-purple" />
          ) : (
            <BsDownload className="fill-bright-purple" />
          )}
          <p className="text-sm font-bold">{syncQueueItems.length}</p>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        {syncQueueItems.length === 0 && (
          <p className="text-deep-purple-1 text-sm font-medium">
            No entities remaining in {direction.toLowerCase()} sync queue
          </p>
        )}
        {syncQueueItems.length > 0 &&
          syncQueueItems.map((item) => {
            const key = `${item.id} - ${item.issueUrn} - ${item.connectionName} - ${item.isAttachment}`;
            return (
              <div
                className="border-deep-purple-3 flex items-center justify-between gap-5 rounded-lg border pl-4 pr-5"
                key={key}
              >
                <div className="flex items-center gap-4">
                  <NodeIcon node={{ type: item.node?.type }} />
                  <div>
                    <p className="font-bold">
                      {item.isAttachment ? "Attachment for " : ""}
                      {item.issueUrn}
                    </p>
                    <p>{item.connectionName ?? "Unknown connection"}</p>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <Chip small={true}>{item.status}</Chip>
                  <Button
                    label={`Open details of ${key}`}
                    type={ButtonType.TERTIARY}
                    iconRight={<FaChevronRight />}
                    size={ButtonSize.SMALL}
                    disabled={item.node === undefined}
                    onClick={() =>
                      selectEntity({
                        id: item.id,
                        isAttachment: item.isAttachment,
                        direction,
                        nodeId: item.node!.id,
                      })
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
    </Card>
  );
};
