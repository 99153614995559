import { ButtonSize, ButtonType } from "../../../components/buttons/Button";
import {
  ActionMenuSize,
  ActionsMenu,
  ButtonMenuItem,
} from "../../../components/ActionsMenu";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import { Loader } from "../../../components/Loader";
import { toast } from "react-toastify";
import { handleResponseError } from "../../../util/errors";

type Props = {
  nodeId: string;
  entityType: string;
  issueUrn: string;
  connectionId: string;
};

export const SyncEntityStatusActions = ({
  nodeId,
  entityType,
  issueUrn,
  connectionId,
}: Props) => {
  const nodeApi = useNodeApi();
  const unExalateMutation = useMutation({
    mutationFn: async () =>
      nodeApi.syncStatusUnexalateEntity({
        nodeId: nodeId,
        entity: issueUrn,
        syncStatusExalateEntityRequest: {
          entityType: entityType,
          connectionId: connectionId,
        },
      }),
    onSuccess: async () =>
      toast.success(`${entityType} ${issueUrn} is Unexalated Successfully!`),
    onError: async (error) =>
      handleResponseError(error, (msg) =>
        toast.error(`Failed to Unexalate ${entityType} ${issueUrn}: ${msg}`),
      ),
  });

  const options: ButtonMenuItem[] = [
    {
      buttonText: "Unexalate",
      onClick: () => unExalateMutation.mutate(),
    },
  ];

  if (unExalateMutation.isPending) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <ActionsMenu
      buttons={options}
      type={ButtonType.SECONDARY}
      size={ActionMenuSize.DEFAULT}
      btnSize={ButtonSize.MEDIUM}
    />
  );
};
