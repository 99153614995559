/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Object that contains the total amount of entities affected by the trigger, if validation is done and successful
 * @export
 * @interface ValidatedTrigger
 */
export interface ValidatedTrigger {
    /**
     * The total amount of all entities affected by the trigger
     * @type {number}
     * @memberof ValidatedTrigger
     */
    totalEntitiesAffected?: number;
}

/**
 * Check if a given object implements the ValidatedTrigger interface.
 */
export function instanceOfValidatedTrigger(value: object): value is ValidatedTrigger {
    return true;
}

export function ValidatedTriggerFromJSON(json: any): ValidatedTrigger {
    return ValidatedTriggerFromJSONTyped(json, false);
}

export function ValidatedTriggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatedTrigger {
    if (json == null) {
        return json;
    }
    return {
        
        'totalEntitiesAffected': json['totalEntitiesAffected'] == null ? undefined : json['totalEntitiesAffected'],
    };
}

  export function ValidatedTriggerToJSON(json: any): ValidatedTrigger {
      return ValidatedTriggerToJSONTyped(json, false);
  }

  export function ValidatedTriggerToJSONTyped(value?: ValidatedTrigger | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalEntitiesAffected': value['totalEntitiesAffected'],
    };
}

