import { IoCloudOffline } from "react-icons/io5";

export const NodeUnavailableHeader = () => {
  return (
    <div className="font-header bg-deep-purple-0 flex items-center gap-3 px-5 py-4 text-white">
      <IoCloudOffline className="text-xl" />
      Status: unavailable (we can’t reach this node)
    </div>
  );
};
