import { DataListItem } from "../DataListItem";
import { NodeError } from "../../../generated/syncroom-api/src";
import { timestampToLocaleString } from "../../util/dateFormat";

type Props = {
  nodeError: NodeError;
};

export const NodeErrorItem = ({ nodeError }: Props) => {
  return (
    <div className="divide-neutral-light_grey divide-y">
      <DataListItem dt="Error type">{nodeError.type}</DataListItem>
      <DataListItem dt="Creation time">
        {timestampToLocaleString(nodeError.creationTime)}
      </DataListItem>
      <DataListItem dt="Stacktrace">{nodeError.stacktrace}</DataListItem>
      {nodeError.localIssueUrn && (
        <DataListItem dt={"Local issue URN"}>
          {nodeError.localIssueUrn}
        </DataListItem>
      )}
      {nodeError.remoteIssueUrn && (
        <DataListItem dt={"Remote issue URN"}>
          {nodeError.remoteIssueUrn}
        </DataListItem>
      )}
      {nodeError.rootCause && (
        <DataListItem dt="Root cause">{nodeError.rootCause}</DataListItem>
      )}
      {nodeError.rootCauseDetails && (
        <DataListItem dt="Root cause details">
          {nodeError.rootCauseDetails}
        </DataListItem>
      )}
    </div>
  );
};
