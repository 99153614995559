/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetEntitySyncStatusDetails200Response
 */
export interface GetEntitySyncStatusDetails200Response {
    /**
     * A representation of a Replica as a string escaped JSON object
     * @type {string}
     * @memberof GetEntitySyncStatusDetails200Response
     */
    localReplica: string;
    /**
     * A representation of a Replica as a string escaped JSON object
     * @type {string}
     * @memberof GetEntitySyncStatusDetails200Response
     */
    remoteReplica?: string;
}

/**
 * Check if a given object implements the GetEntitySyncStatusDetails200Response interface.
 */
export function instanceOfGetEntitySyncStatusDetails200Response(value: object): value is GetEntitySyncStatusDetails200Response {
    if (!('localReplica' in value) || value['localReplica'] === undefined) return false;
    return true;
}

export function GetEntitySyncStatusDetails200ResponseFromJSON(json: any): GetEntitySyncStatusDetails200Response {
    return GetEntitySyncStatusDetails200ResponseFromJSONTyped(json, false);
}

export function GetEntitySyncStatusDetails200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEntitySyncStatusDetails200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'localReplica': json['localReplica'],
        'remoteReplica': json['remoteReplica'] == null ? undefined : json['remoteReplica'],
    };
}

  export function GetEntitySyncStatusDetails200ResponseToJSON(json: any): GetEntitySyncStatusDetails200Response {
      return GetEntitySyncStatusDetails200ResponseToJSONTyped(json, false);
  }

  export function GetEntitySyncStatusDetails200ResponseToJSONTyped(value?: GetEntitySyncStatusDetails200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'localReplica': value['localReplica'],
        'remoteReplica': value['remoteReplica'],
    };
}

