import { Modal } from "../../../components/Modal";
import { Form, Formik } from "formik";
import { FormSelectInput } from "../../../components/form/FormSelectInput";
import { useNodeApi } from "../../../shared/useNodeApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SelectOption } from "../../../components/select/SelectWrapper";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import { Button, ButtonType } from "../../../components/buttons/Button";
import * as yup from "yup";
import { FormTextInput } from "../../../components/form/FormTextInput";
import { FormToggle } from "../../../components/form/FormToggle";

type Props = {
  nodeId: string;
  entity: string;
  entityType: string;
  onClose: () => void;
};

export type FormValues = {
  connectionId: string;
  remoteIssueUrn: string;
  synchronizeComments: boolean;
  synchronizeAttachments: boolean;
  triggerUpdate: boolean;
};

const validationSchema = yup.object({
  connectionId: yup.string().required("Connection is a required field"),
  remoteIssueUrn: yup
    .string()
    .required("Remote Entity URN is a required field"),
  synchronizeComments: yup.boolean().required(),
  synchronizeAttachments: yup.boolean().required(),
  triggerUpdate: yup.boolean().required(),
});

export const SyncEntityStatusActionConnectModal = ({
  nodeId,
  entity,
  entityType,
  onClose,
}: Props) => {
  const nodeApi = useNodeApi();
  const connectionsQuery = useQuery({
    queryKey: ["nodes", nodeId, "connections"],
    queryFn: () =>
      nodeApi.getNodeConnections({
        nodeId,
      }),
  });
  const connectMutation = useMutation({
    mutationFn: (values: FormValues) => {
      return nodeApi.syncStatusConnectEntity({
        nodeId: nodeId,
        entity: entity,
        syncStatusConnectEntityRequest: {
          entityType: entityType,
          connectionId: values.connectionId,
          remoteIssueUrn: values.remoteIssueUrn,
          synchronizeComments: values.synchronizeComments,
          synchronizeAttachments: values.synchronizeAttachments,
          triggerUpdate: values.triggerUpdate,
        },
      });
    },
    onSuccess: async () => {
      onClose();
    },
  });

  const connectionOptions: SelectOption[] =
    connectionsQuery.data?.map((connection) => {
      return {
        value: connection.id,
        label: connection.name,
      };
    }) ?? [];

  return (
    <Formik<FormValues>
      initialValues={{
        connectionId: "",
        remoteIssueUrn: "",
        synchronizeComments: false,
        synchronizeAttachments: false,
        triggerUpdate: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => connectMutation.mutate(values)}
    >
      <Form noValidate>
        <Modal
          isLoading={connectionsQuery.isLoading || connectMutation.isPending}
          toggleModal={() => onClose()}
          title={`Connect ${entityType} ${entity}`}
          buttons={
            <>
              <Button type={ButtonType.SECONDARY} onClick={() => onClose()}>
                Cancel
              </Button>
              <Button buttonType="submit" disabled={connectMutation.isPending}>
                Connect
              </Button>
            </>
          }
        >
          <div className="flex flex-col gap-y-4">
            {connectionsQuery.error && (
              <ResponseErrorMessage error={connectionsQuery.error}>
                Error fetching connections
              </ResponseErrorMessage>
            )}
            {connectMutation.error && (
              <ResponseErrorMessage error={connectMutation.error}>
                Error in Connect entity
              </ResponseErrorMessage>
            )}
            <div>
              You are about to connect the {entityType} {entity}
            </div>
            <FormSelectInput
              label="Connection"
              name="connectionId"
              required={true}
              options={connectionOptions}
            />
            <FormTextInput label="Remote Entity URN" name="remoteIssueUrn" />
            <FormToggle label="Connect and sync data" name="triggerUpdate" />
            <FormToggle
              label="Sync existing comments?"
              name="synchronizeComments"
            />
            <FormToggle
              label="Sync existing attachments?"
              name="synchronizeAttachments"
            />
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
