import { useUserApi } from "../shared/useUserApi";
import { useMutation } from "@tanstack/react-query";
import { Button, ButtonType } from "../components/buttons/Button";
import { Modal } from "../components/Modal";
import { useState } from "react";
import { User } from "../../generated/syncroom-api/src";
import { ErrorBanner } from "../components/banner/ErrorBanner";
import { handleResponseError } from "../util/errors";
import { toast } from "react-toastify";

type Props = {
  onClose: () => void;
  onSave: () => void;
  user: User;
};

export const DeactivateUserModal = ({ onClose, onSave, user }: Props) => {
  const [error, setError] = useState<string>();
  const api = useUserApi();

  const deactivateUserMutation = useMutation({
    mutationFn: () =>
      api.deactivateUser({
        deactivateUserRequest: { email: user.email },
      }),
    onError: (error: Error) =>
      handleResponseError(error, (msg) => setError(msg)),
    onSuccess: async () => {
      toast.success("User successfully deactivated");
      onSave();
      onClose();
    },
  });

  return (
    <Modal
      title="Deactivate User"
      toggleModal={onClose}
      buttons={
        <div className="flex gap-3">
          <Button
            onClick={onClose}
            type={ButtonType.SECONDARY}
            disabled={deactivateUserMutation.isPending}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deactivateUserMutation.mutate()}
            type={ButtonType.PRIMARY}
            disabled={deactivateUserMutation.isPending}
          >
            Submit
          </Button>
        </div>
      }
    >
      <div className="space-y-5">
        {error && <ErrorBanner>{error}</ErrorBanner>}
        <p>Are you sure you want to deactivate the user: {user.email}?</p>
      </div>
    </Modal>
  );
};
