/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SyncQueueDetailItem } from './SyncQueueDetailItem';
import {
    SyncQueueDetailItemFromJSON,
    SyncQueueDetailItemFromJSONTyped,
    SyncQueueDetailItemToJSON,
    SyncQueueDetailItemToJSONTyped,
} from './SyncQueueDetailItem';

/**
 * An object containing two lists of SyncQueueDetailItems
 * @export
 * @interface SyncQueueDetails
 */
export interface SyncQueueDetails {
    /**
     * 
     * @type {Array<SyncQueueDetailItem>}
     * @memberof SyncQueueDetails
     */
    outgoing: Array<SyncQueueDetailItem>;
    /**
     * 
     * @type {Array<SyncQueueDetailItem>}
     * @memberof SyncQueueDetails
     */
    incoming: Array<SyncQueueDetailItem>;
}

/**
 * Check if a given object implements the SyncQueueDetails interface.
 */
export function instanceOfSyncQueueDetails(value: object): value is SyncQueueDetails {
    if (!('outgoing' in value) || value['outgoing'] === undefined) return false;
    if (!('incoming' in value) || value['incoming'] === undefined) return false;
    return true;
}

export function SyncQueueDetailsFromJSON(json: any): SyncQueueDetails {
    return SyncQueueDetailsFromJSONTyped(json, false);
}

export function SyncQueueDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncQueueDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'outgoing': ((json['outgoing'] as Array<any>).map(SyncQueueDetailItemFromJSON)),
        'incoming': ((json['incoming'] as Array<any>).map(SyncQueueDetailItemFromJSON)),
    };
}

  export function SyncQueueDetailsToJSON(json: any): SyncQueueDetails {
      return SyncQueueDetailsToJSONTyped(json, false);
  }

  export function SyncQueueDetailsToJSONTyped(value?: SyncQueueDetails | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'outgoing': ((value['outgoing'] as Array<any>).map(SyncQueueDetailItemToJSON)),
        'incoming': ((value['incoming'] as Array<any>).map(SyncQueueDetailItemToJSON)),
    };
}

