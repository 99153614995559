/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntitySyncStatus } from './EntitySyncStatus';
import {
    EntitySyncStatusFromJSON,
    EntitySyncStatusFromJSONTyped,
    EntitySyncStatusToJSON,
    EntitySyncStatusToJSONTyped,
} from './EntitySyncStatus';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
    NodeTypeToJSONTyped,
} from './NodeType';

/**
 * Status for each connections the issue is synced to
 * @export
 * @interface EntitySyncStatusEntityState
 */
export interface EntitySyncStatusEntityState {
    /**
     * 
     * @type {string}
     * @memberof EntitySyncStatusEntityState
     */
    connectionName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitySyncStatusEntityState
     */
    connectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitySyncStatusEntityState
     */
    nodeName?: string;
    /**
     * 
     * @type {NodeType}
     * @memberof EntitySyncStatusEntityState
     */
    nodeType?: NodeType;
    /**
     * 
     * @type {string}
     * @memberof EntitySyncStatusEntityState
     */
    status: EntitySyncStatusEntityStateStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof EntitySyncStatusEntityState
     */
    twinTraceId: number;
    /**
     * 
     * @type {EntitySyncStatus}
     * @memberof EntitySyncStatusEntityState
     */
    remoteEntitySyncStatus?: EntitySyncStatus;
}


/**
 * @export
 */
export const EntitySyncStatusEntityStateStatusEnum = {
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_REMOTE: 'WAITING_FOR_REMOTE',
    SYNCHRONIZED: 'SYNCHRONIZED',
    REMOTE_ISSUE_REMOVED: 'REMOTE_ISSUE_REMOVED',
    ERROR: 'ERROR',
    LICENSE_ERROR: 'LICENSE_ERROR',
    NONE: 'NONE',
    UNDER_CLEANUP: 'UNDER_CLEANUP'
} as const;
export type EntitySyncStatusEntityStateStatusEnum = typeof EntitySyncStatusEntityStateStatusEnum[keyof typeof EntitySyncStatusEntityStateStatusEnum];


/**
 * Check if a given object implements the EntitySyncStatusEntityState interface.
 */
export function instanceOfEntitySyncStatusEntityState(value: object): value is EntitySyncStatusEntityState {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('twinTraceId' in value) || value['twinTraceId'] === undefined) return false;
    return true;
}

export function EntitySyncStatusEntityStateFromJSON(json: any): EntitySyncStatusEntityState {
    return EntitySyncStatusEntityStateFromJSONTyped(json, false);
}

export function EntitySyncStatusEntityStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntitySyncStatusEntityState {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionName': json['connectionName'] == null ? undefined : json['connectionName'],
        'connectionId': json['connectionId'] == null ? undefined : json['connectionId'],
        'nodeName': json['nodeName'] == null ? undefined : json['nodeName'],
        'nodeType': json['nodeType'] == null ? undefined : NodeTypeFromJSON(json['nodeType']),
        'status': json['status'],
        'twinTraceId': json['twinTraceId'],
        'remoteEntitySyncStatus': json['remoteEntitySyncStatus'] == null ? undefined : EntitySyncStatusFromJSON(json['remoteEntitySyncStatus']),
    };
}

  export function EntitySyncStatusEntityStateToJSON(json: any): EntitySyncStatusEntityState {
      return EntitySyncStatusEntityStateToJSONTyped(json, false);
  }

  export function EntitySyncStatusEntityStateToJSONTyped(value?: EntitySyncStatusEntityState | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'connectionName': value['connectionName'],
        'connectionId': value['connectionId'],
        'nodeName': value['nodeName'],
        'nodeType': NodeTypeToJSON(value['nodeType']),
        'status': value['status'],
        'twinTraceId': value['twinTraceId'],
        'remoteEntitySyncStatus': EntitySyncStatusToJSON(value['remoteEntitySyncStatus']),
    };
}

