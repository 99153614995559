import { SyncQueueCounter } from "./SyncQueueCounter";
import { BsDownload, BsUpload } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../../../../shared/useNodeApi";
import { Loader } from "../../../../components/Loader";
import { ResponseErrorMessage } from "../../../../components/ResponseErrorMessage";
import { useState } from "react";
import { ConnectionSyncQueueDetails } from "./ConnectionSyncQueueDetails";
import { ConnectionDetail } from "../../../../../generated/syncroom-api/src";

type Props = {
  nodeId: string;
  connection: ConnectionDetail;
};

export const SyncQueueCounters = ({ nodeId, connection }: Props) => {
  const [isSyncQueueDetailModalOpen, setIsSyncQueueDetailModalOpen] =
    useState<boolean>();
  const api = useNodeApi();

  const statisticsQuery = useQuery({
    queryKey: ["nodes", nodeId, "connections", connection.id, "syncqueue"],
    queryFn: () =>
      api.getConnectionSyncQueueStatistics({
        nodeId,
        connectionId: connection.id,
      }),
  });

  if (statisticsQuery.isPending)
    return (
      <div>
        <Loader />
      </div>
    );
  if (statisticsQuery.isError)
    return (
      <ResponseErrorMessage error={statisticsQuery.error}>
        Error fetching connection sync queue statistics
      </ResponseErrorMessage>
    );

  const statistics = statisticsQuery.data;

  return (
    <>
      {isSyncQueueDetailModalOpen && (
        <ConnectionSyncQueueDetails
          nodeId={nodeId}
          onClose={() => setIsSyncQueueDetailModalOpen(false)}
          connection={{
            id: connection.id,
            name: connection.name,
            sourceNodeName: connection.sourceNode.customName,
            targetNodeName: connection.targetNode.customName,
          }}
        />
      )}
      <button
        className="flex items-center gap-6"
        aria-label="View Connection SyncQueue details"
        onClick={() => setIsSyncQueueDetailModalOpen(true)}
      >
        <div className="flex gap-2">
          <SyncQueueCounter
            icon={<BsUpload />}
            count={statistics.outgoing}
            queueType={"entities"}
          />
          <SyncQueueCounter
            icon={<BsUpload />}
            count={statistics.outgoingAttachments}
            queueType={"files"}
          />
        </div>
        <div className="flex gap-2">
          <SyncQueueCounter
            icon={<BsDownload />}
            count={statistics.incoming}
            queueType={"entities"}
          />
          <SyncQueueCounter
            icon={<BsDownload />}
            count={statistics.incomingAttachments}
            queueType={"files"}
          />
        </div>
      </button>
    </>
  );
};
