/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SyncQueueDetailItemNode } from './SyncQueueDetailItemNode';
import {
    SyncQueueDetailItemNodeFromJSON,
    SyncQueueDetailItemNodeFromJSONTyped,
    SyncQueueDetailItemNodeToJSON,
    SyncQueueDetailItemNodeToJSONTyped,
} from './SyncQueueDetailItemNode';

/**
 * An object containing details about a specific sync queue item
 * @export
 * @interface SyncQueueDetailItem
 */
export interface SyncQueueDetailItem {
    /**
     * 
     * @type {number}
     * @memberof SyncQueueDetailItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SyncQueueDetailItem
     */
    issueUrn: string;
    /**
     * 
     * @type {string}
     * @memberof SyncQueueDetailItem
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof SyncQueueDetailItem
     */
    connectionName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SyncQueueDetailItem
     */
    isAttachment: boolean;
    /**
     * 
     * @type {SyncQueueDetailItemNode}
     * @memberof SyncQueueDetailItem
     */
    node?: SyncQueueDetailItemNode;
}

/**
 * Check if a given object implements the SyncQueueDetailItem interface.
 */
export function instanceOfSyncQueueDetailItem(value: object): value is SyncQueueDetailItem {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('issueUrn' in value) || value['issueUrn'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('isAttachment' in value) || value['isAttachment'] === undefined) return false;
    return true;
}

export function SyncQueueDetailItemFromJSON(json: any): SyncQueueDetailItem {
    return SyncQueueDetailItemFromJSONTyped(json, false);
}

export function SyncQueueDetailItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncQueueDetailItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'issueUrn': json['issueUrn'],
        'status': json['status'],
        'connectionName': json['connectionName'] == null ? undefined : json['connectionName'],
        'isAttachment': json['isAttachment'],
        'node': json['node'] == null ? undefined : SyncQueueDetailItemNodeFromJSON(json['node']),
    };
}

  export function SyncQueueDetailItemToJSON(json: any): SyncQueueDetailItem {
      return SyncQueueDetailItemToJSONTyped(json, false);
  }

  export function SyncQueueDetailItemToJSONTyped(value?: SyncQueueDetailItem | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'issueUrn': value['issueUrn'],
        'status': value['status'],
        'connectionName': value['connectionName'],
        'isAttachment': value['isAttachment'],
        'node': SyncQueueDetailItemNodeToJSON(value['node']),
    };
}

