/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Connection and entity type and Sync options
 * @export
 * @interface SyncStatusConnectEntityRequest
 */
export interface SyncStatusConnectEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncStatusConnectEntityRequest
     */
    connectionId: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusConnectEntityRequest
     */
    entityType: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusConnectEntityRequest
     */
    remoteIssueUrn: string;
    /**
     * Sync existing comments
     * @type {boolean}
     * @memberof SyncStatusConnectEntityRequest
     */
    synchronizeComments: boolean;
    /**
     * Sync existing attachments
     * @type {boolean}
     * @memberof SyncStatusConnectEntityRequest
     */
    synchronizeAttachments: boolean;
    /**
     * Connect and sync data
     * @type {boolean}
     * @memberof SyncStatusConnectEntityRequest
     */
    triggerUpdate: boolean;
}

/**
 * Check if a given object implements the SyncStatusConnectEntityRequest interface.
 */
export function instanceOfSyncStatusConnectEntityRequest(value: object): value is SyncStatusConnectEntityRequest {
    if (!('connectionId' in value) || value['connectionId'] === undefined) return false;
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    if (!('remoteIssueUrn' in value) || value['remoteIssueUrn'] === undefined) return false;
    if (!('synchronizeComments' in value) || value['synchronizeComments'] === undefined) return false;
    if (!('synchronizeAttachments' in value) || value['synchronizeAttachments'] === undefined) return false;
    if (!('triggerUpdate' in value) || value['triggerUpdate'] === undefined) return false;
    return true;
}

export function SyncStatusConnectEntityRequestFromJSON(json: any): SyncStatusConnectEntityRequest {
    return SyncStatusConnectEntityRequestFromJSONTyped(json, false);
}

export function SyncStatusConnectEntityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncStatusConnectEntityRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionId': json['connectionId'],
        'entityType': json['entityType'],
        'remoteIssueUrn': json['remoteIssueUrn'],
        'synchronizeComments': json['synchronizeComments'],
        'synchronizeAttachments': json['synchronizeAttachments'],
        'triggerUpdate': json['triggerUpdate'],
    };
}

  export function SyncStatusConnectEntityRequestToJSON(json: any): SyncStatusConnectEntityRequest {
      return SyncStatusConnectEntityRequestToJSONTyped(json, false);
  }

  export function SyncStatusConnectEntityRequestToJSONTyped(value?: SyncStatusConnectEntityRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'connectionId': value['connectionId'],
        'entityType': value['entityType'],
        'remoteIssueUrn': value['remoteIssueUrn'],
        'synchronizeComments': value['synchronizeComments'],
        'synchronizeAttachments': value['synchronizeAttachments'],
        'triggerUpdate': value['triggerUpdate'],
    };
}

