/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NodeError } from './NodeError';
import {
    NodeErrorFromJSON,
    NodeErrorFromJSONTyped,
    NodeErrorToJSON,
    NodeErrorToJSONTyped,
} from './NodeError';

/**
 * 
 * @export
 * @interface GetNodeSyncQueueEntityDetails200Response
 */
export interface GetNodeSyncQueueEntityDetails200Response {
    /**
     * 
     * @type {number}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    eventNumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    localIssueUrn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    remoteIssueUrn?: string;
    /**
     * A representation of a Replica as a string escaped JSON object
     * @type {string}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    replica: string;
    /**
     * 
     * @type {string}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    connectionName?: string;
    /**
     * 
     * @type {NodeError}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    error?: NodeError;
    /**
     * 
     * @type {string}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof GetNodeSyncQueueEntityDetails200Response
     */
    fileSize?: number;
}

/**
 * Check if a given object implements the GetNodeSyncQueueEntityDetails200Response interface.
 */
export function instanceOfGetNodeSyncQueueEntityDetails200Response(value: object): value is GetNodeSyncQueueEntityDetails200Response {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('eventNumber' in value) || value['eventNumber'] === undefined) return false;
    if (!('replica' in value) || value['replica'] === undefined) return false;
    return true;
}

export function GetNodeSyncQueueEntityDetails200ResponseFromJSON(json: any): GetNodeSyncQueueEntityDetails200Response {
    return GetNodeSyncQueueEntityDetails200ResponseFromJSONTyped(json, false);
}

export function GetNodeSyncQueueEntityDetails200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNodeSyncQueueEntityDetails200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'eventNumber': json['eventNumber'],
        'localIssueUrn': json['localIssueUrn'] == null ? undefined : json['localIssueUrn'],
        'remoteIssueUrn': json['remoteIssueUrn'] == null ? undefined : json['remoteIssueUrn'],
        'replica': json['replica'],
        'connectionName': json['connectionName'] == null ? undefined : json['connectionName'],
        'error': json['error'] == null ? undefined : NodeErrorFromJSON(json['error']),
        'fileName': json['fileName'] == null ? undefined : json['fileName'],
        'fileSize': json['fileSize'] == null ? undefined : json['fileSize'],
    };
}

  export function GetNodeSyncQueueEntityDetails200ResponseToJSON(json: any): GetNodeSyncQueueEntityDetails200Response {
      return GetNodeSyncQueueEntityDetails200ResponseToJSONTyped(json, false);
  }

  export function GetNodeSyncQueueEntityDetails200ResponseToJSONTyped(value?: GetNodeSyncQueueEntityDetails200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'eventNumber': value['eventNumber'],
        'localIssueUrn': value['localIssueUrn'],
        'remoteIssueUrn': value['remoteIssueUrn'],
        'replica': value['replica'],
        'connectionName': value['connectionName'],
        'error': NodeErrorToJSON(value['error']),
        'fileName': value['fileName'],
        'fileSize': value['fileSize'],
    };
}

