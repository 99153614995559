import React from "react";
import { useNodeApi } from "../../../shared/useNodeApi";
import { useQuery } from "@tanstack/react-query";
import { ScriptEditorHeader } from "./ScriptEditorHeader";
import { ScriptEditor } from "./ScriptEditor";

type Props = {
  nodeId: string;
  header: string;
  subHeader: string;
  script: string;
  onChange: (script: string) => void;
  error?: string;
};

export const ScriptEditorContainer = ({
  nodeId,
  header,
  subHeader,
  script,
  onChange,
  error,
}: Props) => {
  const api = useNodeApi();
  const ideSuggestions = useQuery({
    queryKey: ["ideSuggestions", nodeId],
    queryFn: () =>
      api.getIdeSuggestions({
        nodeId,
      }),
    retry: false,
    enabled: window.env.REACT_APP_EDITOR_SUGGESTIONS_ENABLED === "true",
  });

  return (
    <>
      <ScriptEditorHeader
        header={header}
        subHeader={subHeader}
        error={
          ideSuggestions.isError
            ? "Failed to fetch suggestions from the node, suggestions are disabled."
            : undefined
        }
      />
      <ScriptEditor
        nodeId={nodeId}
        script={script}
        onChange={onChange}
        ideSuggestions={ideSuggestions.data}
        error={error}
      />
    </>
  );
};
