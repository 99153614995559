import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../Loader";
import { useNodeApi } from "../../../shared/useNodeApi";
import { useState } from "react";
import { ConnectionSyncQueueDetails } from "../../../connections/detailPage/subcomponents/syncqueue/ConnectionSyncQueueDetails";
import { BsDownload, BsUpload } from "react-icons/bs";
import { ErrorChip } from "../../ErrorChip";

type Props = {
  nodeId: string;
  sourceNodeName: string;
  targetNodeName: string;
  connectionId: string;
  connectionName: string;
};

export const AccordionConnectionSyncQueueStatistics = ({
  nodeId,
  sourceNodeName,
  targetNodeName,
  connectionId,
  connectionName,
}: Props) => {
  const [isSyncQueueDetailModalOpen, setIsSyncQueueDetailModalOpen] =
    useState<boolean>();
  const api = useNodeApi();

  const statisticsQuery = useQuery({
    queryKey: ["nodes", nodeId, "connections", connectionId, "syncqueue"],
    queryFn: () =>
      api.getConnectionSyncQueueStatistics({
        nodeId,
        connectionId,
      }),
  });

  if (statisticsQuery.isPending)
    return (
      <div>
        <Loader />
      </div>
    );
  if (statisticsQuery.isError)
    return (
      <div className="flex items-end px-2.5">
        <ErrorChip />
      </div>
    );

  const statistics = statisticsQuery.data;

  return (
    <>
      {isSyncQueueDetailModalOpen && connectionId && (
        <ConnectionSyncQueueDetails
          nodeId={nodeId}
          onClose={() => setIsSyncQueueDetailModalOpen(false)}
          connection={{
            id: connectionId,
            name: connectionName,
            sourceNodeName,
            targetNodeName,
          }}
        />
      )}
      <button
        className="flex items-end gap-2"
        aria-label="View Connection SyncQueue details"
        onClick={() => setIsSyncQueueDetailModalOpen(true)}
      >
        <div className="flex items-center gap-1">
          <BsUpload className="fill-bright-purple" />
          {statistics.outgoing + statistics.outgoingAttachments}
        </div>
        <div className="flex items-center gap-1">
          <BsDownload className="fill-bright-purple" />
          {statistics.incoming + statistics.incomingAttachments}
        </div>
      </button>
    </>
  );
};
