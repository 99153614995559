/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateConnectionVersion201Response
 */
export interface CreateConnectionVersion201Response {
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionVersion201Response
     */
    versionId: string;
}

/**
 * Check if a given object implements the CreateConnectionVersion201Response interface.
 */
export function instanceOfCreateConnectionVersion201Response(value: object): value is CreateConnectionVersion201Response {
    if (!('versionId' in value) || value['versionId'] === undefined) return false;
    return true;
}

export function CreateConnectionVersion201ResponseFromJSON(json: any): CreateConnectionVersion201Response {
    return CreateConnectionVersion201ResponseFromJSONTyped(json, false);
}

export function CreateConnectionVersion201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConnectionVersion201Response {
    if (json == null) {
        return json;
    }
    return {
        
        'versionId': json['versionId'],
    };
}

  export function CreateConnectionVersion201ResponseToJSON(json: any): CreateConnectionVersion201Response {
      return CreateConnectionVersion201ResponseToJSONTyped(json, false);
  }

  export function CreateConnectionVersion201ResponseToJSONTyped(value?: CreateConnectionVersion201Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'versionId': value['versionId'],
    };
}

