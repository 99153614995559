/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntitySyncStatus } from './EntitySyncStatus';
import {
    EntitySyncStatusFromJSON,
    EntitySyncStatusFromJSONTyped,
    EntitySyncStatusToJSON,
    EntitySyncStatusToJSONTyped,
} from './EntitySyncStatus';
import type { EntitySyncStatusEntityState } from './EntitySyncStatusEntityState';
import {
    EntitySyncStatusEntityStateFromJSON,
    EntitySyncStatusEntityStateFromJSONTyped,
    EntitySyncStatusEntityStateToJSON,
    EntitySyncStatusEntityStateToJSONTyped,
} from './EntitySyncStatusEntityState';

/**
 * Success response containing if the entity exists, more info about the entity and the sync status of this entity on other task management systems.
 * @export
 * @interface EntitySyncStatusEntity
 */
export interface EntitySyncStatusEntity {
    /**
     * 
     * @type {boolean}
     * @memberof EntitySyncStatusEntity
     */
    entityExists: boolean;
    /**
     * 
     * @type {EntitySyncStatus}
     * @memberof EntitySyncStatusEntity
     */
    localEntitySyncStatus?: EntitySyncStatus;
    /**
     * 
     * @type {Array<EntitySyncStatusEntityState>}
     * @memberof EntitySyncStatusEntity
     */
    statuses: Array<EntitySyncStatusEntityState>;
}

/**
 * Check if a given object implements the EntitySyncStatusEntity interface.
 */
export function instanceOfEntitySyncStatusEntity(value: object): value is EntitySyncStatusEntity {
    if (!('entityExists' in value) || value['entityExists'] === undefined) return false;
    if (!('statuses' in value) || value['statuses'] === undefined) return false;
    return true;
}

export function EntitySyncStatusEntityFromJSON(json: any): EntitySyncStatusEntity {
    return EntitySyncStatusEntityFromJSONTyped(json, false);
}

export function EntitySyncStatusEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntitySyncStatusEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'entityExists': json['entityExists'],
        'localEntitySyncStatus': json['localEntitySyncStatus'] == null ? undefined : EntitySyncStatusFromJSON(json['localEntitySyncStatus']),
        'statuses': ((json['statuses'] as Array<any>).map(EntitySyncStatusEntityStateFromJSON)),
    };
}

  export function EntitySyncStatusEntityToJSON(json: any): EntitySyncStatusEntity {
      return EntitySyncStatusEntityToJSONTyped(json, false);
  }

  export function EntitySyncStatusEntityToJSONTyped(value?: EntitySyncStatusEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'entityExists': value['entityExists'],
        'localEntitySyncStatus': EntitySyncStatusToJSON(value['localEntitySyncStatus']),
        'statuses': ((value['statuses'] as Array<any>).map(EntitySyncStatusEntityStateToJSON)),
    };
}

