import { useField } from "formik";
import { ToggleBase } from "../ToggleBase";
import { FormFieldError } from "./FormFieldError";
import { FormLabel } from "./FormLabel";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
};

export const FormToggle = ({ name, label, disabled = false }: Props) => {
  const [field] = useField(name);

  return (
    <div>
      <label>
        <FormLabel>{label}</FormLabel>
        <ToggleBase disabled={disabled} input={{ ...field }}></ToggleBase>
      </label>
      <FormFieldError name={name} />
    </div>
  );
};
