import { Loader } from "../../../../components/Loader";

export const TestRunInProgress = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="grid justify-items-center">
        <div className="bg-neutral-lesser_grey mb-10 size-40 rounded-full">
          <Loader />
        </div>
        <h1 className="heading-1">Patience, please.</h1>
        <p>We're setting up your test run.</p>
      </div>
    </div>
  );
};
