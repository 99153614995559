/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Connection and entity type
 * @export
 * @interface SyncStatusExalateEntityRequest
 */
export interface SyncStatusExalateEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncStatusExalateEntityRequest
     */
    connectionId: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusExalateEntityRequest
     */
    entityType: string;
}

/**
 * Check if a given object implements the SyncStatusExalateEntityRequest interface.
 */
export function instanceOfSyncStatusExalateEntityRequest(value: object): value is SyncStatusExalateEntityRequest {
    if (!('connectionId' in value) || value['connectionId'] === undefined) return false;
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    return true;
}

export function SyncStatusExalateEntityRequestFromJSON(json: any): SyncStatusExalateEntityRequest {
    return SyncStatusExalateEntityRequestFromJSONTyped(json, false);
}

export function SyncStatusExalateEntityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncStatusExalateEntityRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionId': json['connectionId'],
        'entityType': json['entityType'],
    };
}

  export function SyncStatusExalateEntityRequestToJSON(json: any): SyncStatusExalateEntityRequest {
      return SyncStatusExalateEntityRequestToJSONTyped(json, false);
  }

  export function SyncStatusExalateEntityRequestToJSONTyped(value?: SyncStatusExalateEntityRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'connectionId': value['connectionId'],
        'entityType': value['entityType'],
    };
}

