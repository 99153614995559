import { useMutation } from "@tanstack/react-query";
import {
  ConnectionDetail,
  ConnectionVersion,
  ConnectionVersionStatusEnum,
  CreateConnectionVersion201Response,
  ResponseError,
} from "../../../../generated/syncroom-api/src";
import { useNodeApi } from "../../../shared/useNodeApi";
import { ToggleCentrallyManagedModal } from "./ToggleCentrallyManagedModal";
import React, { useState } from "react";
import { PublishVersionModal } from "./PublishVersionModal";
import { ConnectionErrorModal } from "./ConnectionErrorModal";
import { RemoveConnectionModal } from "./RemoveConnectionModal";
import {
  ActionMenuSize,
  ActionsMenu,
  ButtonMenuItem,
} from "../../../components/ActionsMenu";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../components/buttons/Button";
import { FaPlus } from "react-icons/fa";
import { LuUndo2 } from "react-icons/lu";
import { MdOutlineSettingsBackupRestore, MdRocket } from "react-icons/md";
import { VersionsSelect } from "./VersionsSelect";

type Props = {
  nodeId: string;
  connectionDetail: ConnectionDetail;
  selectedVersion?: ConnectionVersion;
  setSelectedVersionId: (versionId: string | undefined) => void;
  versions: ConnectionVersion[];
};

export const ConnectionActions = ({
  nodeId,
  connectionDetail,
  selectedVersion,
  setSelectedVersionId,
  versions,
}: Props) => {
  const [
    isToggleCentrallyManagedModalOpen,
    setIsToggleCentrallyManagedModalOpen,
  ] = useState<boolean>(false);
  const [isPublishVersionModalOpen, setIsPublishVersionModalOpen] =
    useState<boolean>(false);
  const [isRemoveConnectionModalOpen, setIsRemoveConnectionModalOpen] =
    useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const api = useNodeApi();
  const createVersionMutate = useMutation<
    CreateConnectionVersion201Response,
    ResponseError
  >({
    mutationKey: ["createVersionMutate"],
    mutationFn: () =>
      api.createConnectionVersion({
        nodeId,
        connectionId: connectionDetail.id,
        body: selectedVersion!.id,
      }),
    onSuccess: async (response) => {
      setSelectedVersionId(response.versionId);
    },
  });

  const options: ButtonMenuItem[] = [
    {
      buttonText: "Add new version",
      onClick: () => createVersionMutate.mutate(),
      disabled: !connectionDetail.centrallyManaged,
      showButton: selectedVersion?.status === ConnectionVersionStatusEnum.DRAFT,
    },
    {
      buttonText: "View errors",
      onClick: () => setIsErrorModalOpen(true),
      disabled:
        connectionDetail.totalErrors === 0 &&
        !connectionDetail.errorsOnOppositeConnection,
    },
    {
      buttonText: `Admin ${connectionDetail.sourceNode.customName}`,
      onClick: () => window.open(connectionDetail.sourceNode.adminUrl),
      disabled: false,
    },
    {
      buttonText: `Admin ${connectionDetail.targetNode.customName}`,
      onClick: () => window.open(connectionDetail.targetNode.adminUrl),
      disabled: false,
    },
    {
      buttonText: "Manage in SyncRoom",
      onClick: () => setIsToggleCentrallyManagedModalOpen(true),
      disabled: false,
      toggle: true,
      toggleState: connectionDetail.centrallyManaged,
    },
    {
      buttonText: "Remove connection",
      onClick: () => {
        setIsRemoveConnectionModalOpen(true);
      },
      disabled: false,
    },
  ];

  return (
    <>
      {isErrorModalOpen && (
        <ConnectionErrorModal
          setIsModalOpen={setIsErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          nodeId={nodeId}
          connectionId={connectionDetail.id}
        />
      )}
      {isToggleCentrallyManagedModalOpen && (
        <ToggleCentrallyManagedModal
          setIsModalOpen={setIsToggleCentrallyManagedModalOpen}
          onClose={() => {
            setSelectedVersionId(undefined);
            setIsToggleCentrallyManagedModalOpen(false);
          }}
          nodeId={nodeId}
          connectionId={connectionDetail.id}
          centrallyManaged={connectionDetail.centrallyManaged}
          sourceNodeName={connectionDetail.sourceNode.customName}
          targetNodeName={connectionDetail.targetNode.customName}
        />
      )}
      {connectionDetail.activeVersionId && isPublishVersionModalOpen && (
        <PublishVersionModal
          setIsModalOpen={setIsPublishVersionModalOpen}
          onClose={() => setIsPublishVersionModalOpen(false)}
          nodeId={nodeId}
          connectionId={connectionDetail.id}
          versionId={selectedVersion!.id}
        />
      )}
      {isRemoveConnectionModalOpen && (
        <RemoveConnectionModal
          setIsModalOpen={setIsRemoveConnectionModalOpen}
          onClose={() => setIsRemoveConnectionModalOpen(false)}
          connectionId={connectionDetail.id}
          nodeId={nodeId}
          sourceNodeName={connectionDetail.sourceNode.customName}
          targetNodeName={connectionDetail.targetNode.customName}
        />
      )}

      <div className="flex items-end gap-4">
        {connectionDetail.activeVersionId && (
          <div className="w-72">
            <VersionsSelect
              versions={versions}
              selectedVersionId={selectedVersion?.id}
              setSelectedVersionId={setSelectedVersionId}
              totalErrors={connectionDetail.totalErrors}
            />
          </div>
        )}
        {selectedVersion?.status === "ACTIVE" && (
          <>
            <Button
              type={ButtonType.SECONDARY}
              size={ButtonSize.MEDIUM}
              iconLeft={<FaPlus />}
              onClick={() => createVersionMutate.mutate()}
            >
              New version
            </Button>
            {versions.some((v) => v.status === "DRAFT") && (
              <Button
                type={ButtonType.PRIMARY}
                size={ButtonSize.MEDIUM}
                iconLeft={<LuUndo2 />}
                onClick={() => {
                  const latestDraft = versions
                    .toReversed()
                    .find((v) => v.status === "DRAFT");
                  if (latestDraft) {
                    setSelectedVersionId(latestDraft.id);
                  }
                }}
              >
                Open latest draft
              </Button>
            )}
          </>
        )}
        {selectedVersion?.status === "DRAFT" && (
          <Button
            type={ButtonType.PRIMARY}
            size={ButtonSize.MEDIUM}
            iconLeft={<MdRocket />}
            onClick={() => setIsPublishVersionModalOpen(true)}
          >
            Publish version
          </Button>
        )}
        {selectedVersion?.status === "ARCHIVED" && (
          <Button
            type={ButtonType.PRIMARY}
            size={ButtonSize.MEDIUM}
            iconLeft={<MdOutlineSettingsBackupRestore />}
            onClick={() => createVersionMutate.mutate()}
          >
            Restore version
          </Button>
        )}
        <ActionsMenu
          buttons={options}
          type={ButtonType.SECONDARY}
          size={ActionMenuSize.LARGE}
          btnSize={ButtonSize.MEDIUM}
          btnLabel="Show connection actions"
        />
      </div>
    </>
  );
};
