/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * What kind of ticket system a node is from
 * @export
 */
export const NodeType = {
    JIRA_CLOUD: 'JIRA_CLOUD',
    GITHUB: 'GITHUB',
    ZENDESK: 'ZENDESK',
    SERVICE_NOW: 'SERVICE_NOW',
    AZURE_DEVOPS: 'AZURE_DEVOPS',
    SALESFORCE: 'SALESFORCE'
} as const;
export type NodeType = typeof NodeType[keyof typeof NodeType];


export function instanceOfNodeType(value: any): boolean {
    for (const key in NodeType) {
        if (Object.prototype.hasOwnProperty.call(NodeType, key)) {
            if (NodeType[key as keyof typeof NodeType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function NodeTypeFromJSON(json: any): NodeType {
    return NodeTypeFromJSONTyped(json, false);
}

export function NodeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeType {
    return json as NodeType;
}

export function NodeTypeToJSON(value?: NodeType | null): any {
    return value as any;
}

export function NodeTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): NodeType {
    return value as NodeType;
}

