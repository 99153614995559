import React from "react";
import { WarningBanner } from "../../../components/banner/WarningBanner";

type Props = {
  header: string;
  subHeader: string;
  error?: string;
};

export const ScriptEditorHeader = ({ header, subHeader, error }: Props) => {
  return (
    <div className="h-18 flex flex-shrink-0 items-center justify-between border-b border-zinc-200 p-2 px-8">
      <div>
        <div className="font-header text-primary font-semibold">{header}</div>
        <div className="text-sm text-zinc-400">{subHeader}</div>
      </div>

      {error && <WarningBanner>{error}</WarningBanner>}
    </div>
  );
};
