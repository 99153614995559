import { useParams } from "react-router-dom";
import { EntitySyncStatus } from "./EntitySyncStatus";
import { PageHeader } from "../../components/layout/PageHeader";
import React from "react";
import { PageContent } from "../../components/layout/PageContent";
import { Card } from "../../components/layout/Card";

export const EntitySyncStatusPage = () => {
  const { nodeId } = useParams();

  if (!nodeId) return <h2>Error</h2>;

  return (
    <PageContent>
      <PageHeader>
        <h1 className="heading-1 mt-5">Entity Sync Status</h1>
      </PageHeader>
      <Card header="Entity Sync Status" padding={false}>
        <EntitySyncStatus nodeId={nodeId}></EntitySyncStatus>
      </Card>
    </PageContent>
  );
};
