import { ReactNode } from "react";

type Props = {
  counter: number;
  children: ReactNode;
};

export const NumberedListItem = ({ children, counter }: Props) => {
  return (
    <li className="flex items-center gap-3">
      <div className="bg-bright-purple flex size-10 items-center justify-center rounded-full text-lg font-semibold text-white">
        {counter}
      </div>
      <div className="text-xl font-semibold">{children}</div>
    </li>
  );
};
