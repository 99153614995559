import { SyncEntityStatusCard } from "./SyncEntityStatusCard";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "../../../components/buttons/Button";
import { MdCompare } from "react-icons/md";
import {
  ActionMenuSize,
  ActionsMenu,
  ButtonMenuItem,
} from "../../../components/ActionsMenu";
import { useState } from "react";
import type {
  EntitySyncStatusEntity,
  EntitySyncStatusEntityState,
  Node,
} from "../../../../generated/syncroom-api/src";
import { EntityCompareModal } from "./EntityCompareModal";
import { SyncEntityStatusActions } from "./SyncEntityStatusActions";
import { SyncEntityStatusActionExalateModal } from "./SyncEntityStatusActionExalateModal";
import { SyncEntityStatusActionConnectModal } from "./SyncEntityStatusActionConnectModal";

type Props = {
  nodeInfo: Node;
  syncStatus: EntitySyncStatusEntity;
  entityType: string;
};
export const EntitySearchResults = ({
  syncStatus,
  nodeInfo,
  entityType,
}: Props) => {
  const [exalateModalOpen, setExalateModalOpen] = useState(false);
  const [connectModalOpen, setConnectModalOpen] = useState(false);

  const [
    selectedEntitySyncStatusEntityState,
    setSelectedEntitySyncStatusEntityState,
  ] = useState<EntitySyncStatusEntityState>();

  if (!syncStatus.entityExists) {
    return (
      <div className="text-danger px-6 pb-6">
        Entity not found on node {nodeInfo.customName}
      </div>
    );
  }

  const options: ButtonMenuItem[] = [
    {
      buttonText: "Exalate",
      onClick: () => setExalateModalOpen(true),
    },
    {
      buttonText: "Connect",
      onClick: () => setConnectModalOpen(true),
    },
  ];

  return (
    <>
      {exalateModalOpen && syncStatus.localEntitySyncStatus && (
        <SyncEntityStatusActionExalateModal
          nodeId={nodeInfo.id}
          entityType={entityType}
          entity={syncStatus.localEntitySyncStatus.issueUrn}
          onClose={() => setExalateModalOpen(false)}
        />
      )}
      {connectModalOpen && syncStatus.localEntitySyncStatus && (
        <SyncEntityStatusActionConnectModal
          nodeId={nodeInfo.id}
          entityType={entityType}
          entity={syncStatus.localEntitySyncStatus.issueUrn}
          onClose={() => setConnectModalOpen(false)}
        />
      )}
      {selectedEntitySyncStatusEntityState !== undefined && (
        <EntityCompareModal
          onClose={() => setSelectedEntitySyncStatusEntityState(undefined)}
          sourceNode={nodeInfo}
          localEntitySyncStatus={syncStatus.localEntitySyncStatus!}
          selectedEntitySyncStatusEntityState={
            selectedEntitySyncStatusEntityState
          }
        />
      )}
      <div className="flex border-t border-zinc-200">
        <div className="w-1/3 space-y-4 border-r border-zinc-200 px-6 py-4">
          <div className="text-deep-purple-1 font-header text-sm">
            Main {entityType}
          </div>
          <SyncEntityStatusCard
            nodeType={nodeInfo.type}
            nodeName={nodeInfo.customName}
            issueUrn={syncStatus.localEntitySyncStatus?.issueUrn ?? "Unknown"}
          >
            {syncStatus.localEntitySyncStatus && (
              <ActionsMenu
                buttons={options}
                type={ButtonType.SECONDARY}
                size={ActionMenuSize.DEFAULT}
                btnSize={ButtonSize.MEDIUM}
              />
            )}
          </SyncEntityStatusCard>
        </div>
        <div className="w-2/3 space-y-4 px-6 py-4">
          <div className="text-deep-purple-1 font-header text-sm">
            Synced with {syncStatus.statuses.length} other {entityType}:
          </div>

          {syncStatus.statuses.map((item) => (
            <SyncEntityStatusCard
              key={item.twinTraceId}
              nodeType={item.nodeType}
              issueUrn={item.remoteEntitySyncStatus?.issueUrn ?? "Unknown"}
              status={item.status}
              nodeName={item.nodeName}
              connectionName={item.connectionName}
            >
              <Button
                type={ButtonType.TERTIARY}
                size={ButtonSize.MEDIUM}
                iconLeft={<MdCompare />}
                onClick={() => setSelectedEntitySyncStatusEntityState(item)}
              >
                Compare
              </Button>
              {syncStatus.localEntitySyncStatus && item.connectionId && (
                <SyncEntityStatusActions
                  nodeId={nodeInfo.id}
                  entityType={entityType}
                  issueUrn={syncStatus.localEntitySyncStatus.issueUrn}
                  connectionId={item.connectionId}
                />
              )}
            </SyncEntityStatusCard>
          ))}
        </div>
      </div>
    </>
  );
};
