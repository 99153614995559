import {
  createBrowserRouter,
  Link,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { NodeDetailPage } from "./nodes/detailPage/NodeDetailPage";
import { NetworkListviewPage } from "./network/listviewPage/NetworkListviewPage";
import { NetworkDetailPage } from "./network/detailPage/NetworkDetailPage";
import { MainLayout } from "./MainLayout";
import { setLocale } from "yup";
import { NodeListviewPage } from "./nodes/listviewPage/NodeListviewPage";
import { ConnectionDetailPage } from "./connections/detailPage/ConnectionDetailPage";
import { NetworkCrumb } from "./breadcrumbs/NetworkCrumb";
import { NodeCrumb } from "./breadcrumbs/NodeCrumb";
import { ConnectionCrumb } from "./breadcrumbs/ConnectionCrumb";
import { GuardedComponent } from "./components/GuardedComponent";
import { Roles } from "./shared/Roles";
import { AccessDeniedPage } from "./pages/AccessDeniedPage";
import React from "react";
import { NotFoundPage } from "./pages/NotFoundPage";
import { UserListViewPage } from "./user/UserListViewPage";
import { EntitySyncStatusPage } from "./nodes/entityStatus/EntitySyncStatusPage";

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "/",
        element: <Navigate to="networks" />,
      },
      {
        path: "users",
        element: <UserListViewPage />,
      },
      {
        path: "networks",
        handle: {
          crumb: () => <Link to="/networks">Networks</Link>,
        },
        children: [
          {
            path: "",
            element: <NetworkListviewPage />,
          },
          {
            path: ":networkId",
            handle: {
              crumb: () => <NetworkCrumb />,
            },
            children: [
              {
                path: "",
                element: <NetworkDetailPage />,
              },
              {
                path: "nodes/:nodeId",
                handle: {
                  crumb: () => <NodeCrumb />,
                },
                children: [
                  {
                    path: "",
                    element: <NodeDetailPage />,
                  },
                  {
                    path: "connections/:connectionId",
                    element: <ConnectionDetailPage />,
                    handle: {
                      crumb: () => <ConnectionCrumb />,
                    },
                  },
                  {
                    path: "entity-sync-status",
                    element: <EntitySyncStatusPage />,
                    handle: {
                      crumb: () => <>Entity Sync Status</>,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "nodes",
        handle: {
          crumb: () => <Link to="/nodes">Nodes</Link>,
        },
        children: [
          {
            path: "",
            element: (
              <GuardedComponent role={Roles.SYSTEM_ADMIN}>
                <NodeListviewPage />
              </GuardedComponent>
            ),
          },
          {
            path: ":nodeId",
            handle: {
              crumb: () => <NodeCrumb />,
            },
            children: [
              {
                path: "",
                element: <NodeDetailPage />,
              },
              {
                path: "connections/:connectionId",
                element: <ConnectionDetailPage />,
                handle: {
                  crumb: () => <ConnectionCrumb />,
                },
              },
              {
                path: "entity-sync-status",
                element: <EntitySyncStatusPage />,
                handle: {
                  crumb: () => <>Entity Sync Status</>,
                },
              },
            ],
          },
        ],
      },
      {
        path: "access_denied",
        element: <AccessDeniedPage />,
      },
      {
        path: "not_found",
        element: <NotFoundPage />,
      },
    ],
  },
]);

setLocale({
  string: {
    url: "Url should be a valid url",
  },
});

export const App = () => {
  return <RouterProvider router={router} />;
};
