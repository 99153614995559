import { ReactNode } from "react";

export enum ButtonSize {
  DEFAULT,
  MEDIUM,
  SMALL,
  NONE,
}

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  TEXT_ONLY,
}

type Props = {
  children?: ReactNode;
  onClick?: () => void;
  size?: ButtonSize;
  type?: ButtonType;
  buttonType?: "submit" | "button";
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  disabled?: boolean;
  label?: string;
};

export const Button = ({
  children,
  onClick,
  size = ButtonSize.DEFAULT,
  type = ButtonType.PRIMARY,
  buttonType = "button",
  iconLeft = undefined,
  iconRight = undefined,
  disabled = false,
  label,
}: Props) => {
  return (
    <button
      type={buttonType}
      disabled={disabled}
      onClick={onClick}
      className={`inline-flex ${getHeight(size)} ${getBackground(disabled, type)} items-center justify-center gap-2 ${getPadding(size)} ${getBorder(disabled, type)} ${getHover(disabled, type)}`}
      aria-label={label}
    >
      {iconLeft && (
        <div
          className={`flex h-5 w-5 items-center justify-center ${getIconColor(disabled, type)}`}
        >
          {iconLeft}
        </div>
      )}
      {children && (
        <div className="flex items-center justify-center gap-2.5">
          <p
            className={`whitespace-nowrap ${getBold(type)} leading-7 ${getTextColor(disabled, type)}`}
          >
            {children}
          </p>
        </div>
      )}
      {iconRight && (
        <div
          className={`flex h-5 w-5 items-center justify-center ${getIconColor(disabled, type)}`}
        >
          {iconRight}
        </div>
      )}
    </button>
  );
};

const getBackground = (disabled: boolean, type: ButtonType) => {
  if (disabled && type !== ButtonType.TEXT_ONLY)
    return "rounded-lg bg-gray-100";

  switch (type) {
    case ButtonType.PRIMARY:
      return "rounded-lg bg-green";
    case ButtonType.SECONDARY:
      return "rounded-lg bg-white";
    case ButtonType.TERTIARY:
      return "rounded-lg bg-gray-100";
    case ButtonType.TEXT_ONLY:
      return "bg-none";
  }
};

const getHeight = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.SMALL:
      return "h-8";
    case ButtonSize.MEDIUM:
      return "h-10";
    case ButtonSize.DEFAULT:
      return "h-11";
  }
};

const getPadding = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.DEFAULT:
      return "px-4";
    case ButtonSize.MEDIUM:
      return "px-2.5";
    case ButtonSize.SMALL:
      return "px-1";
  }
};

const getBorder = (disabled: boolean, type: ButtonType) => {
  if (disabled && type !== ButtonType.TEXT_ONLY)
    return "border rounded-lg border-zinc-200";

  switch (type) {
    case ButtonType.SECONDARY:
    case ButtonType.TERTIARY:
      return "border rounded-lg  border-zinc-200";
  }
};

const getHover = (disabled: boolean, type: ButtonType) => {
  if (disabled) return "";

  switch (type) {
    case ButtonType.PRIMARY:
      return "hover:bg-[#3b9460] group-hover:bg-[#3b9460]";
    case ButtonType.SECONDARY:
      return "hover:bg-gray-200 group-hover:bg-gray-200";
    case ButtonType.TERTIARY:
      return "hover:bg-deep-purple-3 group-hover:bg-deep-purple-3";
  }
};

const getIconColor = (disabled: boolean, type: ButtonType) => {
  if (disabled) {
    return type === ButtonType.TEXT_ONLY ? "text-slate-300" : "text-zinc-400";
  }

  switch (type) {
    case ButtonType.PRIMARY:
      return "text-white";
    case ButtonType.SECONDARY:
    case ButtonType.TERTIARY:
    case ButtonType.TEXT_ONLY:
      return "text-bright-purple";
  }
};

const getTextColor = (disabled: boolean, type: ButtonType) => {
  if (disabled) {
    return type === ButtonType.TEXT_ONLY ? "text-slate-300" : "text-zinc-400";
  }

  if (type === ButtonType.PRIMARY) {
    return "text-white";
  } else {
    return "text-primary";
  }
};

const getBold = (type: ButtonType) => {
  if (type === ButtonType.TEXT_ONLY) return "font-bold";
  return "font-semibold";
};
