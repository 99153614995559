import { Modal } from "../../../components/Modal";
import { Form, Formik } from "formik";
import { Button, ButtonType } from "../../../components/buttons/Button";
import * as yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import {
  ResponseError,
  Trigger,
  TriggerStatusEnum,
  ValidatedTrigger,
} from "../../../../generated/syncroom-api/src";
import { FormSelectInput } from "../../../components/form/FormSelectInput";
import { SelectOption } from "../../../components/select/SelectWrapper";
import { FormTextareaInput } from "../../../components/form/FormTextareaInput";
import { FormCheckboxInput } from "../../../components/form/FormCheckboxInput";
import { Loader } from "../../../components/Loader";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";
import React from "react";
import { toast } from "react-toastify";

type Props = {
  nodeId: string;
  connectionId: string;
  versionId: string;
  trigger?: Trigger;
  toggleModal: (value: boolean) => void;
  onClose: () => void;
};

type FormValues = {
  entity: string;
  query?: string;
  notes?: string;
  status: boolean;
};

export const AddEditTriggerModal = ({
  nodeId,
  connectionId,
  versionId,
  trigger,
  toggleModal,
  onClose,
}: Props) => {
  const nodesApi = useNodeApi();

  const validationScheme = yup.object({
    entity: yup.string().trim().required(),
    query: yup.string().trim(),
    notes: yup.string().trim(),
    status: yup.boolean().required(),
  });

  const entityTypesRequest = useQuery({
    queryKey: [nodeId, "entityTypes"],
    queryFn: () =>
      nodesApi.getNodeEntityTypes({
        nodeId,
      }),
  });

  const handleOnSuccess = (validatedTrigger: ValidatedTrigger) => {
    onClose();
    toggleModal(false);

    if (validatedTrigger.totalEntitiesAffected !== undefined)
      toast.success(
        `Trigger validated successfully and trigger will affect ${validatedTrigger.totalEntitiesAffected} entities.`,
      );
  };

  const createTriggerMutate = useMutation<
    ValidatedTrigger,
    ResponseError,
    FormValues
  >({
    mutationFn: (values: FormValues) =>
      nodesApi.createTrigger({
        nodeId,
        connectionId,
        versionId,
        triggerRequestBody: {
          entityType: values.entity,
          query: values.query,
          notes: values.notes,
          status: values.status
            ? TriggerStatusEnum.ACTIVE
            : TriggerStatusEnum.DEACTIVATED,
        },
      }),
    onSuccess: (validatedTrigger: ValidatedTrigger) =>
      handleOnSuccess(validatedTrigger),
  });

  const updateTriggerMutate = useMutation<
    ValidatedTrigger,
    ResponseError,
    FormValues
  >({
    mutationFn: (values: FormValues) =>
      nodesApi.updateTrigger({
        nodeId,
        connectionId,
        versionId,
        triggerId: trigger?.id!,
        triggerRequestBody: {
          notes: values.notes,
          query: values.query,
          entityType: values.entity,
          status: values.status
            ? TriggerStatusEnum.ACTIVE
            : TriggerStatusEnum.DEACTIVATED,
        },
      }),
    onSuccess: (validatedTrigger: ValidatedTrigger) =>
      handleOnSuccess(validatedTrigger),
  });

  if (entityTypesRequest.isPending) {
    return <Loader />;
  }
  if (entityTypesRequest.isError) {
    return (
      <ResponseErrorMessage error={entityTypesRequest.error}>
        Error fetching entity types
      </ResponseErrorMessage>
    );
  }
  const entityTypes: SelectOption[] = entityTypesRequest.data.map((entity) => ({
    value: entity,
    label: entity,
  }));
  const isAddTrigger = trigger === undefined;

  return (
    <Formik
      initialValues={{
        entity: entityTypes[0].value,
        query: trigger?.query,
        notes: trigger?.notes,
        status: trigger?.status === TriggerStatusEnum.ACTIVE,
      }}
      validationSchema={validationScheme}
      onSubmit={async (values) => {
        const validatedValues = await validationScheme.validate(values);
        isAddTrigger
          ? createTriggerMutate.mutate(validatedValues)
          : updateTriggerMutate.mutate(validatedValues);
      }}
    >
      <Form noValidate>
        <Modal
          toggleModal={toggleModal}
          title={isAddTrigger ? "Add Trigger" : "Edit Trigger"}
          buttons={
            <>
              <Button
                type={ButtonType.SECONDARY}
                onClick={() => toggleModal(false)}
              >
                Cancel
              </Button>
              <Button buttonType="submit">Save trigger</Button>
            </>
          }
          isLoading={
            createTriggerMutate.isPending || updateTriggerMutate.isPending
          }
        >
          <div className="space-y-5">
            <FormSelectInput
              label="Entity"
              name="entity"
              options={entityTypes}
            />
            <FormTextareaInput label="Query" name="query" />
            <FormTextareaInput label="Notes" name="notes" />
            <FormCheckboxInput label="Activate trigger" name="status" />
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};
