/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetConnectionSyncQueueStatistics200Response
 */
export interface GetConnectionSyncQueueStatistics200Response {
    /**
     * 
     * @type {number}
     * @memberof GetConnectionSyncQueueStatistics200Response
     */
    incoming: number;
    /**
     * 
     * @type {number}
     * @memberof GetConnectionSyncQueueStatistics200Response
     */
    incomingAttachments: number;
    /**
     * 
     * @type {number}
     * @memberof GetConnectionSyncQueueStatistics200Response
     */
    outgoing: number;
    /**
     * 
     * @type {number}
     * @memberof GetConnectionSyncQueueStatistics200Response
     */
    outgoingAttachments: number;
}

/**
 * Check if a given object implements the GetConnectionSyncQueueStatistics200Response interface.
 */
export function instanceOfGetConnectionSyncQueueStatistics200Response(value: object): value is GetConnectionSyncQueueStatistics200Response {
    if (!('incoming' in value) || value['incoming'] === undefined) return false;
    if (!('incomingAttachments' in value) || value['incomingAttachments'] === undefined) return false;
    if (!('outgoing' in value) || value['outgoing'] === undefined) return false;
    if (!('outgoingAttachments' in value) || value['outgoingAttachments'] === undefined) return false;
    return true;
}

export function GetConnectionSyncQueueStatistics200ResponseFromJSON(json: any): GetConnectionSyncQueueStatistics200Response {
    return GetConnectionSyncQueueStatistics200ResponseFromJSONTyped(json, false);
}

export function GetConnectionSyncQueueStatistics200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConnectionSyncQueueStatistics200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'incoming': json['incoming'],
        'incomingAttachments': json['incomingAttachments'],
        'outgoing': json['outgoing'],
        'outgoingAttachments': json['outgoingAttachments'],
    };
}

  export function GetConnectionSyncQueueStatistics200ResponseToJSON(json: any): GetConnectionSyncQueueStatistics200Response {
      return GetConnectionSyncQueueStatistics200ResponseToJSONTyped(json, false);
  }

  export function GetConnectionSyncQueueStatistics200ResponseToJSONTyped(value?: GetConnectionSyncQueueStatistics200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'incoming': value['incoming'],
        'incomingAttachments': value['incomingAttachments'],
        'outgoing': value['outgoing'],
        'outgoingAttachments': value['outgoingAttachments'],
    };
}

